import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { domElementGetter } from '@toasttab/ec-layout'
import { AppBootstrap } from './app'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

import { loadTranslations } from '@local/translations'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-ec-ecs',
  rootComponent: AppBootstrap,
  domElementGetter: domElementGetter.application,
  portalContainers: ['banquetPortalsContainer'],

  sentry: {
    publicKey: '22e1b9066f8cdc82dc5f75939ee682d1@o37442',
    projectId: '4506519724949504',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = [loadTranslations, ...reactLifecycles.bootstrap]
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'bulk-import-direct-deposit-spa'
