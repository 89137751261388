import React from 'react'
import { BulkActionDisclosureSection } from '@local/bulk-action-section'
import { DirectDepositsCard } from './direct-deposit-card'
import { t } from '@local/translations'

export const DirectDepositsSection: React.FunctionComponent = () => {
  return (
    <BulkActionDisclosureSection
      title={t('direct-deposits.title')}
      subtitle={t('direct-deposits.subtitle')}
    >
      <DirectDepositsCard />
    </BulkActionDisclosureSection>
  )
}
