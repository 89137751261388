import * as React from 'react'
import cx from 'classnames'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { IconProps } from '@toasttab/buffet-pui-icons'
import { Badge } from '@toasttab/buffet-pui-badge'

export type BulkActionSectionProps = {
  title: string
  badge?: string
  subtitle: React.ReactNode
} & TestIdentifiable

/**
 * BulkActionSection component
 * Modeled after the sections in ec-settings-spa
 * https://github.com/toasttab/ec-settings-spa/blob/main/packages/features/configuration/pages/dashboard/index.tsx#L62
 */
export const BulkActionSection: React.FunctionComponent<
  React.PropsWithChildren<BulkActionSectionProps>
> = ({ testId = 'bulk-action-section', children, title, subtitle }) => {
  return (
    <div data-testid={testId} className='md:py-8'>
      <BulkActionSectionTitle
        testId={testId}
        className={cx({
          'mb-2': subtitle
        })}
        stickyOnMobile={true}
        title={title}
      />
      <BulkActionSectionSubtitle
        className={cx({ 'mb-4': children })}
        subtitle={subtitle}
      />
      {children}
    </div>
  )
}

export type BulkActionSectionTitleProps = {
  className?: string
  action?: {
    onClick: React.MouseEventHandler
    icon?: React.FunctionComponentElement<IconProps>
  }
  badge?: string
  stickyOnMobile?: boolean
} & Pick<BulkActionSectionProps, 'title'> &
  TestIdentifiable

/** Modeled after SettingGroupHeader **/
export const BulkActionSectionTitle: React.FunctionComponent<
  BulkActionSectionTitleProps
> = ({ testId, className, title, badge, stickyOnMobile = false, action }) => {
  const screenSize = useScreenSize()
  return (
    <header
      data-testid={`${testId}-title`}
      className={cx(
        'flex flex-row justify-between',
        {
          'cursor-pointer': action?.onClick
        },
        {
          'py-4 sticky z-10 top-[64px] bg-white':
            stickyOnMobile && screenSize < ScreenSize.MD
        },
        className
      )}
      onClick={action?.onClick}
    >
      <h3 className='font-bold type-headline-5 m-0'>
        {title}
        {badge && (
          <Badge color='neutral0' className='ml-2 align-middle'>
            {badge}
          </Badge>
        )}
      </h3>
      {action?.icon && (
        <IconButton
          onClick={action.onClick}
          icon={action.icon}
          iconColor='secondary'
          cropToIcon
        />
      )}
    </header>
  )
}

export type BulkActionSectionSubtitleProps = {
  className?: string
  onClick?: React.MouseEventHandler
} & Pick<BulkActionSectionProps, 'subtitle'> &
  TestIdentifiable

export const BulkActionSectionSubtitle: React.FunctionComponent<
  BulkActionSectionSubtitleProps
> = ({ testId, className, subtitle, onClick }) => {
  return (
    <p
      data-testid={`${testId}-subtitle`}
      className={cx(
        'type-default text-secondary',
        {
          'cursor-pointer': onClick
        },
        className
      )}
      onClick={onClick}
    >
      {subtitle}
    </p>
  )
}
