import * as React from 'react'
import { useQuery } from '@apollo/client'
import { LoadingCard } from '@local/loading-card'

import {
  IncompleteDirectDepositSortColumn,
  SortDirection
} from '@local/grouter'
import { ErrorCard } from '@local/error-card'

import {
  DirectDepositIncompleteDataView,
  EmptyStateNoDirectDeposits,
  EmptyStateNoEmployees
} from './components'
import { EmployeeCountByStatus } from '../../api-documents'
import { IncompleteDirectDeposits } from '../api-documents'

export const DirectDepositIncompleteCard: React.FunctionComponent = () => {
  const [limit, setLimit] = React.useState<number>(10)
  const [offset, setOffset] = React.useState<number>(0)
  const [sortColumn, setSortColumn] =
    React.useState<IncompleteDirectDepositSortColumn>('NAME')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('ASC')

  const { data: employeeCountData, loading: employeeCountLoading } = useQuery(
    EmployeeCountByStatus
  )

  const { data, error, loading, refetch } = useQuery(IncompleteDirectDeposits, {
    variables: {
      incompleteDirectDepositInput: {
        limit: limit,
        offset: offset,
        sort: sortColumn,
        direction: sortDirection
      }
    }
  })

  return employeeCountLoading || loading ? (
    <LoadingCard />
  ) : error ? (
    <ErrorCard onClick={() => refetch} />
  ) : employeeCountData?.employeeStatusTypeCount.active === 0 ? (
    <EmptyStateNoEmployees onAddSuccess={() => refetch} />
  ) : !data || data.incompleteDirectDeposits?.totalCount === 0 ? (
    <EmptyStateNoDirectDeposits />
  ) : (
    <DirectDepositIncompleteDataView
      data={data}
      limit={limit}
      setLimit={setLimit}
      offset={offset}
      setOffset={setOffset}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      sortColumn={sortColumn}
      setSortColumn={setSortColumn}
    />
  )
}
