import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { CardContainer } from '@toasttab/buffet-pui-card'

/**
 * LoadingCard component
 */

export const LoadingCard = () => {
  return (
    <CardContainer
      testId='loading-card'
      noPadding
      className='flex flex-col items-center p-10'
    >
      <MerryGoRound testId='loading-card-spinner' size='md' />
    </CardContainer>
  )
}
