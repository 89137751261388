import React from 'react'

import { Cell } from '@toasttab/buffet-pui-table'
import { useUser } from '@toasttab/ec-session'

import { DirectDepositsQueryEmployeeFragment } from '@local/grouter'

type Props = Pick<DirectDepositsQueryEmployeeFragment, 'id' | 'name' | 'email'>

export const NameCell: React.FunctionComponent<Props> = (props) => {
  return (
    <Cell className='flex flex-col align-middle'>
      <NameComponent {...props} />
    </Cell>
  )
}

export const NameComponent: React.FunctionComponent<Props> = ({
  id,
  name,
  email
}) => {
  const user = useUser()
  const onClick = () => {
    window.location.assign(`/${user.client}/employees/${id}/paymentMethod`)
  }

  return (
    <div>
      <a
        className='inline-link !no-underline cursor-pointer'
        key='payment-method'
        onClick={onClick}
        role='link'
      >
        {name.last}, {!!name.chosen ? name.chosen : name.first}
      </a>
      <div className='text-secondary type-subhead'>{email}</div>
    </div>
  )
}
