import * as React from 'react'
import { useQuery } from '@apollo/client'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { EmailPreviewQuery } from './api-documents'
import { t } from '@local/translations'

export type EmailPreviewModalProps = {
  onClose: VoidFunction
} & TestIdentifiable

/**
 * EmailPreviewModal component
 */
export const EmailPreviewModal: React.FunctionComponent<
  EmailPreviewModalProps
> = ({ onClose, testId = 'email-preview-modal' }) => {
  const { data, loading } = useQuery(EmailPreviewQuery)

  return (
    !loading &&
    data && (
      <Modal
        testId={testId.toString()}
        hideCloseIcon={true}
        isOpen={true}
        onRequestClose={onClose}
        size='xxl'
      >
        <Modal.Header>{t('email-preview-modal.header')}</Modal.Header>
        <Modal.Body>
          <div className='space-y-12'>
            <div>{t('email-preview-modal.description')}</div>
            <div>
              <DividingLine />
              <div className='font-semibold text-default'>
                {t('email-preview-modal.email-preview-header')}
              </div>
              <div className='whitespace-pre-wrap'>
                {data.directDepositEmailTemplate}
              </div>
              <DividingLine />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>{t('email-preview-modal.button')}</Button>
        </Modal.Footer>
      </Modal>
    )
  )
}
