import * as React from 'react'
import { AddEmployeeModal } from '@toasttab/ec-employee-management'
import { useTracking } from '@local/track'
import { EmptyStateCard } from '@local/empty-state-card'
import { t } from '@local/translations'

export const EmptyStateNoEmployees: React.FunctionComponent<{
  onAddSuccess: VoidFunction
}> = ({ onAddSuccess }) => {
  const { track } = useTracking()

  const [isOpen, setIsOpen] = React.useState(false)
  const onOpen = React.useCallback(() => {
    track('direct-deposit-bulk-action.incomplete-section.add-employee')
    setIsOpen(true)
  }, [track])
  const onClose = React.useCallback(() => setIsOpen(false), [])

  return (
    <>
      <AddEmployeeModal
        isOpen={isOpen}
        onSuccess={onAddSuccess}
        onSuccessButtonLabel={t(
          'direct-deposit-incomplete.empty-state.no-employees.action-complete'
        )}
        onCancel={onClose}
      />
      <EmptyStateCard
        title={t('direct-deposit-incomplete.empty-state.no-employees.title')}
        subtitle={t(
          'direct-deposit-incomplete.empty-state.no-employees.subtitle'
        )}
        action={{
          label: t('direct-deposit-incomplete.empty-state.no-employees.action'),
          onClick: onOpen
        }}
      />
    </>
  )
}
