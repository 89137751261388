import { isValidEmail as isValidEmailFormat } from '@toasttab/email-validation'

export const isValidEmail = (email?: string | null) => {
  return (
    !!email &&
    isValidEmailFormat(email) &&
    !email.endsWith('@example.com') &&
    !email.endsWith('@test.com')
  )
}
