import React from 'react'

import { Body, Row, Table } from '@toasttab/buffet-pui-table'

import {
  IncompleteDirectDepositSortColumn,
  IncompleteDirectDepositsQueryEmployeeFragment,
  SortDirection
} from '@local/grouter'

import {
  ActionsCell,
  EmailCell,
  LastEmailedCell,
  NameCell,
  SelectCell
} from './cells'
import { DirectDepositIncompleteTableHead } from './table-head'
import {
  BulkActionTableFooter,
  BulkActionTableFooterProps
} from '@local/bulk-action-table-footer'

export type DirectDepositIncompleteTableProps = {
  visibleEmployees: IncompleteDirectDepositsQueryEmployeeFragment[]
  sortColumn: IncompleteDirectDepositSortColumn
  sortDirection: SortDirection
  onChangeSort: (
    sortColumn: IncompleteDirectDepositSortColumn,
    sortDirection: SortDirection
  ) => void
} & BulkActionTableFooterProps

export const DirectDepositIncompleteTable: React.FunctionComponent<
  DirectDepositIncompleteTableProps
> = (props) => {
  return props.visibleEmployees && props.visibleEmployees.length > 0 ? (
    <Table variant='plain' className='table-fixed'>
      <DirectDepositIncompleteTableHead {...props} />
      <Body>
        {props.visibleEmployees.map((employee) => {
          return (
            employee && <EmployeeRow key={employee.id} employee={employee} />
          )
        })}
      </Body>
      <BulkActionTableFooter cellClassName='pt-6 pb-0' colSpan={5} {...props} />
    </Table>
  ) : null
}

const EmployeeRow: React.FunctionComponent<{
  employee: IncompleteDirectDepositsQueryEmployeeFragment
}> = ({ employee }) => {
  return (
    <Row>
      <SelectCell employee={employee} />
      <NameCell id={employee.id} name={employee.name} />
      <EmailCell email={employee.email} />
      <LastEmailedCell
        currentEmail={employee.email}
        notification={employee.notification}
      />
      <ActionsCell employee={employee} />
    </Row>
  )
}
