import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export type StackedDataCellTextPairProps = {
  label: string
  value: string | React.ReactElement
} & TestIdentifiable

/**
 * StackedDataCellTextPair component
 */
export const StackedDataCellTextPair: React.FunctionComponent<
  StackedDataCellTextPairProps
> = ({ testId = 'stacked-data-cell-text-pair', label, value }) => {
  return (
    <div data-testid={testId} className='w-full grid space-y-px'>
      <div className='type-caption text-secondary'>{label}</div>
      <div>{value}</div>
    </div>
  )
}
