import React from 'react'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Cell } from '@toasttab/buffet-pui-table'

type SequenceCellProps = {
  sequence?: number
}

export const SequenceCell: React.FunctionComponent<SequenceCellProps> = (
  props
) => {
  return (
    <Cell className='align-middle'>
      <SequenceContent {...props} />
    </Cell>
  )
}

export const SequenceContent: React.FunctionComponent<SequenceCellProps> = ({
  sequence
}) => {
  return sequence && <Badge>{sequence}</Badge>
}
