/* eslint-disable */
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig
} from 'graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> =
  { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> &
  { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An `ISO-8601` compliant Full Date Scalar - e.g. 2022-06-01 */
  Date: { input: any; output: any }
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: { input: any; output: any }
  /**  A signed decimal number, which supports arbitrary precision and is serialized as a string. Example values: "29.99", "29.999".  */
  Decimal: { input: any; output: any }
  Long: { input: any; output: any }
}

export type AbsentPayChangeLogTokenError = {
  __typename?: 'AbsentPayChangeLogTokenError'
  message?: Maybe<Scalars['String']['output']>
}

export type AccessibleCustomer = {
  __typename?: 'AccessibleCustomer'
  companyCode: Scalars['String']['output']
  name: Scalars['String']['output']
  shardName: Scalars['String']['output']
}

export type AccessibleCustomersInput = {
  cursor?: InputMaybe<Scalars['ID']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type AccessibleCustomersResult = {
  __typename?: 'AccessibleCustomersResult'
  customers: Array<AccessibleCustomer>
  nextCursor?: Maybe<Scalars['ID']['output']>
}

export type ActiveEmploymentStatus = EmploymentStatus & {
  __typename?: 'ActiveEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type Actor = {
  __typename?: 'Actor'
  /** The ID of the actor */
  id: Scalars['ID']['output']
  /** The actor's primary job name */
  jobName?: Maybe<Scalars['String']['output']>
  /** The name of the actor */
  name?: Maybe<PersonName>
}

export type ActorPermissionsForEmployee = {
  __typename?: 'ActorPermissionsForEmployee'
  employeeId: Scalars['ID']['output']
  permissions: Array<HumanResourcesPermission>
}

export type AdditionalDocumentInput = {
  fileName: Scalars['String']['input']
  filePath: Scalars['String']['input']
}

export type AdditionalEarningResponse =
  | EarningListEmptyError
  | EmployeeNotFoundError
  | JobAssignmentNotFoundError
  | MissingPayGroupError
  | NextPayPeriodNotFoundError
  | NextPaycheckDateNotFoundError
  | Paycheck

export type AllEmploymentStatuses =
  | ActiveEmploymentStatus
  | ApplicantEmploymentStatus
  | DeletedEmploymentStatus
  | DemoEmploymentStatus
  | ImplementationEmploymentStatus
  | LeaveOfAbsenceEmploymentStatus
  | NeverEmployedEmploymentStatus
  | PendingEmploymentStatus
  | RehiredEmploymentStatus
  | SeasonalEmploymentStatus
  | TerminatedEmploymentStatus

export type AmountWithPayInterval = {
  __typename?: 'AmountWithPayInterval'
  amount: Money
  interval: PayInterval
}

export type ApplicantEmploymentStatus = EmploymentStatus & {
  __typename?: 'ApplicantEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type Assignee = 'CUSTOMER' | 'ONBOARDING'

export type AvailabilityRequest = {
  email?: InputMaybe<Scalars['String']['input']>
  employeeId?: InputMaybe<Scalars['ID']['input']>
  employeeNumber?: InputMaybe<Scalars['Int']['input']>
  posAccessCode?: InputMaybe<Scalars['Int']['input']>
  ssn?: InputMaybe<Scalars['String']['input']>
}

export type AvailabilityResponse = {
  __typename?: 'AvailabilityResponse'
  email?: Maybe<Scalars['Boolean']['output']>
  employeeNumber?: Maybe<Scalars['Boolean']['output']>
  posAccessCode?: Maybe<Scalars['Boolean']['output']>
  ssn?: Maybe<Scalars['Boolean']['output']>
}

export type Benefit = {
  __typename?: 'Benefit'
  key: Scalars['String']['output']
  label: Scalars['String']['output']
  options: Array<BenefitOption>
}

export type BenefitOption = {
  __typename?: 'BenefitOption'
  label: Scalars['String']['output']
  value: Scalars['ID']['output']
}

export type Benefits = {
  __typename?: 'Benefits'
  acaPlan?: Maybe<Scalars['String']['output']>
  benefitPlan?: Maybe<Scalars['String']['output']>
  tafwPolicy?: Maybe<Scalars['String']['output']>
  washingtonLaI?: Maybe<Scalars['String']['output']>
  workersComp?: Maybe<Scalars['String']['output']>
}

export type BenefitsInput = {
  acaPlan?: InputMaybe<Scalars['String']['input']>
  benefitPlan?: InputMaybe<Scalars['String']['input']>
  tafwPolicy?: InputMaybe<Scalars['String']['input']>
  washingtonLaI?: InputMaybe<Scalars['String']['input']>
  workersComp?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsThirdPartyDataSharingConsent = {
  __typename?: 'BenefitsThirdPartyDataSharingConsent'
  version: Scalars['Float']['output']
}

export type BulkNotifyResponse = {
  __typename?: 'BulkNotifyResponse'
  employee: Scalars['ID']['output']
  status: DirectDepositNotificationStatus
}

export type CannotChangeLinkedUsername = {
  __typename?: 'CannotChangeLinkedUsername'
  userId: Scalars['ID']['output']
}

export type CannotChangeUnlinkedUsername = {
  __typename?: 'CannotChangeUnlinkedUsername'
  userId: Scalars['ID']['output']
}

export type ChangeLinkedUsernameResult =
  | CannotChangeUnlinkedUsername
  | InvalidUsername
  | PayrollUser
  | ToastVersionIgnored
  | UserNotFound
  | UsernameExists

export type ChangePayInput = {
  effectiveDate: Scalars['Date']['input']
  rate: MoneyInput
}

export type ChangeSalaryAllocationInput = {
  /**  Date the new rate should be applied. If more than 1 SalaryAllocation is being updated, effectiveDate must be today's date  */
  effectiveDate: Scalars['Date']['input']
  /** Id of the salary allocation */
  id: Scalars['ID']['input']
  /** New annual rate for this salary allocation */
  newRate: MoneyInput
}

export type ChangeSalaryResponse =
  | EmployeeNotFoundError
  | EmptySalaryAllocationsError
  | InvalidEffectiveDateError
  | Salary
  | SalaryAllocationNotFoundError
  | SalaryNotFoundError

export type ChangeUnlinkedUsernameResult =
  | CannotChangeLinkedUsername
  | InvalidUsername
  | PayrollUser
  | UserNotFound
  | UsernameExists

export type ChecklistState = {
  __typename?: 'ChecklistState'
  customerUuid: Scalars['ID']['output']
  items: Array<Item>
}

export type CompleteDirectDepositEmployee = {
  __typename?: 'CompleteDirectDepositEmployee'
  allocations: Array<DirectDepositAllocation>
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: PersonName
}

export type CompleteDirectDepositsResponse = {
  __typename?: 'CompleteDirectDepositsResponse'
  employees: Array<CompleteDirectDepositEmployee>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  totalCount: Scalars['Int']['output']
}

export type ConfirmVerificationEmailError =
  | ConfirmationExpired
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type ConfirmVerificationEmailInput = {
  confirmationKey: Scalars['String']['input']
}

export type ConfirmVerificationEmailResult =
  | ConfirmationExpired
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailConfirmed
  | VerificationEmailNotSent

export type ConfirmationExpired = {
  __typename?: 'ConfirmationExpired'
  message?: Maybe<Scalars['String']['output']>
}

export type ContactTelephoneNumber = {
  __typename?: 'ContactTelephoneNumber'
  isMobile: Scalars['Boolean']['output']
  telephoneNumber: Scalars['String']['output']
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']['output']
  displayName: Scalars['String']['output']
  isoCode: Scalars['String']['output']
}

export type CreateJobTagAssignmentInput = {
  createdByUserGuid: Scalars['ID']['input']
  jobTagId: Scalars['ID']['input']
  managementSetId: Scalars['ID']['input']
  restaurantId: Scalars['ID']['input']
  restaurantJobId: Scalars['ID']['input']
  selectedJobTagValue: Scalars['String']['input']
}

export type CreateJobTagInput = {
  createdByUserGuid: Scalars['ID']['input']
  name: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

/** An `ISO-4217` compliant Currency. */
export type Currency =
  /** Canadian Dollars (CAD). */
  | 'CAD'
  /** Euro (EUR). */
  | 'EUR'
  /** United Kingdom Pounds (GBP). */
  | 'GBP'
  /** United States Dollars (USD). */
  | 'USD'

export type CustomDocumentFieldInput = {
  fieldId: Scalars['Int']['input']
  id: Scalars['ID']['input']
  value: Scalars['String']['input']
}

export type CustomDocumentFields = {
  __typename?: 'CustomDocumentFields'
  documentId: Scalars['ID']['output']
  fieldId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type CustomDocumentFieldsV2 = {
  __typename?: 'CustomDocumentFieldsV2'
  documentIds: Array<Scalars['ID']['output']>
  fieldId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Customer = {
  __typename?: 'Customer'
  feins: Array<PayrollFein>
  id: Scalars['ID']['output']
  locations: Array<Location>
  /** @deprecated Field no longer supported */
  uuid: Scalars['ID']['output']
}

export type CustomerConfiguration = {
  __typename?: 'CustomerConfiguration'
  customerPositions: Array<CustomerPosition>
  defaultEarningsIds?: Maybe<DefaultEarningsId>
  /** customerUuid */
  id: Scalars['ID']['output']
  locations: Array<PayrollLocation>
  payGroups: Array<PayGroupWithFrequency>
  payGroupsV2: Array<PayGroup>
  payrollSecurityRoles: Array<PayrollSecurityRole>
  workTaxLocations: Array<PayrollWorkTaxLocation>
}

export type CustomerInput = {
  locations: Array<LocationInput>
  uuid: Scalars['ID']['input']
}

export type CustomerPosition = {
  __typename?: 'CustomerPosition'
  checkCode: PayrollCheckCode
  fein: PayrollFein
  id: Scalars['ID']['output']
  isExempt: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  payGroup: PayGroup
  salaryType: Scalars['String']['output']
}

export type CustomerSetting = {
  __typename?: 'CustomerSetting'
  id: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export type DataExceptionError = {
  __typename?: 'DataExceptionError'
  message?: Maybe<Scalars['String']['output']>
}

export type DateRange = {
  __typename?: 'DateRange'
  endDate?: Maybe<Scalars['Date']['output']>
  startDate: Scalars['Date']['output']
}

export type DefaultEarningsId = {
  __typename?: 'DefaultEarningsId'
  contractorHourly?: Maybe<Scalars['ID']['output']>
  contractorSalary?: Maybe<Scalars['ID']['output']>
  hourly: Scalars['ID']['output']
  salary: Scalars['ID']['output']
}

export type DeletedEmploymentStatus = EmploymentStatus & {
  __typename?: 'DeletedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type DemoEmploymentStatus = EmploymentStatus & {
  __typename?: 'DemoEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type DepositAccountType =
  | 'CHECKING'
  | 'MONEYMARKET'
  | 'PAYCARD'
  | 'SAVING'

export type DirectDepositAllocation = {
  __typename?: 'DirectDepositAllocation'
  amount: Scalars['Float']['output']
  depositAccountType: DepositAccountType
  sequence: Scalars['Int']['output']
  splitDepositType: SplitDepositType
}

export type DirectDepositBulkEmailResponse = {
  __typename?: 'DirectDepositBulkEmailResponse'
  id: Scalars['ID']['output']
  status: DirectDepositNotificationStatus
}

export type DirectDepositNotification = {
  __typename?: 'DirectDepositNotification'
  email?: Maybe<Scalars['String']['output']>
  lastContactedUtc?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<DirectDepositNotificationStatus>
}

export type DirectDepositNotificationStatus =
  | 'FAILURE_TO_SEND'
  | 'SUCCESSFULLY_SENT'

export type DirectDepositPaginationInput = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type DisabilityStatus = 'DOES_NOT_WISH_TO_ANSWER' | 'NO' | 'YES'

export type DriversLicense = {
  __typename?: 'DriversLicense'
  expirationDate: Scalars['Date']['output']
  number: Scalars['String']['output']
  state: StateInUnitedStates
}

export type DuplicateRequest = {
  __typename?: 'DuplicateRequest'
  message?: Maybe<Scalars['String']['output']>
}

export type EarningListEmptyError = {
  __typename?: 'EarningListEmptyError'
  message?: Maybe<Scalars['String']['output']>
}

export type EeoClassification = {
  __typename?: 'EeoClassification'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type EeoClassificationInput = {
  id: Scalars['Int']['input']
  name: Scalars['String']['input']
}

export type EmailAlreadyVerified = {
  __typename?: 'EmailAlreadyVerified'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailHasBeenUpdated = {
  __typename?: 'EmailHasBeenUpdated'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailInvalidError = {
  __typename?: 'EmailInvalidError'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailNotFound = {
  __typename?: 'EmailNotFound'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailSendingError = {
  __typename?: 'EmailSendingError'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailVerification = {
  __typename?: 'EmailVerification'
  isVerified?: Maybe<Scalars['Boolean']['output']>
  toastIdentityGuid: Scalars['ID']['output']
  verifiedTime?: Maybe<Scalars['DateTime']['output']>
}

export type EmailVerificationError =
  | ConfirmationExpired
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailNotFound
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type EmailVerificationFailed = {
  __typename?: 'EmailVerificationFailed'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailVerificationMutation = {
  __typename?: 'EmailVerificationMutation'
  confirmVerificationEmail: ConfirmVerificationEmailResult
  sendVerificationEmail: SendVerificationEmailResult
}

export type EmailVerificationMutationConfirmVerificationEmailArgs = {
  input?: InputMaybe<ConfirmVerificationEmailInput>
}

export type EmailVerificationMutationSendVerificationEmailArgs = {
  input?: InputMaybe<SendVerificationEmailInput>
}

export type EmailVerificationQuery = {
  __typename?: 'EmailVerificationQuery'
  verification?: Maybe<EmailVerificationResult>
}

export type EmailVerificationResult =
  | ConfirmationExpired
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailNotFound
  | EmailVerification
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  isPrimary?: Maybe<Scalars['Boolean']['output']>
  lastName: Scalars['String']['output']
  relation: Scalars['String']['output']
  telephoneNumber?: Maybe<Scalars['String']['output']>
}

export type Employee = {
  __typename?: 'Employee'
  benefits?: Maybe<Benefits>
  companyCode?: Maybe<Scalars['String']['output']>
  contact: EmployeeContact
  demographics: EmployeeDemographics
  emergencyContacts: Array<EmergencyContact>
  employment?: Maybe<Employment>
  hasSsn?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  identification: EmployeeIdentification
  isEmployeeZero: Scalars['Boolean']['output']
  jobAssignmentPayChangeErrors: Array<JobAssignment>
  jobAssignments: Array<JobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  payHistory: EmployeePayHistory
  payrollSecurityRole?: Maybe<PayrollSecurityRole>
  salary?: Maybe<Salary>
  salaryChangeErrors?: Maybe<Salary>
  supervisors: Array<EmployeeSupervisor>
  user?: Maybe<User>
  /** @deprecated Please use user */
  userUuid: Scalars['ID']['output']
}

export type EmployeeAtLocation = {
  __typename?: 'EmployeeAtLocation'
  jobs: Array<EmployeeJob>
  mappingStatus: MappingCondition
  payrollLocationId?: Maybe<LevelId>
  payrollLocationName?: Maybe<Scalars['String']['output']>
  posEmail?: Maybe<Scalars['String']['output']>
  posLocationGuid?: Maybe<Scalars['ID']['output']>
  posMostRecentClockInDate?: Maybe<Scalars['Date']['output']>
  posRestaurantUserGuid?: Maybe<Scalars['ID']['output']>
  posStatus?: Maybe<Scalars['String']['output']>
  posUserName?: Maybe<PersonName>
}

export type EmployeeContact = {
  __typename?: 'EmployeeContact'
  homeAddress?: Maybe<PostalAddress>
  mailingAddress?: Maybe<PostalAddress>
  telephoneNumber?: Maybe<ContactTelephoneNumber>
}

export type EmployeeDemographics = {
  __typename?: 'EmployeeDemographics'
  dateOfBirth?: Maybe<Scalars['Date']['output']>
  disability?: Maybe<DisabilityStatus>
  ethnicity?: Maybe<Ethnicity>
  gender?: Maybe<Gender>
  maritalStatus?: Maybe<MaritalStatus>
  veteranStatus: Array<VeteranStatus>
}

export type EmployeeDocumentUploadRequest = {
  fileName: Scalars['String']['input']
}

export type EmployeeIdentification = {
  __typename?: 'EmployeeIdentification'
  driversLicense?: Maybe<DriversLicense>
  hasSocialSecurityNumber: Scalars['Boolean']['output']
}

export type EmployeeJob = {
  __typename?: 'EmployeeJob'
  mappingStatus: MappingCondition
  payrollId?: Maybe<LevelId>
  payrollRateOfPay?: Maybe<Money>
  payrollTitle?: Maybe<Scalars['String']['output']>
  posGuid?: Maybe<Scalars['ID']['output']>
  posRateOfPay?: Maybe<Money>
  posTitle?: Maybe<Scalars['String']['output']>
}

export type EmployeeMappingStatus = {
  __typename?: 'EmployeeMappingStatus'
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  issues: Array<MappingIssue>
  locations: Array<EmployeeAtLocation>
  name: PersonName
  status: EmploymentStatusType
}

export type EmployeeNewHireDocument = {
  __typename?: 'EmployeeNewHireDocument'
  fileName: Scalars['String']['output']
  filePath: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export type EmployeeNotFoundError = {
  __typename?: 'EmployeeNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type EmployeePay = {
  __typename?: 'EmployeePay'
  mostRecentPaycheck?: Maybe<Scalars['String']['output']>
}

export type EmployeePayHistory = {
  __typename?: 'EmployeePayHistory'
  mostRecentPaycheck?: Maybe<Scalars['String']['output']>
}

export type EmployeePaymentMethodEmailResponse = {
  __typename?: 'EmployeePaymentMethodEmailResponse'
  messageId?: Maybe<Scalars['String']['output']>
}

export type EmployeeStatusTypeCount = {
  __typename?: 'EmployeeStatusTypeCount'
  active?: Maybe<Scalars['Int']['output']>
  applicant?: Maybe<Scalars['Int']['output']>
  deleted?: Maybe<Scalars['Int']['output']>
  demo?: Maybe<Scalars['Int']['output']>
  dormant?: Maybe<Scalars['Int']['output']>
  implementation?: Maybe<Scalars['Int']['output']>
  leaveOfAbsence?: Maybe<Scalars['Int']['output']>
  neverEmployed?: Maybe<Scalars['Int']['output']>
  notSpecified?: Maybe<Scalars['Int']['output']>
  pendingActive?: Maybe<Scalars['Int']['output']>
  terminated?: Maybe<Scalars['Int']['output']>
}

export type EmployeeSupervisor = {
  __typename?: 'EmployeeSupervisor'
  id: Scalars['ID']['output']
  job?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type Employment = {
  __typename?: 'Employment'
  eeoClassification: EeoClassification
  employeeNumber?: Maybe<Scalars['String']['output']>
  employmentStatus: AllEmploymentStatuses
  employmentType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  overtimeEligible?: Maybe<Scalars['Boolean']['output']>
  totalLengthOfService?: Maybe<DateRange>
}

export type EmploymentDetails = {
  __typename?: 'EmploymentDetails'
  employeeNumber: Scalars['Int']['output']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']['output']
}

export type EmploymentDetailsInput = {
  defaultEarningsCode: Scalars['ID']['input']
  eeoClassification?: InputMaybe<EeoClassificationInput>
  employeeNumber: Scalars['Int']['input']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']['input']
}

export type EmploymentId = {
  __typename?: 'EmploymentId'
  customerId: Scalars['ID']['output']
  employeeId: Scalars['ID']['output']
}

export type EmploymentStatus = {
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type EmploymentStatusType =
  | 'ACTIVE'
  | 'APPLICANT'
  | 'DELETED'
  | 'DEMO'
  | 'IMPLEMENTATION'
  | 'LEAVE_OF_ABSENCE'
  | 'NEVER_EMPLOYED'
  | 'NOT_SPECIFIED'
  | 'PENDING_ACTIVE'
  | 'SEASONAL'
  | 'TERMINATED'

export type EmploymentTaxType = 'K1' | 'UNKNOWN' | 'W2' | '_1099'

export type EmptySalaryAllocationsError = {
  __typename?: 'EmptySalaryAllocationsError'
  message?: Maybe<Scalars['String']['output']>
}

export type Ethnicity =
  | 'AMERICAN_INDIAN_OR_ALASKA_NATIVE'
  | 'ASIAN'
  | 'BLACK_OR_AFRICAN_AMERICAN'
  | 'DO_NOT_WISH_TO_IDENTIFY'
  | 'HAWAIIAN_OR_OTHER_PACIFIC_ISLAND'
  | 'HISPANIC_OR_LATINO'
  | 'TWO_OR_MORE_RACES'
  | 'WHITE'

export type FindUserEventsByUserIdInput = {
  fromVersion?: InputMaybe<Scalars['Long']['input']>
  maxNumResults?: InputMaybe<Scalars['Int']['input']>
  userId: Scalars['ID']['input']
}

export type Gender = 'DOES_NOT_WISH_TO_IDENTIFY' | 'FEMALE' | 'MALE'

export type GenericResponse = {
  __typename?: 'GenericResponse'
  message: Scalars['String']['output']
}

export type HourlyEarningInput = {
  earningCodeId: Scalars['ID']['input']
  hours: Scalars['Decimal']['input']
  rate: MoneyInput
}

export type HourlyRetroPay = {
  __typename?: 'HourlyRetroPay'
  hoursWorked: Scalars['Decimal']['output']
  payChangeDifference: AmountWithPayInterval
  retroPay: Money
}

export type HourlyRetroPayInput = {
  employeeId: Scalars['ID']['input']
  endDate: Scalars['Date']['input']
  jobAssignmentId: Scalars['ID']['input']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']['input']
}

export type HourlyRetroPayResponse =
  | HourlyRetroPay
  | InvalidStartDateError
  | JobAssignmentNotFoundError
  | TimesheetRetrievalError

export type HumanResourcesPermission =
  | 'CHANGE_UNVERIFIED_USER_EMAIL'
  | 'CHANGE_VERIFIED_USER_EMAIL'
  | 'CUSTOMER_CONFIGURATION_EDIT'
  | 'CUSTOMER_CONFIGURATION_VIEW'
  | 'CUSTOMER_JOB_MAPPING_ADMIN_EDIT'
  | 'CUSTOMER_JOB_MAPPING_ADMIN_VIEW'
  | 'EDIT_MY_PROFILE'
  | 'EMPLOYEE_ACCESS_ALL'
  | 'EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER'
  | 'EMPLOYEE_EDIT'
  | 'EMPLOYEE_MAPPING_EDIT'
  | 'EMPLOYEE_VIEW'
  | 'EMPLOYMENT_EDIT'
  | 'EMPLOYMENT_STATUS_EDIT'
  | 'EMPLOYMENT_STATUS_VIEW'
  | 'EMPLOYMENT_VIEW'
  | 'JOB_ASSIGNMENT_EDIT'
  | 'JOB_ASSIGNMENT_VIEW'
  | 'MIGRATE_USERS'
  | 'PAY_CARD_AUDIT_VIEW'
  | 'PAY_CHANGE_HISTORY_VIEW'
  | 'PAY_EDIT'
  | 'PAY_HISTORY_VIEW'
  | 'SSN_EDIT'
  | 'SSN_EXISTS_VIEW'
  | 'VIEW_MY_PROFILE'
  | 'VIEW_PERMISSIONS'

export type ImplementationEmploymentStatus = EmploymentStatus & {
  __typename?: 'ImplementationEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type IncompleteDirectDepositEmployee = {
  __typename?: 'IncompleteDirectDepositEmployee'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: PersonName
  notification?: Maybe<DirectDepositNotification>
}

export type IncompleteDirectDepositInput = {
  direction: SortDirection
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
  sort: IncompleteDirectDepositSortColumn
}

export type IncompleteDirectDepositSortColumn = 'LAST_CONTACTED' | 'NAME'

export type IncompleteDirectDepositsResponse = {
  __typename?: 'IncompleteDirectDepositsResponse'
  employees: Array<IncompleteDirectDepositEmployee>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  totalCount: Scalars['Int']['output']
}

export type InputCannotBeEmpty = {
  __typename?: 'InputCannotBeEmpty'
  message?: Maybe<Scalars['String']['output']>
}

export type IntercomClientType = 'ANDROID' | 'IOS' | 'WEB'

export type IntercomSessionDataResponse = {
  __typename?: 'IntercomSessionDataResponse'
  intercomUserHash?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
}

export type InvalidEffectiveDateError = {
  __typename?: 'InvalidEffectiveDateError'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidPayChangeLogTokenError = {
  __typename?: 'InvalidPayChangeLogTokenError'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidStartDateError = {
  __typename?: 'InvalidStartDateError'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidUsername = {
  __typename?: 'InvalidUsername'
  username: Scalars['String']['output']
}

export type IssueSeverity = 'ERROR' | 'INFO' | 'WARN'

export type IssueType =
  | 'ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS'
  | 'JOB_GUID_IS_MALFORMED'
  | 'JOB_IS_MISSING_IN_POS'
  | 'JOB_NOT_MAPPED'
  | 'LOCATION_GUID_IS_MALFORMED'
  | 'LOCATION_IS_MISSING_IN_POS'
  | 'LOCATION_NOT_MAPPED'
  | 'MAPPED_INTO_DIFFERENT_TOAST_IDENTITY'
  | 'PAYROLL_JOBS_MISMATCH_POS_JOBS'
  | 'POS_EE_MAPPING_IGNORED'
  | 'POS_EE_MAPPING_UNKNOWN_LOCATION_GUID'
  | 'RU_COUNTERPART_IS_MISSING_IN_POS'
  | 'UNMAPPED_PAYROLL_SPECIALTY'
  | 'UNMAPPED_POS_RU'

export type Item = {
  __typename?: 'Item'
  assignedTo: Assignee
  key: Scalars['ID']['output']
  status: TaskStatus
  subItems: Array<Item>
  title: Scalars['String']['output']
}

export type Job = {
  __typename?: 'Job'
  exportId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type JobAssignment = {
  __typename?: 'JobAssignment'
  activeHourlyRateOfPay?: Maybe<Money>
  employmentTaxType?: Maybe<EmploymentTaxType>
  id: Scalars['ID']['output']
  isPrimary: Scalars['Boolean']['output']
  jobId: Scalars['ID']['output']
  locationId: Scalars['ID']['output']
  locationName: Scalars['String']['output']
  name: Scalars['String']['output']
  payGroupId?: Maybe<Scalars['ID']['output']>
  pendingPayChange?: Maybe<PendingPayChange>
}

export type JobAssignmentNotFoundError = {
  __typename?: 'JobAssignmentNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type JobInput = {
  restaurantJobGuid?: InputMaybe<Scalars['ID']['input']>
  uuid: Scalars['ID']['input']
}

export type JobMapping = {
  __typename?: 'JobMapping'
  payrollJob: PayrollJob
  restaurantJob: RestaurantJob
}

export type JobTag = {
  __typename?: 'JobTag'
  createdByUserGuid: Scalars['ID']['output']
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  modifiedByUserGuid?: Maybe<Scalars['ID']['output']>
  modifiedDate?: Maybe<Scalars['Date']['output']>
  name: Scalars['String']['output']
  values: Array<Scalars['String']['output']>
}

export type JobTagAssignment = {
  __typename?: 'JobTagAssignment'
  createdByUserGuid: Scalars['ID']['output']
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  jobTagId: Scalars['ID']['output']
  jobTagName: Scalars['String']['output']
  managementSetId: Scalars['ID']['output']
  modifiedByUserGuid?: Maybe<Scalars['ID']['output']>
  modifiedDate?: Maybe<Scalars['Date']['output']>
  restaurantId: Scalars['ID']['output']
  restaurantJobId: Scalars['ID']['output']
  selectedJobTagValue: Scalars['String']['output']
}

export type KeyDoesNotMatch = {
  __typename?: 'KeyDoesNotMatch'
  message?: Maybe<Scalars['String']['output']>
}

export type LeaveOfAbsenceEmploymentStatus = EmploymentStatus & {
  __typename?: 'LeaveOfAbsenceEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
  leaveType?: Maybe<Scalars['String']['output']>
  scheduledLeave?: Maybe<DateRange>
}

export type LevelId = {
  __typename?: 'LevelId'
  databaseId: Scalars['Int']['output']
  sequenceId: Scalars['Int']['output']
}

export type LevelIdInput = {
  databaseId: Scalars['Int']['input']
  sequenceId: Scalars['Int']['input']
}

export type LinkIfMappedResult =
  | LinkingNotAvailable
  | MappedUserMismatchedUsername
  | PayrollUser
  | UserAlreadyLinked
  | UserNotMapped

export type LinkUserResult = PayrollUser | UserNotFound

export type LinkingNotAvailable = {
  __typename?: 'LinkingNotAvailable'
  toastIdentityGuid: Scalars['ID']['output']
}

export type LocalizableMessage = {
  __typename?: 'LocalizableMessage'
  key: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type Location = {
  __typename?: 'Location'
  name?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Restaurant>
  status: LocationMappingStatus
  uuid: Scalars['ID']['output']
}

export type LocationInput = {
  restaurantGuid?: InputMaybe<Scalars['ID']['input']>
  uuid: Scalars['ID']['input']
}

export type LocationMappingStatus = {
  __typename?: 'LocationMappingStatus'
  code: LocationMappingStatusCode
  message: Scalars['String']['output']
}

export type LocationMappingStatusCode =
  | 'NoStatus'
  | 'RestaurantGuidConnected'
  | 'RestaurantGuidDoubleMapped'
  | 'RestaurantGuidEmpty'
  | 'RestaurantGuidInvalid'

export type LumpSumEarningInput = {
  amount: MoneyInput
  earningCodeId: Scalars['ID']['input']
}

export type MappedUserMismatchedUsername = {
  __typename?: 'MappedUserMismatchedUsername'
  username: Scalars['String']['output']
}

export type MappingAssignment = {
  locationGuid: Scalars['ID']['input']
  restaurantUserGuid: Scalars['ID']['input']
}

export type MappingCondition =
  | 'MAPPED_INVALID_GUID'
  | 'MAPPED_NO_POS_COUNTERPART'
  | 'MAPPED_USER_MAPPED'
  | 'MAPPED_USER_MAPPING_IGNORED'
  | 'MAPPED_USER_NOT_MAPPED'
  | 'NOT_MAPPED'
  | 'POS_ONLY'

export type MappingIssue = {
  __typename?: 'MappingIssue'
  severity: IssueSeverity
  type: IssueType
}

export type MaritalStatus =
  | 'DIVORCED'
  | 'HEAD_OF_HOUSEHOLD'
  | 'MARRIED'
  | 'SINGLE'
  | 'WIDOW'

export type MissingPayGroupError = {
  __typename?: 'MissingPayGroupError'
  message?: Maybe<Scalars['String']['output']>
}

/** A monetary value with currency. */
export type Money = {
  __typename?: 'Money'
  /** The amount of money */
  amount: Scalars['Decimal']['output']
  /** The currency the money is paid in */
  currency: Currency
}

export type MoneyInput = {
  amount: Scalars['Decimal']['input']
  currency: Currency
}

export type Mutation = {
  __typename?: 'Mutation'
  applyMapping: Scalars['String']['output']
  /** @deprecated Please use directDepositBulkEmail */
  bulkNotify: Array<BulkNotifyResponse>
  changeHelloWorldResponse?: Maybe<Response>
  changeHourlyPay?: Maybe<JobAssignment>
  /**  Change salary pay: provide a list of the updated SalaryAllocations and their new annual pay rate  */
  changeSalaryAllocations: ChangeSalaryResponse
  contactOnboarding?: Maybe<GenericResponse>
  createJobTag?: Maybe<JobTag>
  createJobTagAssignment?: Maybe<JobTagAssignment>
  /** @deprecated please use updatePendingPayChangeStatuses */
  deletePendingPayChange?: Maybe<Scalars['ID']['output']>
  directDepositBulkEmail: Array<DirectDepositBulkEmailResponse>
  emailVerification?: Maybe<EmailVerificationMutation>
  ignorePosUserInPayroll?: Maybe<Scalars['Boolean']['output']>
  payrollUser?: Maybe<PayrollUserMutations>
  rehireEmployeeNoChanges: Scalars['ID']['output']
  rehireEmployeeWithChanges: Scalars['ID']['output']
  resyncTasks: ChecklistState
  saveAdditionalEarnings?: Maybe<AdditionalEarningResponse>
  saveNewEmployee?: Maybe<SaveNewEmployeeResponse>
  sendJobMappingRequest?: Maybe<Scalars['String']['output']>
  sendUpdateEmployeePaymentMethodEmail?: Maybe<UpdateEmployeePaymentMethodEmailResponse>
  setHasPreviousPayrollProvider: GenericResponse
  updateBenefitsConsent: BenefitsThirdPartyDataSharingConsent
  updateCustomer: Customer
  updateJobMappings: Array<PayrollJob>
  updatePayCardCustomerSettings: UpdatePayCardCustomerSettingsResponse
  updatePendingPayChangeStatuses?: Maybe<UpdatePendingPayChangeStatusResponse>
  updateTask: ChecklistState
  userMigration?: Maybe<UserMigrationMutation>
}

export type MutationApplyMappingArgs = {
  locationId: Scalars['ID']['input']
  payrollEmployeeId: Scalars['ID']['input']
  restaurantUserId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationBulkNotifyArgs = {
  employees: Array<Scalars['ID']['input']>
}

export type MutationChangeHelloWorldResponseArgs = {
  response: Scalars['String']['input']
}

export type MutationChangeHourlyPayArgs = {
  changePayInput: ChangePayInput
  employeeId: Scalars['ID']['input']
  jobAssignmentId: Scalars['ID']['input']
}

export type MutationChangeSalaryAllocationsArgs = {
  changeSalaryAllocations: Array<ChangeSalaryAllocationInput>
  employeeId: Scalars['ID']['input']
}

export type MutationContactOnboardingArgs = {
  emailContent?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateJobTagArgs = {
  jobTagInput: CreateJobTagInput
}

export type MutationCreateJobTagAssignmentArgs = {
  jobTagAssignmentInput: CreateJobTagAssignmentInput
}

export type MutationDeletePendingPayChangeArgs = {
  employeeId: Scalars['ID']['input']
  pendingPayChangeId: Scalars['ID']['input']
}

export type MutationDirectDepositBulkEmailArgs = {
  employees: Array<Scalars['ID']['input']>
}

export type MutationIgnorePosUserInPayrollArgs = {
  userGuid: Scalars['ID']['input']
}

export type MutationRehireEmployeeNoChangesArgs = {
  request: RehireEmployeeNoChangesInput
}

export type MutationRehireEmployeeWithChangesArgs = {
  employeeId: Scalars['ID']['input']
  rehireDate: Scalars['Date']['input']
  request: NewEmployeeInput
}

export type MutationSaveAdditionalEarningsArgs = {
  employeeId: Scalars['ID']['input']
  hourlyEarnings?: Array<HourlyEarningInput>
  jobAssignmentId: Scalars['ID']['input']
  lumpSumEarnings?: Array<LumpSumEarningInput>
}

export type MutationSaveNewEmployeeArgs = {
  request: NewEmployeeInput
}

export type MutationSendJobMappingRequestArgs = {
  attachedNote?: InputMaybe<Scalars['String']['input']>
  employeeUuid: Scalars['ID']['input']
  payrollJobId?: InputMaybe<LevelIdInput>
  payrollLocationId?: InputMaybe<LevelIdInput>
}

export type MutationSendUpdateEmployeePaymentMethodEmailArgs = {
  employeeId: Scalars['ID']['input']
}

export type MutationSetHasPreviousPayrollProviderArgs = {
  hasPreviousPayrollProvider: Scalars['Boolean']['input']
}

export type MutationUpdateBenefitsConsentArgs = {
  version: Scalars['Float']['input']
}

export type MutationUpdateCustomerArgs = {
  customerInput: CustomerInput
}

export type MutationUpdateJobMappingsArgs = {
  jobs: Array<JobInput>
  locationId: Scalars['ID']['input']
}

export type MutationUpdatePayCardCustomerSettingsArgs = {
  input: UpdatePayCardCustomerSettingsInput
}

export type MutationUpdatePendingPayChangeStatusesArgs = {
  employeeId: Scalars['ID']['input']
  updatePendingPayChangeStatusInput?: Array<PendingPayChangeStatusInput>
}

export type MutationUpdateTaskArgs = {
  status: TaskStatus
  taskKey: Scalars['String']['input']
}

export type NavigableTaxAccount = {
  __typename?: 'NavigableTaxAccount'
  companyCode: Scalars['String']['output']
  legalName: Scalars['String']['output']
  shardName: Scalars['String']['output']
  tin: Scalars['String']['output']
}

export type NavigableTaxAccountResult = {
  __typename?: 'NavigableTaxAccountResult'
  nextPageNumber?: Maybe<Scalars['Int']['output']>
  taxAccounts: Array<NavigableTaxAccount>
}

export type NavigableTaxAccountsInput = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type Navigation = {
  __typename?: 'Navigation'
  children: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']['output']
  labelNew: LocalizableMessage
  matchUrls: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
  pageType?: Maybe<PageType>
  path: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type NavigationError = {
  __typename?: 'NavigationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type NavigationErrors = {
  __typename?: 'NavigationErrors'
  errors: Array<NavigationError>
  path: Scalars['String']['output']
}

export type NavigationInput = {
  params?: InputMaybe<Array<NavigationParam>>
  path?: InputMaybe<Scalars['String']['input']>
}

export type NavigationParam = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type NavigationResult = {
  __typename?: 'NavigationResult'
  errors: Array<NavigationErrors>
  result?: Maybe<Navigation>
}

export type NeverEmployedEmploymentStatus = EmploymentStatus & {
  __typename?: 'NeverEmployedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type NewEmployeeInput = {
  additionalDocuments: Array<AdditionalDocumentInput>
  benefits: BenefitsInput
  customDocumentFields: Array<CustomDocumentFieldInput>
  documents: Array<Scalars['ID']['input']>
  employment: EmploymentDetailsInput
  jobsAndPay: NewJobsAndPayInput
  overtimeEligible: Scalars['Boolean']['input']
  person: PersonInput
  securityRoleId: Scalars['ID']['input']
}

export type NewHireDocument = {
  __typename?: 'NewHireDocument'
  assignedByPosition: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type NewJobAssignment = {
  __typename?: 'NewJobAssignment'
  hourlyRate?: Maybe<Money>
  jobId: Scalars['ID']['output']
  locationId: Scalars['ID']['output']
}

export type NewJobAssignmentInput = {
  hourlyRate?: InputMaybe<MoneyInput>
  jobId: Scalars['ID']['input']
  locationId: Scalars['ID']['input']
}

export type NewJobsAndPay = {
  __typename?: 'NewJobsAndPay'
  annualSalary?: Maybe<Money>
  jobs: Array<NewJobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  salaryPerPayPeriod?: Maybe<Money>
  salaryRateOfPay?: Maybe<Money>
}

export type NewJobsAndPayInput = {
  annualSalary?: InputMaybe<MoneyInput>
  jobs: Array<NewJobAssignmentInput>
  mappings: Array<MappingAssignment>
  payGroupAssignments: Array<PayGroupAssignmentInput>
  salaryPerPayPeriod?: InputMaybe<MoneyInput>
  salaryRateOfPay?: InputMaybe<MoneyInput>
}

export type NextPayPeriodNotFoundError = {
  __typename?: 'NextPayPeriodNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type NextPaycheckDateNotFoundError = {
  __typename?: 'NextPaycheckDateNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type NotAToastUser = {
  __typename?: 'NotAToastUser'
  message?: Maybe<Scalars['String']['output']>
}

export type OnboardingCheckListPermission =
  | 'EDIT_PAYROLL_ONBOARDING'
  | 'VIEW_PAYROLL_ONBOARDING'

export type PageType = 'HEADER_ONLY' | 'LINK_ONLY'

export type PayCardAdminSettings = {
  __typename?: 'PayCardAdminSettings'
  payCardAdminPayoutsLocked?: Maybe<Scalars['Boolean']['output']>
}

export type PayCardBooleanAccessFactor = 'FALSE' | 'TRUE' | 'UNKNOWN'

export type PayCardBusinessAccess = {
  __typename?: 'PayCardBusinessAccess'
  businessId?: Maybe<Scalars['ID']['output']>
  existsInPayroll: Scalars['Boolean']['output']
  fein: Scalars['String']['output']
  isContractComplete: Scalars['Boolean']['output']
  restaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccess = {
  __typename?: 'PayCardCustomerAccess'
  businesses: Array<PayCardBusinessAccess>
  customerUuid: Scalars['ID']['output']
  detachedRestaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccessInput = {
  skipCache: Scalars['Boolean']['input']
}

export type PayCardCustomerSettings = {
  __typename?: 'PayCardCustomerSettings'
  payCardAdminSettings?: Maybe<PayCardAdminSettings>
  payCardPayoutConfiguration?: Maybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled?: Maybe<Scalars['Boolean']['output']>
  payCardTipsConfiguration?: Maybe<PayCardTipsConfiguration>
}

/** Represents one atomic change to customer settings. */
export type PayCardCustomerSettingsAuditEntry = {
  __typename?: 'PayCardCustomerSettingsAuditEntry'
  change: PayCardCustomerSettingsAuditHistoryChangeContent
  /** The timestamp in which the change was made */
  datetime: Scalars['DateTime']['output']
  /** User that made the change */
  user: PayCardCustomerSettingsAuditUser
}

/**  Content that represents what the change was that occurred. Note we only return a message key here not the actual string. The front end will need to resolve the actual localized string.  */
export type PayCardCustomerSettingsAuditHistoryChangeContent = {
  __typename?: 'PayCardCustomerSettingsAuditHistoryChangeContent'
  messageKey: Scalars['String']['output']
}

export type PayCardCustomerSettingsAuditUser = {
  __typename?: 'PayCardCustomerSettingsAuditUser'
  displayName: Scalars['String']['output']
}

export type PayCardEmployeeAccess = {
  __typename?: 'PayCardEmployeeAccess'
  accessFactors: PayCardEmployeeAccessFactors
  accessLevel: PayCardEmployeeAccessLevel
  employeeUuid: Scalars['ID']['output']
}

export type PayCardEmployeeAccessFactors = {
  __typename?: 'PayCardEmployeeAccessFactors'
  cardActivated?: Maybe<PayCardBooleanAccessFactor>
  highestRestaurantAccessLevel?: Maybe<PayCardRestaurantAccessLevel>
  isEmployed?: Maybe<Scalars['Boolean']['output']>
  isLocEnabled?: Maybe<PayCardBooleanAccessFactor>
  isWorkTaxLocationStateEligible?: Maybe<Scalars['Boolean']['output']>
}

export type PayCardEmployeeAccessInput = {
  employeeUuid: Scalars['ID']['input']
  skipCache: Scalars['Boolean']['input']
}

export type PayCardEmployeeAccessLevel =
  | 'NONE'
  | 'PAYCARD'
  | 'PAYOUTS'
  | 'SIGNUP'

export type PayCardEmployerAccessFactors =
  | 'ENROLLMENT_FEATURE_FLAG'
  | 'FEIN_IN_PAYROLL'
  | 'HAS_COMPLETED_CONTRACT'
  | 'PAYOUT_ENTITLEMENT'
  | 'PAY_CARD_ENTITLEMENT'
  | 'SDP_FEATURE_FLAG'

export type PayCardEmployerAccessLevel =
  | 'EMPLOYEE_PAYOUTS'
  | 'EMPLOYEE_SIGNUP'
  | 'NONE'
  | 'ONBOARDING'

export type PayCardPayoutConfiguration =
  | 'TIPS_ONLY'
  | 'WAGES_AND_TIPS'
  | 'WAGES_ONLY'

export type PayCardRestaurantAccess = {
  __typename?: 'PayCardRestaurantAccess'
  accessFactors: Array<PayCardEmployerAccessFactors>
  accessLevel: PayCardEmployerAccessLevel
  restaurantGuid: Scalars['ID']['output']
}

export type PayCardRestaurantAccessLevel = 'NONE' | 'PAYOUTS' | 'SIGNUP'

export type PayCardTipsConfiguration = 'TIPS_ALL' | 'TIPS_WITH_WITHHOLDING'

export type PayChangeEvent = {
  __typename?: 'PayChangeEvent'
  /**  Information about the actor that initiated the pay change event. If null, the actor is unknown.  */
  actor?: Maybe<Actor>
  /**  The date and time the pay change event was initiated. If null, the date is unknown.  */
  createdAt?: Maybe<Scalars['DateTime']['output']>
  /**  The current pay rate for the job to which the pay change event is referencing.  */
  currentRate: AmountWithPayInterval
  /** The date and time the pay change did / will take effect. */
  effectiveAt?: Maybe<Scalars['DateTime']['output']>
  /** The ID of the pay change event */
  id: Scalars['ID']['output']
  /** The job name to which the pay change event is referencing */
  jobName: Scalars['String']['output']
  /**  The location name for the job to which the pay change event is referencing  */
  locationName: Scalars['String']['output']
  /**  The previous pay rate for the job to which the pay change event is referencing. If null, this pay change event is not tied to a previous pay, i.e. for new jobs  */
  previousRate?: Maybe<AmountWithPayInterval>
  /** The current status of the pay change even. */
  status: PayChangeEventStatus
}

export type PayChangeEventStatus =
  | 'CANCELED'
  | 'ERROR'
  | 'ERROR_ACKNOWLEDGED'
  | 'NEW_JOB'
  | 'SCHEDULED'
  | 'UPDATED'

export type PayChangeLog = {
  __typename?: 'PayChangeLog'
  /**  Page token used to retrieve the current page of pay change events. Passing this value through as the pageToken in subsequent requests will result in the same pay change events being retrieved.  */
  currentPageToken?: Maybe<Scalars['String']['output']>
  /**  The employee's pay change events, default sort is via createdDate newest -> oldest  */
  data?: Maybe<Array<PayChangeEvent>>
  /**  Page token used to retrieve the next page of pay change events. When this field is null that indicates that [currentPageToken] was the last page in the result set.  */
  nextPageToken?: Maybe<Scalars['String']['output']>
  /**  Page token used to retrieve the previous page of pay change events. When the field is null that indicates that indicates that the [currentPageToken] was the first page in the result set.  */
  previousPageToken?: Maybe<Scalars['String']['output']>
  /** The total count of this employee's pay change events */
  totalResultCount: Scalars['Int']['output']
}

export type PayChangeLogResponse =
  | AbsentPayChangeLogTokenError
  | InvalidPayChangeLogTokenError
  | PayChangeLog

export type PayChangeLogSortField = 'CREATE_DATE'

export type PayChangeLogSortInput = {
  /** Whether to sort in ascending or descending order for this field */
  direction: SortDirection
  /** The field by which to sort the pay change log events */
  sortField: PayChangeLogSortField
}

export type PayFrequency =
  | 'ANNUAL'
  | 'BIWEEKLY'
  | 'MONTHLY'
  | 'NONE'
  | 'QUARTERLY'
  | 'SEMIANNUAL'
  | 'SEMIMONTHLY'
  | 'WEEKLY'

export type PayGroup = {
  __typename?: 'PayGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayGroupAssignment = {
  __typename?: 'PayGroupAssignment'
  checkCodeId: Scalars['ID']['output']
  locationIds: Array<Scalars['ID']['output']>
  payGroupId: Scalars['ID']['output']
  positionId: Scalars['ID']['output']
  workTaxLocationId: Scalars['ID']['output']
}

export type PayGroupAssignmentInput = {
  checkCodeId: Scalars['ID']['input']
  locationIds: Array<Scalars['ID']['input']>
  payGroupId: Scalars['ID']['input']
  positionId?: InputMaybe<Scalars['ID']['input']>
  workTaxLocationId: Scalars['ID']['input']
}

export type PayGroupWithFrequency = {
  __typename?: 'PayGroupWithFrequency'
  checkCodeId: Scalars['ID']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  payGroupId: Scalars['ID']['output']
  periodsPerYear: Scalars['Int']['output']
}

export type PayInterval = 'ANNUAL' | 'HOUR'

export type Paycheck = {
  __typename?: 'Paycheck'
  date?: Maybe<Scalars['Date']['output']>
}

export type PayrollCheckCode = {
  __typename?: 'PayrollCheckCode'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  periodsPerYear: Scalars['Int']['output']
}

export type PayrollCustomerUser = {
  __typename?: 'PayrollCustomerUser'
  companyCode: Scalars['String']['output']
  customerId: Scalars['ID']['output']
  customerName: Scalars['String']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  shardName: Scalars['String']['output']
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type PayrollCustomerUserSource = 'DB' | 'DEX'

export type PayrollCustomerUsersInput = {
  source: PayrollCustomerUserSource
  toastIdentityGuid?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type PayrollEmployeeMapping = {
  __typename?: 'PayrollEmployeeMapping'
  name: PersonName
  payrollEmployeeNumber?: Maybe<Scalars['String']['output']>
}

export type PayrollFein = {
  __typename?: 'PayrollFein'
  benefits: Array<ThirdPartyBenefit>
  fein?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollJob = {
  __typename?: 'PayrollJob'
  name: Scalars['String']['output']
  uuid: Scalars['ID']['output']
}

export type PayrollLocation = {
  __typename?: 'PayrollLocation'
  exportId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  jobs: Array<Job>
  name: Scalars['String']['output']
}

export type PayrollSecurityRole = {
  __typename?: 'PayrollSecurityRole'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollUser = {
  __typename?: 'PayrollUser'
  customerId: Scalars['ID']['output']
  email?: Maybe<Scalars['String']['output']>
  employeeId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type PayrollUserMutations = {
  __typename?: 'PayrollUserMutations'
  changeLinkedUsername: Array<ChangeLinkedUsernameResult>
  changeUnlinkedUsername: ChangeUnlinkedUsernameResult
  link: LinkUserResult
  linkIfMapped: Array<LinkIfMappedResult>
  unlink: UnlinkUserResult
}

export type PayrollUserMutationsChangeLinkedUsernameArgs = {
  toastIdentityGuid: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type PayrollUserMutationsChangeUnlinkedUsernameArgs = {
  customerId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type PayrollUserMutationsLinkArgs = {
  customerId: Scalars['ID']['input']
  toastIdentityGuid: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type PayrollUserMutationsUnlinkArgs = {
  customerId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type PayrollWorkTaxLocation = {
  __typename?: 'PayrollWorkTaxLocation'
  address: PayrollWorkTaxLocationAddress
  fein: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollWorkTaxLocationAddress = {
  __typename?: 'PayrollWorkTaxLocationAddress'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  gnisCode?: Maybe<Scalars['String']['output']>
  line1?: Maybe<Scalars['String']['output']>
  line2?: Maybe<Scalars['String']['output']>
  line3?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type PendingEmploymentStatus = EmploymentStatus & {
  __typename?: 'PendingEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type PendingPayChange = {
  __typename?: 'PendingPayChange'
  effectiveDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  rate: Money
  status: PendingPayChangeStatus
}

export type PendingPayChangeNotFoundError = {
  __typename?: 'PendingPayChangeNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type PendingPayChangeNotFoundErrors = {
  __typename?: 'PendingPayChangeNotFoundErrors'
  pendingPayChangeNotFoundErrors: Array<PendingPayChangeNotFoundError>
}

export type PendingPayChangeStatus =
  | 'APPLIED'
  | 'CANCELED'
  | 'ERROR'
  | 'ERROR_ACKNOWLEDGED'
  | 'PENDING'

export type PendingPayChangeStatusInput = {
  pendingPayChangeId: Scalars['ID']['input']
  pendingPayChangeStatus: PendingPayChangeStatus
}

export type PendingPayChangeStatusResponse = {
  __typename?: 'PendingPayChangeStatusResponse'
  pendingPayChanges: Array<PendingPayChange>
}

export type Person = {
  __typename?: 'Person'
  chosenName?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  posAccessCode: Scalars['Int']['output']
  ssn?: Maybe<Scalars['String']['output']>
}

export type PersonInput = {
  chosenName?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  posAccessCode?: InputMaybe<Scalars['Int']['input']>
  ssn?: InputMaybe<Scalars['String']['input']>
}

export type PersonName = {
  __typename?: 'PersonName'
  chosen?: Maybe<Scalars['String']['output']>
  first?: Maybe<Scalars['String']['output']>
  formatted?: Maybe<Scalars['String']['output']>
  last?: Maybe<Scalars['String']['output']>
  middle?: Maybe<Scalars['String']['output']>
}

export type PosAvailabilityRequest = {
  exportIds: Array<Scalars['ID']['input']>
  posAccessCode: Scalars['Int']['input']
}

export type PosEmployee = {
  __typename?: 'PosEmployee'
  archived: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  externalEmployeeId?: Maybe<Scalars['String']['output']>
  mappedToEmployee?: Maybe<PayrollEmployeeMapping>
  mostRecentClockInDate?: Maybe<Scalars['Date']['output']>
  restaurantUserGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PosRawEmployee = {
  __typename?: 'PosRawEmployee'
  archived: Scalars['Boolean']['output']
  disabled: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  locationGuid: Scalars['ID']['output']
  payrollLocationId: Scalars['Int']['output']
  restaurantUserGuid: Scalars['ID']['output']
  userGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PosUser = {
  __typename?: 'PosUser'
  email: Scalars['String']['output']
  restaurantUsers: Array<RestaurantUser>
  userGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PostalAddress = {
  __typename?: 'PostalAddress'
  administrativeArea: Scalars['String']['output']
  country: Country
  locality: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
  streetAddress2?: Maybe<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  accessibleCustomers: AccessibleCustomersResult
  actorPermissionsForEmployees: Array<ActorPermissionsForEmployee>
  checkAvailability: AvailabilityResponse
  checkPosAvailability?: Maybe<Scalars['Boolean']['output']>
  completeDirectDeposits: CompleteDirectDepositsResponse
  customer: Customer
  customerBenefits?: Maybe<Array<Maybe<Benefit>>>
  customerConfiguration?: Maybe<CustomerConfiguration>
  directDepositEmailTemplate?: Maybe<Scalars['String']['output']>
  documentCenter: UserDocuments
  emailVerification?: Maybe<EmailVerificationQuery>
  /** @deprecated Please use findEmployeeById */
  employee: Employee
  employeeDocumentUploadUrl: EmployeeNewHireDocument
  employeeStatusTypeCount: EmployeeStatusTypeCount
  findCustomerSettings: Array<CustomerSetting>
  findEmployeeById?: Maybe<Employee>
  findEmployeeForRestaurantByToastIdentityGuid?: Maybe<Employee>
  /** @deprecated Please use findEmployeeById */
  findEmployeePay?: Maybe<EmployeePay>
  findJobMappingByJobId?: Maybe<JobMapping>
  findJobMappingsByLocationId: Array<JobMapping>
  findPayrollJobsByLocationId: Array<PayrollJob>
  findRestaurantJobsByRestaurantId: Array<RestaurantJob>
  findUserByEmployeeId?: Maybe<User>
  findUserById?: Maybe<User>
  findUserEventsByUserId: Array<UserEvent>
  /** @deprecated Please use findUserById */
  findUsers?: Maybe<Users>
  getEmployeeMappingStatus: EmployeeMappingStatus
  getPosMappingOptions: Array<PosEmployee>
  getPosUser: Array<PosRawEmployee>
  getPosUserV2?: Maybe<PosUser>
  getUnmappedPosUsers: Array<PosRawEmployee>
  helloFromBenefits: Scalars['String']['output']
  helloWorld: Response
  incompleteDirectDeposits: IncompleteDirectDepositsResponse
  intercomSessionData?: Maybe<IntercomSessionDataResponse>
  jobAssignmentsById: Array<JobAssignment>
  navigableTaxAccounts: NavigableTaxAccountResult
  navigation: NavigationResult
  newHireRequiredDocumentsForAssignedFields: Array<NewHireDocument>
  nextAvailableEmployeeNumber?: Maybe<Scalars['Int']['output']>
  numRunPayrollsInThePastYear?: Maybe<Scalars['Int']['output']>
  payCardCustomerAccess: PayCardCustomerAccess
  payCardCustomerSettings: PayCardCustomerSettings
  payCardCustomerSettingsAuditHistory: Array<PayCardCustomerSettingsAuditEntry>
  payCardCustomerSettingsLastEvent?: Maybe<PayCardCustomerSettingsAuditEntry>
  payCardEmployeeAccess: PayCardEmployeeAccess
  /** Pay change log for the specified employee. */
  payChangeLog?: Maybe<PayChangeLogResponse>
  payrollCustomerUsers: Array<PayrollCustomerUser>
  payrollOnboardingPermissions: Array<OnboardingCheckListPermission>
  requiredDocumentationCustomFields: Array<CustomDocumentFields>
  requiredDocumentationCustomFieldsV2: Array<CustomDocumentFieldsV2>
  restaurant?: Maybe<Restaurant>
  retroPayForHourlyPayChange?: Maybe<HourlyRetroPayResponse>
  retroPayForSalaryChange?: Maybe<SalaryRetroPayResponse>
  sections: SectionResult
  userMigration?: Maybe<UserMigrationQuery>
}

export type QueryAccessibleCustomersArgs = {
  input?: InputMaybe<AccessibleCustomersInput>
}

export type QueryActorPermissionsForEmployeesArgs = {
  employeeIds: Array<Scalars['ID']['input']>
}

export type QueryCheckAvailabilityArgs = {
  request: AvailabilityRequest
}

export type QueryCheckPosAvailabilityArgs = {
  request: PosAvailabilityRequest
}

export type QueryCompleteDirectDepositsArgs = {
  completeDirectDepositInput: DirectDepositPaginationInput
}

export type QueryDocumentCenterArgs = {
  documentType: UserDocumentType
}

export type QueryEmployeeArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryEmployeeDocumentUploadUrlArgs = {
  request: EmployeeDocumentUploadRequest
}

export type QueryFindCustomerSettingsArgs = {
  settings: Array<Scalars['Int']['input']>
}

export type QueryFindEmployeeByIdArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryFindEmployeeForRestaurantByToastIdentityGuidArgs = {
  toastIdentityGuid: Scalars['ID']['input']
  toastRestaurantGuid: Scalars['ID']['input']
}

export type QueryFindEmployeePayArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryFindJobMappingByJobIdArgs = {
  jobId: Scalars['ID']['input']
}

export type QueryFindJobMappingsByLocationIdArgs = {
  locationId: Scalars['ID']['input']
}

export type QueryFindPayrollJobsByLocationIdArgs = {
  locationId: Scalars['ID']['input']
}

export type QueryFindRestaurantJobsByRestaurantIdArgs = {
  restaurantId: Scalars['ID']['input']
}

export type QueryFindUserByEmployeeIdArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryFindUserByIdArgs = {
  userId: Scalars['ID']['input']
}

export type QueryFindUserEventsByUserIdArgs = {
  input: FindUserEventsByUserIdInput
}

export type QueryFindUsersArgs = {
  companyCode: Scalars['ID']['input']
  employeeUuid: Scalars['ID']['input']
}

export type QueryGetEmployeeMappingStatusArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryGetPosMappingOptionsArgs = {
  employeeUuid: Scalars['ID']['input']
  locationUuid: Scalars['ID']['input']
}

export type QueryGetPosUserArgs = {
  userGuid: Scalars['ID']['input']
}

export type QueryGetPosUserV2Args = {
  userGuid: Scalars['ID']['input']
}

export type QueryIncompleteDirectDepositsArgs = {
  incompleteDirectDepositInput: IncompleteDirectDepositInput
}

export type QueryIntercomSessionDataArgs = {
  intercomClientType: IntercomClientType
}

export type QueryJobAssignmentsByIdArgs = {
  employeeId: Scalars['ID']['input']
  jobAssignmentIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryNavigableTaxAccountsArgs = {
  input?: InputMaybe<NavigableTaxAccountsInput>
}

export type QueryNavigationArgs = {
  input?: InputMaybe<NavigationInput>
}

export type QueryNewHireRequiredDocumentsForAssignedFieldsArgs = {
  request: RequiredDocumentsRequest
}

export type QueryPayCardCustomerAccessArgs = {
  input: PayCardCustomerAccessInput
}

export type QueryPayCardEmployeeAccessArgs = {
  input: PayCardEmployeeAccessInput
}

export type QueryPayChangeLogArgs = {
  employeeId: Scalars['ID']['input']
  pageNumber?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  pageToken?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<Array<PayChangeLogSortInput>>
}

export type QueryPayrollCustomerUsersArgs = {
  input?: InputMaybe<PayrollCustomerUsersInput>
}

export type QueryRestaurantArgs = {
  restaurantGuid: Scalars['ID']['input']
}

export type QueryRetroPayForHourlyPayChangeArgs = {
  hourlyRetroPayInput: HourlyRetroPayInput
}

export type QueryRetroPayForSalaryChangeArgs = {
  salaryRetroPayInput: SalaryRetroPayInput
}

export type QuerySectionsArgs = {
  input?: InputMaybe<SectionsInput>
}

export type RehireEmployeeNoChangesInput = {
  employeeId: Scalars['ID']['input']
  rehireDate: Scalars['Date']['input']
}

export type RehiredEmploymentStatus = EmploymentStatus & {
  __typename?: 'RehiredEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  dismissalPeriod?: Maybe<DateRange>
  hireDate?: Maybe<Scalars['String']['output']>
  rehireDate?: Maybe<Scalars['String']['output']>
}

export type RequiredDocumentsRequest = {
  hireDate: Scalars['Date']['input']
  securityRoleId: Scalars['ID']['input']
  workTaxLocationIds: Array<Scalars['ID']['input']>
}

export type Response = {
  __typename?: 'Response'
  response?: Maybe<Scalars['String']['output']>
}

export type Restaurant = {
  __typename?: 'Restaurant'
  guid: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type RestaurantJob = {
  __typename?: 'RestaurantJob'
  guid: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: RestaurantJobStatusCode
}

export type RestaurantJobStatusCode =
  | 'Available'
  /** Restaurant job does not exist */
  | 'Deleted'

export type RestaurantUser = {
  __typename?: 'RestaurantUser'
  archived: Scalars['Boolean']['output']
  jobs: Array<Scalars['ID']['output']>
  locationGuid: Scalars['ID']['output']
  restaurantUserGuid: Scalars['ID']['output']
}

export type Salary = {
  __typename?: 'Salary'
  activeSalaryRateOfPay?: Maybe<Money>
  employmentTaxType?: Maybe<EmploymentTaxType>
  id: Scalars['ID']['output']
  payFrequency?: Maybe<PayFrequency>
  pendingPayChange?: Maybe<PendingPayChange>
  salaryAllocations: Array<SalaryAllocation>
}

export type SalaryAllocation = {
  __typename?: 'SalaryAllocation'
  id: Scalars['ID']['output']
  jobAssignment: JobAssignment
  rate: Money
}

export type SalaryAllocationNotFoundError = {
  __typename?: 'SalaryAllocationNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type SalaryNotFoundError = {
  __typename?: 'SalaryNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type SalaryPayFrequencyDoesNotExistError = {
  __typename?: 'SalaryPayFrequencyDoesNotExistError'
  message?: Maybe<Scalars['String']['output']>
}

export type SalaryPayFrequencyNoneError = {
  __typename?: 'SalaryPayFrequencyNoneError'
  message?: Maybe<Scalars['String']['output']>
}

export type SalaryRetroPay = {
  __typename?: 'SalaryRetroPay'
  payChangeDifference: AmountWithPayInterval
  payFrequency: PayFrequency
  payPeriodEarnings: Money
  retroPay: Money
}

export type SalaryRetroPayInput = {
  employeeId: Scalars['ID']['input']
  endDate: Scalars['Date']['input']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']['input']
}

export type SalaryRetroPayResponse =
  | InvalidStartDateError
  | SalaryPayFrequencyDoesNotExistError
  | SalaryPayFrequencyNoneError
  | SalaryRetroPay
  | TimesheetRetrievalError

export type SaveNewEmployeeResponse = {
  __typename?: 'SaveNewEmployeeResponse'
  employeeId: Scalars['ID']['output']
}

export type SeasonalEmploymentStatus = EmploymentStatus & {
  __typename?: 'SeasonalEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type Section = {
  __typename?: 'Section'
  items: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']['output']
  labelNew: LocalizableMessage
  name: Scalars['String']['output']
}

export type SectionResult = {
  __typename?: 'SectionResult'
  errors: Array<NavigationErrors>
  result: Array<Section>
}

export type SectionsInput = {
  name?: InputMaybe<Scalars['String']['input']>
  params?: InputMaybe<Array<NavigationParam>>
}

export type SecurityRole =
  | 'APPLICANT'
  | 'CUSTOM'
  | 'CUSTOMER'
  | 'EMPLOYEE'
  | 'FIRED'
  | 'HR_EXECUTIVE'
  | 'HR_PLUS'
  | 'MANAGER'
  | 'NEW_HIRE'
  | 'PAYROLL'
  | 'PAYROLL_OPERATIONS'
  | 'SUPERUSER'
  | 'SUPER_ADMIN'
  | 'SUPER_ADMIN_LIGHT'

export type SendEmailVerificationError =
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch

export type SendVerificationEmailInput = {
  idempotencyKey: Scalars['String']['input']
}

export type SendVerificationEmailResult =
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailSent

export type SortDirection = 'ASC' | 'DESC'

export type SplitDepositType = 'DOLLAR' | 'PERCENTAGE'

export type StateInUnitedStates = {
  __typename?: 'StateInUnitedStates'
  abbreviation: Scalars['String']['output']
  fipsCode: Scalars['Int']['output']
  fullName: Scalars['String']['output']
}

export type Supervisor = {
  __typename?: 'Supervisor'
  id: EmploymentId
  job?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type Supervisors = {
  __typename?: 'Supervisors'
  supervisors: Array<Supervisor>
}

export type TaskStatus =
  | 'CANCELED'
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'LOCKED'
  | 'NOT_STARTED'
  | 'ON_HOLD'
  | 'SKIPPED'

export type TerminatedEmploymentStatus = EmploymentStatus & {
  __typename?: 'TerminatedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
  lastDayOfEmployment?: Maybe<Scalars['String']['output']>
  lastDayToReceiveBenefits?: Maybe<Scalars['String']['output']>
}

export type ThirdPartyBenefit = {
  __typename?: 'ThirdPartyBenefit'
  benefitType: ThirdPartyBenefitType
}

export type ThirdPartyBenefitType = 'DENTAL' | 'LIFE' | 'MEDICAL' | 'VISION'

export type TimesheetRetrievalError = {
  __typename?: 'TimesheetRetrievalError'
  message?: Maybe<Scalars['String']['output']>
}

export type ToastVersionIgnored = {
  __typename?: 'ToastVersionIgnored'
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UnlinkUserResult = PayrollUser | UserNotFound | UserNotLinked

export type UpdateEmployeePaymentMethodEmailResponse =
  | EmailInvalidError
  | EmailSendingError
  | EmployeeNotFoundError
  | EmployeePaymentMethodEmailResponse

export type UpdatePayCardAdminSettingsInput = {
  payCardAdminPayoutsLocked?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdatePayCardCustomerSettingsInput = {
  payCardAdminSettings?: InputMaybe<UpdatePayCardAdminSettingsInput>
  payCardPayoutConfiguration?: InputMaybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled?: InputMaybe<Scalars['Boolean']['input']>
  payCardTipsConfiguration?: InputMaybe<PayCardTipsConfiguration>
}

export type UpdatePayCardCustomerSettingsResponse = {
  __typename?: 'UpdatePayCardCustomerSettingsResponse'
  payCardCustomerSettings: PayCardCustomerSettings
}

export type UpdatePendingPayChangeStatusResponse =
  | EmployeeNotFoundError
  | PendingPayChangeNotFoundErrors
  | PendingPayChangeStatusResponse

export type User = {
  __typename?: 'User'
  email?: Maybe<Scalars['String']['output']>
  employeeUuid?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  isEmailVerified: Scalars['Boolean']['output']
  name: PersonName
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type UserAlreadyLinked = {
  __typename?: 'UserAlreadyLinked'
  username: Scalars['String']['output']
}

export type UserDocument = {
  __typename?: 'UserDocument'
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type UserDocumentType = 'W2'

export type UserDocuments = {
  __typename?: 'UserDocuments'
  documents: Array<UserDocument>
}

export type UserEvent =
  | UserLinkChanged
  | UserLinked
  | UserRoleAdded
  | UserRoleRemoved
  | UserUnlinked
  | UsernameChanged
  | UsernameChangedWithConflict

export type UserLinkChanged = {
  __typename?: 'UserLinkChanged'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  newToastIdentityGuid: Scalars['ID']['output']
  oldToastIdentityGuid: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserLinked = {
  __typename?: 'UserLinked'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserMigrationChooseAnotherUsername = {
  __typename?: 'UserMigrationChooseAnotherUsername'
  message: Scalars['String']['output']
}

export type UserMigrationConfirmAccountVerificationEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailConfirmationFailed
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationDefaultEmailSource = 'NEW' | 'PAYROLL' | 'TOAST'

export type UserMigrationDefaults = {
  __typename?: 'UserMigrationDefaults'
  canChangeEmail: Scalars['Boolean']['output']
  email?: Maybe<Scalars['String']['output']>
  source: UserMigrationDefaultEmailSource
}

export type UserMigrationDefaultsResult =
  | UserMigrationDefaults
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationEmailConfirmationFailed = {
  __typename?: 'UserMigrationEmailConfirmationFailed'
  error: ConfirmVerificationEmailError
}

export type UserMigrationEmailExistsInCustomer = {
  __typename?: 'UserMigrationEmailExistsInCustomer'
  customerId: Scalars['ID']['output']
}

export type UserMigrationEmailNotVerified = {
  __typename?: 'UserMigrationEmailNotVerified'
  toastAccountType: UserMigrationToastAccountType
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserMigrationEmailVerified = {
  __typename?: 'UserMigrationEmailVerified'
  toastAccountType: UserMigrationToastAccountType
  toastIdentityGuid: Scalars['ID']['output']
  verifiedTime: Scalars['DateTime']['output']
}

export type UserMigrationFailed = {
  __typename?: 'UserMigrationFailed'
  message: Scalars['String']['output']
}

export type UserMigrationMigrateResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailNotVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSuccess
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationMutation = {
  __typename?: 'UserMigrationMutation'
  confirmAccountVerificationEmail: UserMigrationConfirmAccountVerificationEmailResult
  migrate: UserMigrationMigrateResult
  sendAccountVerificationEmail: UserMigrationSendAccountVerificationEmailResult
}

export type UserMigrationMutationConfirmAccountVerificationEmailArgs = {
  confirmationKey: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type UserMigrationMutationMigrateArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type UserMigrationMutationSendAccountVerificationEmailArgs = {
  email: Scalars['String']['input']
  idempotencyKey: Scalars['String']['input']
}

export type UserMigrationNotEnabled = {
  __typename?: 'UserMigrationNotEnabled'
  message: Scalars['String']['output']
}

export type UserMigrationQuery = {
  __typename?: 'UserMigrationQuery'
  defaults: UserMigrationDefaultsResult
  verifyAccountEmail: UserMigrationVerifyAccountEmailResult
}

export type UserMigrationQueryVerifyAccountEmailArgs = {
  email: Scalars['String']['input']
}

export type UserMigrationSendAccountVerificationEmailFailed = {
  __typename?: 'UserMigrationSendAccountVerificationEmailFailed'
  error?: Maybe<SendEmailVerificationError>
}

export type UserMigrationSendAccountVerificationEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSendAccountVerificationEmailFailed
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound
  | UserMigrationVerificationEmailSent

export type UserMigrationSuccess = {
  __typename?: 'UserMigrationSuccess'
  email: Scalars['String']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type UserMigrationToastAccountType = 'CREATED' | 'EXISTING' | 'MAPPED'

export type UserMigrationToastUserHasCredentials = {
  __typename?: 'UserMigrationToastUserHasCredentials'
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserMigrationUserAlreadyMigrated = {
  __typename?: 'UserMigrationUserAlreadyMigrated'
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type UserMigrationUserNotFound = {
  __typename?: 'UserMigrationUserNotFound'
  userId: Scalars['ID']['output']
}

export type UserMigrationVerificationEmailSent = {
  __typename?: 'UserMigrationVerificationEmailSent'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  expirationTime: Scalars['DateTime']['output']
  idempotencyKey: Scalars['ID']['output']
}

export type UserMigrationVerifyAccountEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailNotVerified
  | UserMigrationEmailVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserNotFound = {
  __typename?: 'UserNotFound'
  userId: Scalars['ID']['output']
}

export type UserNotLinked = {
  __typename?: 'UserNotLinked'
  userId: Scalars['ID']['output']
}

export type UserNotMapped = {
  __typename?: 'UserNotMapped'
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserRoleAdded = {
  __typename?: 'UserRoleAdded'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  role?: Maybe<SecurityRole>
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserRoleRemoved = {
  __typename?: 'UserRoleRemoved'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  role?: Maybe<SecurityRole>
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserUnlinked = {
  __typename?: 'UserUnlinked'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameChanged = {
  __typename?: 'UsernameChanged'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  fromUsername?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
  toUsername?: Maybe<Scalars['String']['output']>
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameChangedWithConflict = {
  __typename?: 'UsernameChangedWithConflict'
  actorId: Scalars['ID']['output']
  conflictingUserId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  fromUsername?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
  toUsername?: Maybe<Scalars['String']['output']>
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameExists = {
  __typename?: 'UsernameExists'
  username: Scalars['String']['output']
}

export type Users = {
  __typename?: 'Users'
  list: Array<Maybe<User>>
}

export type VerificationEmailConfirmed = {
  __typename?: 'VerificationEmailConfirmed'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
}

export type VerificationEmailNotSent = {
  __typename?: 'VerificationEmailNotSent'
  message?: Maybe<Scalars['String']['output']>
}

export type VerificationEmailSent = {
  __typename?: 'VerificationEmailSent'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  expirationTime: Scalars['DateTime']['output']
  idempotencyKey: Scalars['ID']['output']
}

export type VeteranStatus =
  | 'ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN'
  | 'ARMED_FORCES_SERVICE_MEDAL_VETERAN'
  | 'DISABLED_VETERAN'
  | 'NOT_A_VETERAN'
  | 'RECENTLY_SEPARATED_VETERAN'
  | 'SPECIAL_DISABLED_VETERAN'
  | 'VETERAN'
  | 'VIETNAM_ERA_VETERAN'

export type EmployeeCountQueryQueryVariables = Exact<{ [key: string]: never }>

export type EmployeeCountQueryQuery = {
  __typename: 'Query'
  employeeStatusTypeCount: {
    __typename: 'EmployeeStatusTypeCount'
    active?: number | null
  }
}

export type IncompleteDirectDepositsQueryEmployeeFragment = {
  __typename: 'IncompleteDirectDepositEmployee'
  id: string
  email: string
  name: {
    __typename: 'PersonName'
    first?: string | null
    last?: string | null
    chosen?: string | null
  }
  notification?: {
    __typename: 'DirectDepositNotification'
    email?: string | null
    lastContactedUtc?: any | null
    status?: DirectDepositNotificationStatus | null
  } | null
}

export type IncompleteDirectDepositsQueryNotificationFragment = {
  __typename: 'DirectDepositNotification'
  email?: string | null
  lastContactedUtc?: any | null
  status?: DirectDepositNotificationStatus | null
}

export type IncompleteDirectDepositsQueryVariables = Exact<{
  incompleteDirectDepositInput: IncompleteDirectDepositInput
}>

export type IncompleteDirectDepositsQuery = {
  __typename: 'Query'
  incompleteDirectDeposits: {
    __typename: 'IncompleteDirectDepositsResponse'
    totalCount: number
    hasNextPage: boolean
    hasPreviousPage: boolean
    employees: Array<{
      __typename: 'IncompleteDirectDepositEmployee'
      id: string
      email: string
      name: {
        __typename: 'PersonName'
        first?: string | null
        last?: string | null
        chosen?: string | null
      }
      notification?: {
        __typename: 'DirectDepositNotification'
        email?: string | null
        lastContactedUtc?: any | null
        status?: DirectDepositNotificationStatus | null
      } | null
    }>
  }
}

export type EmailPreviewQueryQueryVariables = Exact<{ [key: string]: never }>

export type EmailPreviewQueryQuery = {
  __typename: 'Query'
  directDepositEmailTemplate?: string | null
}

export type SendDirectDepositInfoRequestEmailMutationVariables = Exact<{
  employees: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type SendDirectDepositInfoRequestEmailMutation = {
  __typename: 'Mutation'
  directDepositBulkEmail: Array<{
    __typename: 'DirectDepositBulkEmailResponse'
    id: string
    status: DirectDepositNotificationStatus
  }>
}

export type DirectDepositsQueryEmployeeFragment = {
  __typename: 'CompleteDirectDepositEmployee'
  id: string
  email: string
  name: {
    __typename: 'PersonName'
    first?: string | null
    last?: string | null
    chosen?: string | null
  }
  allocations: Array<{
    __typename: 'DirectDepositAllocation'
    depositAccountType: DepositAccountType
    splitDepositType: SplitDepositType
    amount: number
    sequence: number
  }>
}

export type DirectDepositsQueryAllocationFragment = {
  __typename: 'DirectDepositAllocation'
  depositAccountType: DepositAccountType
  splitDepositType: SplitDepositType
  amount: number
  sequence: number
}

export type DirectDepositsQueryVariables = Exact<{
  directDepositsInput: DirectDepositPaginationInput
}>

export type DirectDepositsQuery = {
  __typename: 'Query'
  completeDirectDeposits: {
    __typename: 'CompleteDirectDepositsResponse'
    totalCount: number
    hasNextPage: boolean
    hasPreviousPage: boolean
    employees: Array<{
      __typename: 'CompleteDirectDepositEmployee'
      id: string
      email: string
      name: {
        __typename: 'PersonName'
        first?: string | null
        last?: string | null
        chosen?: string | null
      }
      allocations: Array<{
        __typename: 'DirectDepositAllocation'
        depositAccountType: DepositAccountType
        splitDepositType: SplitDepositType
        amount: number
        sequence: number
      }>
    }>
  }
}

export const IncompleteDirectDepositsQueryNotificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'IncompleteDirectDepositsQueryNotification'
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DirectDepositNotification' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastContactedUtc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  IncompleteDirectDepositsQueryNotificationFragment,
  unknown
>
export const IncompleteDirectDepositsQueryEmployeeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IncompleteDirectDepositsQueryEmployee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IncompleteDirectDepositEmployee' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chosen' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'IncompleteDirectDepositsQueryNotification'
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'IncompleteDirectDepositsQueryNotification'
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DirectDepositNotification' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastContactedUtc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  IncompleteDirectDepositsQueryEmployeeFragment,
  unknown
>
export const DirectDepositsQueryAllocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DirectDepositsQueryAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DirectDepositAllocation' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositAccountType' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'splitDepositType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DirectDepositsQueryAllocationFragment, unknown>
export const DirectDepositsQueryEmployeeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DirectDepositsQueryEmployee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CompleteDirectDepositEmployee' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chosen' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DirectDepositsQueryAllocation' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DirectDepositsQueryAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DirectDepositAllocation' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositAccountType' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'splitDepositType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DirectDepositsQueryEmployeeFragment, unknown>
export const EmployeeCountQueryDocument = {
  __meta__: { hash: 'd687f87685d63722cccc9cfeb07c0aac393ee504' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmployeeCountQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeStatusTypeCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  EmployeeCountQueryQuery,
  EmployeeCountQueryQueryVariables
>
export const IncompleteDirectDepositsDocument = {
  __meta__: { hash: 'eda341d96a3e44c6bdc6015a347150ce29a03436' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IncompleteDirectDeposits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'incompleteDirectDepositInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'IncompleteDirectDepositInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incompleteDirectDeposits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'incompleteDirectDepositInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'incompleteDirectDepositInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPreviousPage' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' }
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'IncompleteDirectDepositsQueryEmployee'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'IncompleteDirectDepositsQueryNotification'
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DirectDepositNotification' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastContactedUtc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IncompleteDirectDepositsQueryEmployee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IncompleteDirectDepositEmployee' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chosen' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'IncompleteDirectDepositsQueryNotification'
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  IncompleteDirectDepositsQuery,
  IncompleteDirectDepositsQueryVariables
>
export const EmailPreviewQueryDocument = {
  __meta__: { hash: 'f6b8ed817196d079592099d885c97809524797ac' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailPreviewQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directDepositEmailTemplate' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  EmailPreviewQueryQuery,
  EmailPreviewQueryQueryVariables
>
export const SendDirectDepositInfoRequestEmailDocument = {
  __meta__: { hash: 'abd6486713fc34816cab30d003240458f3cc9834' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendDirectDepositInfoRequestEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'employees' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directDepositBulkEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'employees' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'employees' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SendDirectDepositInfoRequestEmailMutation,
  SendDirectDepositInfoRequestEmailMutationVariables
>
export const DirectDepositsDocument = {
  __meta__: { hash: 'a015b16c1713b863e40fda37a610ef9b7fef5164' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DirectDeposits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'directDepositsInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DirectDepositPaginationInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeDirectDeposits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'completeDirectDepositInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'directDepositsInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPreviousPage' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' }
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'DirectDepositsQueryEmployee'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DirectDepositsQueryAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DirectDepositAllocation' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositAccountType' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'splitDepositType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DirectDepositsQueryEmployee' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CompleteDirectDepositEmployee' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chosen' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DirectDepositsQueryAllocation' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DirectDepositsQuery, DirectDepositsQueryVariables>

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ReferenceResolver<TResult, TReference, TContext> = (
  reference: TReference,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

type ScalarCheck<T, S> = S extends true ? T : NullableCheck<T, S>
type NullableCheck<T, S> = Maybe<T> extends T
  ? Maybe<ListCheck<NonNullable<T>, S>>
  : ListCheck<T, S>
type ListCheck<T, S> = T extends (infer U)[]
  ? NullableCheck<U, S>[]
  : GraphQLRecursivePick<T, S>
export type GraphQLRecursivePick<T, S> = {
  [K in keyof T & keyof S]: ScalarCheck<T[K], S[K]>
}

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping of union types */
export type ResolversUnionTypes<RefType extends Record<string, unknown>> = {
  AdditionalEarningResponse:
    | EarningListEmptyError
    | EmployeeNotFoundError
    | JobAssignmentNotFoundError
    | MissingPayGroupError
    | NextPayPeriodNotFoundError
    | NextPaycheckDateNotFoundError
    | Paycheck
  AllEmploymentStatuses:
    | ActiveEmploymentStatus
    | ApplicantEmploymentStatus
    | DeletedEmploymentStatus
    | DemoEmploymentStatus
    | ImplementationEmploymentStatus
    | LeaveOfAbsenceEmploymentStatus
    | NeverEmployedEmploymentStatus
    | PendingEmploymentStatus
    | RehiredEmploymentStatus
    | SeasonalEmploymentStatus
    | TerminatedEmploymentStatus
  ChangeLinkedUsernameResult:
    | CannotChangeUnlinkedUsername
    | InvalidUsername
    | PayrollUser
    | ToastVersionIgnored
    | UserNotFound
    | UsernameExists
  ChangeSalaryResponse:
    | EmployeeNotFoundError
    | EmptySalaryAllocationsError
    | InvalidEffectiveDateError
    | Salary
    | SalaryAllocationNotFoundError
    | SalaryNotFoundError
  ChangeUnlinkedUsernameResult:
    | CannotChangeLinkedUsername
    | InvalidUsername
    | PayrollUser
    | UserNotFound
    | UsernameExists
  ConfirmVerificationEmailError:
    | ConfirmationExpired
    | EmailAlreadyVerified
    | EmailHasBeenUpdated
    | EmailVerificationFailed
    | KeyDoesNotMatch
    | VerificationEmailNotSent
  ConfirmVerificationEmailResult:
    | ConfirmationExpired
    | EmailAlreadyVerified
    | EmailHasBeenUpdated
    | EmailVerificationFailed
    | KeyDoesNotMatch
    | VerificationEmailConfirmed
    | VerificationEmailNotSent
  EmailVerificationError:
    | ConfirmationExpired
    | DuplicateRequest
    | EmailAlreadyVerified
    | EmailHasBeenUpdated
    | EmailNotFound
    | EmailVerificationFailed
    | KeyDoesNotMatch
    | VerificationEmailNotSent
  EmailVerificationResult:
    | ConfirmationExpired
    | DuplicateRequest
    | EmailAlreadyVerified
    | EmailHasBeenUpdated
    | EmailNotFound
    | EmailVerification
    | EmailVerificationFailed
    | KeyDoesNotMatch
    | VerificationEmailNotSent
  HourlyRetroPayResponse:
    | HourlyRetroPay
    | InvalidStartDateError
    | JobAssignmentNotFoundError
    | TimesheetRetrievalError
  LinkIfMappedResult:
    | LinkingNotAvailable
    | MappedUserMismatchedUsername
    | PayrollUser
    | UserAlreadyLinked
    | UserNotMapped
  LinkUserResult: PayrollUser | UserNotFound
  PayChangeLogResponse:
    | AbsentPayChangeLogTokenError
    | InvalidPayChangeLogTokenError
    | PayChangeLog
  SalaryRetroPayResponse:
    | InvalidStartDateError
    | SalaryPayFrequencyDoesNotExistError
    | SalaryPayFrequencyNoneError
    | SalaryRetroPay
    | TimesheetRetrievalError
  SendEmailVerificationError:
    | DuplicateRequest
    | EmailAlreadyVerified
    | EmailHasBeenUpdated
    | EmailVerificationFailed
    | KeyDoesNotMatch
  SendVerificationEmailResult:
    | DuplicateRequest
    | EmailAlreadyVerified
    | EmailHasBeenUpdated
    | EmailVerificationFailed
    | KeyDoesNotMatch
    | VerificationEmailSent
  UnlinkUserResult: PayrollUser | UserNotFound | UserNotLinked
  UpdateEmployeePaymentMethodEmailResponse:
    | EmailInvalidError
    | EmailSendingError
    | EmployeeNotFoundError
    | EmployeePaymentMethodEmailResponse
  UpdatePendingPayChangeStatusResponse:
    | EmployeeNotFoundError
    | PendingPayChangeNotFoundErrors
    | PendingPayChangeStatusResponse
  UserEvent:
    | UserLinkChanged
    | UserLinked
    | UserRoleAdded
    | UserRoleRemoved
    | UserUnlinked
    | UsernameChanged
    | UsernameChangedWithConflict
  UserMigrationConfirmAccountVerificationEmailResult:
    | UserMigrationChooseAnotherUsername
    | (Omit<UserMigrationEmailConfirmationFailed, 'error'> & {
        error: RefType['ConfirmVerificationEmailError']
      })
    | UserMigrationEmailExistsInCustomer
    | UserMigrationEmailVerified
    | UserMigrationFailed
    | UserMigrationNotEnabled
    | UserMigrationToastUserHasCredentials
    | UserMigrationUserAlreadyMigrated
    | UserMigrationUserNotFound
  UserMigrationDefaultsResult:
    | UserMigrationDefaults
    | UserMigrationFailed
    | UserMigrationNotEnabled
    | UserMigrationUserAlreadyMigrated
    | UserMigrationUserNotFound
  UserMigrationMigrateResult:
    | UserMigrationChooseAnotherUsername
    | UserMigrationEmailExistsInCustomer
    | UserMigrationEmailNotVerified
    | UserMigrationFailed
    | UserMigrationNotEnabled
    | UserMigrationSuccess
    | UserMigrationToastUserHasCredentials
    | UserMigrationUserAlreadyMigrated
    | UserMigrationUserNotFound
  UserMigrationSendAccountVerificationEmailResult:
    | UserMigrationChooseAnotherUsername
    | UserMigrationEmailExistsInCustomer
    | UserMigrationFailed
    | UserMigrationNotEnabled
    | (Omit<UserMigrationSendAccountVerificationEmailFailed, 'error'> & {
        error?: Maybe<RefType['SendEmailVerificationError']>
      })
    | UserMigrationToastUserHasCredentials
    | UserMigrationUserAlreadyMigrated
    | UserMigrationUserNotFound
    | UserMigrationVerificationEmailSent
  UserMigrationVerifyAccountEmailResult:
    | UserMigrationChooseAnotherUsername
    | UserMigrationEmailExistsInCustomer
    | UserMigrationEmailNotVerified
    | UserMigrationEmailVerified
    | UserMigrationFailed
    | UserMigrationNotEnabled
    | UserMigrationToastUserHasCredentials
    | UserMigrationUserAlreadyMigrated
    | UserMigrationUserNotFound
}

/** Mapping of interface types */
export type ResolversInterfaceTypes<RefType extends Record<string, unknown>> = {
  EmploymentStatus:
    | ActiveEmploymentStatus
    | ApplicantEmploymentStatus
    | DeletedEmploymentStatus
    | DemoEmploymentStatus
    | ImplementationEmploymentStatus
    | LeaveOfAbsenceEmploymentStatus
    | NeverEmployedEmploymentStatus
    | PendingEmploymentStatus
    | RehiredEmploymentStatus
    | SeasonalEmploymentStatus
    | TerminatedEmploymentStatus
}

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AbsentPayChangeLogTokenError: ResolverTypeWrapper<AbsentPayChangeLogTokenError>
  String: ResolverTypeWrapper<Scalars['String']['output']>
  AccessibleCustomer: ResolverTypeWrapper<AccessibleCustomer>
  AccessibleCustomersInput: AccessibleCustomersInput
  ID: ResolverTypeWrapper<Scalars['ID']['output']>
  Int: ResolverTypeWrapper<Scalars['Int']['output']>
  AccessibleCustomersResult: ResolverTypeWrapper<AccessibleCustomersResult>
  ActiveEmploymentStatus: ResolverTypeWrapper<ActiveEmploymentStatus>
  Actor: ResolverTypeWrapper<Actor>
  ActorPermissionsForEmployee: ResolverTypeWrapper<ActorPermissionsForEmployee>
  AdditionalDocumentInput: AdditionalDocumentInput
  AdditionalEarningResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['AdditionalEarningResponse']
  >
  AllEmploymentStatuses: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['AllEmploymentStatuses']
  >
  AmountWithPayInterval: ResolverTypeWrapper<AmountWithPayInterval>
  ApplicantEmploymentStatus: ResolverTypeWrapper<ApplicantEmploymentStatus>
  Assignee: Assignee
  AvailabilityRequest: AvailabilityRequest
  AvailabilityResponse: ResolverTypeWrapper<AvailabilityResponse>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>
  Benefit: ResolverTypeWrapper<Benefit>
  BenefitOption: ResolverTypeWrapper<BenefitOption>
  Benefits: ResolverTypeWrapper<Benefits>
  BenefitsInput: BenefitsInput
  BenefitsThirdPartyDataSharingConsent: ResolverTypeWrapper<BenefitsThirdPartyDataSharingConsent>
  Float: ResolverTypeWrapper<Scalars['Float']['output']>
  BulkNotifyResponse: ResolverTypeWrapper<BulkNotifyResponse>
  CannotChangeLinkedUsername: ResolverTypeWrapper<CannotChangeLinkedUsername>
  CannotChangeUnlinkedUsername: ResolverTypeWrapper<CannotChangeUnlinkedUsername>
  ChangeLinkedUsernameResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ChangeLinkedUsernameResult']
  >
  ChangePayInput: ChangePayInput
  ChangeSalaryAllocationInput: ChangeSalaryAllocationInput
  ChangeSalaryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ChangeSalaryResponse']
  >
  ChangeUnlinkedUsernameResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ChangeUnlinkedUsernameResult']
  >
  ChecklistState: ResolverTypeWrapper<ChecklistState>
  CompleteDirectDepositEmployee: ResolverTypeWrapper<CompleteDirectDepositEmployee>
  CompleteDirectDepositsResponse: ResolverTypeWrapper<CompleteDirectDepositsResponse>
  ConfirmVerificationEmailError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ConfirmVerificationEmailError']
  >
  ConfirmVerificationEmailInput: ConfirmVerificationEmailInput
  ConfirmVerificationEmailResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ConfirmVerificationEmailResult']
  >
  ConfirmationExpired: ResolverTypeWrapper<ConfirmationExpired>
  ContactTelephoneNumber: ResolverTypeWrapper<ContactTelephoneNumber>
  Country: ResolverTypeWrapper<Country>
  CreateJobTagAssignmentInput: CreateJobTagAssignmentInput
  CreateJobTagInput: CreateJobTagInput
  Currency: Currency
  CustomDocumentFieldInput: CustomDocumentFieldInput
  CustomDocumentFields: ResolverTypeWrapper<CustomDocumentFields>
  CustomDocumentFieldsV2: ResolverTypeWrapper<CustomDocumentFieldsV2>
  Customer: ResolverTypeWrapper<Customer>
  CustomerConfiguration: ResolverTypeWrapper<CustomerConfiguration>
  CustomerInput: CustomerInput
  CustomerPosition: ResolverTypeWrapper<CustomerPosition>
  CustomerSetting: ResolverTypeWrapper<CustomerSetting>
  DataExceptionError: ResolverTypeWrapper<DataExceptionError>
  Date: ResolverTypeWrapper<Scalars['Date']['output']>
  DateRange: ResolverTypeWrapper<DateRange>
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>
  Decimal: ResolverTypeWrapper<Scalars['Decimal']['output']>
  DefaultEarningsId: ResolverTypeWrapper<DefaultEarningsId>
  DeletedEmploymentStatus: ResolverTypeWrapper<DeletedEmploymentStatus>
  DemoEmploymentStatus: ResolverTypeWrapper<DemoEmploymentStatus>
  DepositAccountType: DepositAccountType
  DirectDepositAllocation: ResolverTypeWrapper<DirectDepositAllocation>
  DirectDepositBulkEmailResponse: ResolverTypeWrapper<DirectDepositBulkEmailResponse>
  DirectDepositNotification: ResolverTypeWrapper<DirectDepositNotification>
  DirectDepositNotificationStatus: DirectDepositNotificationStatus
  DirectDepositPaginationInput: DirectDepositPaginationInput
  DisabilityStatus: DisabilityStatus
  DriversLicense: ResolverTypeWrapper<DriversLicense>
  DuplicateRequest: ResolverTypeWrapper<DuplicateRequest>
  EarningListEmptyError: ResolverTypeWrapper<EarningListEmptyError>
  EeoClassification: ResolverTypeWrapper<EeoClassification>
  EeoClassificationInput: EeoClassificationInput
  EmailAlreadyVerified: ResolverTypeWrapper<EmailAlreadyVerified>
  EmailHasBeenUpdated: ResolverTypeWrapper<EmailHasBeenUpdated>
  EmailInvalidError: ResolverTypeWrapper<EmailInvalidError>
  EmailNotFound: ResolverTypeWrapper<EmailNotFound>
  EmailSendingError: ResolverTypeWrapper<EmailSendingError>
  EmailVerification: ResolverTypeWrapper<EmailVerification>
  EmailVerificationError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['EmailVerificationError']
  >
  EmailVerificationFailed: ResolverTypeWrapper<EmailVerificationFailed>
  EmailVerificationMutation: ResolverTypeWrapper<
    Omit<
      EmailVerificationMutation,
      'confirmVerificationEmail' | 'sendVerificationEmail'
    > & {
      confirmVerificationEmail: ResolversTypes['ConfirmVerificationEmailResult']
      sendVerificationEmail: ResolversTypes['SendVerificationEmailResult']
    }
  >
  EmailVerificationQuery: ResolverTypeWrapper<
    Omit<EmailVerificationQuery, 'verification'> & {
      verification?: Maybe<ResolversTypes['EmailVerificationResult']>
    }
  >
  EmailVerificationResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['EmailVerificationResult']
  >
  EmergencyContact: ResolverTypeWrapper<EmergencyContact>
  Employee: ResolverTypeWrapper<Employee>
  EmployeeAtLocation: ResolverTypeWrapper<EmployeeAtLocation>
  EmployeeContact: ResolverTypeWrapper<EmployeeContact>
  EmployeeDemographics: ResolverTypeWrapper<EmployeeDemographics>
  EmployeeDocumentUploadRequest: EmployeeDocumentUploadRequest
  EmployeeIdentification: ResolverTypeWrapper<EmployeeIdentification>
  EmployeeJob: ResolverTypeWrapper<EmployeeJob>
  EmployeeMappingStatus: ResolverTypeWrapper<EmployeeMappingStatus>
  EmployeeNewHireDocument: ResolverTypeWrapper<EmployeeNewHireDocument>
  EmployeeNotFoundError: ResolverTypeWrapper<EmployeeNotFoundError>
  EmployeePay: ResolverTypeWrapper<EmployeePay>
  EmployeePayHistory: ResolverTypeWrapper<EmployeePayHistory>
  EmployeePaymentMethodEmailResponse: ResolverTypeWrapper<EmployeePaymentMethodEmailResponse>
  EmployeeStatusTypeCount: ResolverTypeWrapper<EmployeeStatusTypeCount>
  EmployeeSupervisor: ResolverTypeWrapper<EmployeeSupervisor>
  Employment: ResolverTypeWrapper<
    Omit<Employment, 'employmentStatus'> & {
      employmentStatus: ResolversTypes['AllEmploymentStatuses']
    }
  >
  EmploymentDetails: ResolverTypeWrapper<EmploymentDetails>
  EmploymentDetailsInput: EmploymentDetailsInput
  EmploymentId: ResolverTypeWrapper<EmploymentId>
  EmploymentStatus: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['EmploymentStatus']
  >
  EmploymentStatusType: EmploymentStatusType
  EmploymentTaxType: EmploymentTaxType
  EmptySalaryAllocationsError: ResolverTypeWrapper<EmptySalaryAllocationsError>
  Ethnicity: Ethnicity
  FindUserEventsByUserIdInput: FindUserEventsByUserIdInput
  Gender: Gender
  GenericResponse: ResolverTypeWrapper<GenericResponse>
  HourlyEarningInput: HourlyEarningInput
  HourlyRetroPay: ResolverTypeWrapper<HourlyRetroPay>
  HourlyRetroPayInput: HourlyRetroPayInput
  HourlyRetroPayResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['HourlyRetroPayResponse']
  >
  HumanResourcesPermission: HumanResourcesPermission
  ImplementationEmploymentStatus: ResolverTypeWrapper<ImplementationEmploymentStatus>
  IncompleteDirectDepositEmployee: ResolverTypeWrapper<IncompleteDirectDepositEmployee>
  IncompleteDirectDepositInput: IncompleteDirectDepositInput
  IncompleteDirectDepositSortColumn: IncompleteDirectDepositSortColumn
  IncompleteDirectDepositsResponse: ResolverTypeWrapper<IncompleteDirectDepositsResponse>
  InputCannotBeEmpty: ResolverTypeWrapper<InputCannotBeEmpty>
  IntercomClientType: IntercomClientType
  IntercomSessionDataResponse: ResolverTypeWrapper<IntercomSessionDataResponse>
  InvalidEffectiveDateError: ResolverTypeWrapper<InvalidEffectiveDateError>
  InvalidPayChangeLogTokenError: ResolverTypeWrapper<InvalidPayChangeLogTokenError>
  InvalidStartDateError: ResolverTypeWrapper<InvalidStartDateError>
  InvalidUsername: ResolverTypeWrapper<InvalidUsername>
  IssueSeverity: IssueSeverity
  IssueType: IssueType
  Item: ResolverTypeWrapper<Item>
  Job: ResolverTypeWrapper<Job>
  JobAssignment: ResolverTypeWrapper<JobAssignment>
  JobAssignmentNotFoundError: ResolverTypeWrapper<JobAssignmentNotFoundError>
  JobInput: JobInput
  JobMapping: ResolverTypeWrapper<JobMapping>
  JobTag: ResolverTypeWrapper<JobTag>
  JobTagAssignment: ResolverTypeWrapper<JobTagAssignment>
  KeyDoesNotMatch: ResolverTypeWrapper<KeyDoesNotMatch>
  LeaveOfAbsenceEmploymentStatus: ResolverTypeWrapper<LeaveOfAbsenceEmploymentStatus>
  LevelId: ResolverTypeWrapper<LevelId>
  LevelIdInput: LevelIdInput
  LinkIfMappedResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['LinkIfMappedResult']
  >
  LinkUserResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['LinkUserResult']
  >
  LinkingNotAvailable: ResolverTypeWrapper<LinkingNotAvailable>
  LocalizableMessage: ResolverTypeWrapper<LocalizableMessage>
  Location: ResolverTypeWrapper<Location>
  LocationInput: LocationInput
  LocationMappingStatus: ResolverTypeWrapper<LocationMappingStatus>
  LocationMappingStatusCode: LocationMappingStatusCode
  Long: ResolverTypeWrapper<Scalars['Long']['output']>
  LumpSumEarningInput: LumpSumEarningInput
  MappedUserMismatchedUsername: ResolverTypeWrapper<MappedUserMismatchedUsername>
  MappingAssignment: MappingAssignment
  MappingCondition: MappingCondition
  MappingIssue: ResolverTypeWrapper<MappingIssue>
  MaritalStatus: MaritalStatus
  MissingPayGroupError: ResolverTypeWrapper<MissingPayGroupError>
  Money: ResolverTypeWrapper<Money>
  MoneyInput: MoneyInput
  Mutation: ResolverTypeWrapper<{}>
  NavigableTaxAccount: ResolverTypeWrapper<NavigableTaxAccount>
  NavigableTaxAccountResult: ResolverTypeWrapper<NavigableTaxAccountResult>
  NavigableTaxAccountsInput: NavigableTaxAccountsInput
  Navigation: ResolverTypeWrapper<Navigation>
  NavigationError: ResolverTypeWrapper<NavigationError>
  NavigationErrors: ResolverTypeWrapper<NavigationErrors>
  NavigationInput: NavigationInput
  NavigationParam: NavigationParam
  NavigationResult: ResolverTypeWrapper<NavigationResult>
  NeverEmployedEmploymentStatus: ResolverTypeWrapper<NeverEmployedEmploymentStatus>
  NewEmployeeInput: NewEmployeeInput
  NewHireDocument: ResolverTypeWrapper<NewHireDocument>
  NewJobAssignment: ResolverTypeWrapper<NewJobAssignment>
  NewJobAssignmentInput: NewJobAssignmentInput
  NewJobsAndPay: ResolverTypeWrapper<NewJobsAndPay>
  NewJobsAndPayInput: NewJobsAndPayInput
  NextPayPeriodNotFoundError: ResolverTypeWrapper<NextPayPeriodNotFoundError>
  NextPaycheckDateNotFoundError: ResolverTypeWrapper<NextPaycheckDateNotFoundError>
  NotAToastUser: ResolverTypeWrapper<NotAToastUser>
  OnboardingCheckListPermission: OnboardingCheckListPermission
  PageType: PageType
  PayCardAdminSettings: ResolverTypeWrapper<PayCardAdminSettings>
  PayCardBooleanAccessFactor: PayCardBooleanAccessFactor
  PayCardBusinessAccess: ResolverTypeWrapper<PayCardBusinessAccess>
  PayCardCustomerAccess: ResolverTypeWrapper<PayCardCustomerAccess>
  PayCardCustomerAccessInput: PayCardCustomerAccessInput
  PayCardCustomerSettings: ResolverTypeWrapper<PayCardCustomerSettings>
  PayCardCustomerSettingsAuditEntry: ResolverTypeWrapper<PayCardCustomerSettingsAuditEntry>
  PayCardCustomerSettingsAuditHistoryChangeContent: ResolverTypeWrapper<PayCardCustomerSettingsAuditHistoryChangeContent>
  PayCardCustomerSettingsAuditUser: ResolverTypeWrapper<PayCardCustomerSettingsAuditUser>
  PayCardEmployeeAccess: ResolverTypeWrapper<PayCardEmployeeAccess>
  PayCardEmployeeAccessFactors: ResolverTypeWrapper<PayCardEmployeeAccessFactors>
  PayCardEmployeeAccessInput: PayCardEmployeeAccessInput
  PayCardEmployeeAccessLevel: PayCardEmployeeAccessLevel
  PayCardEmployerAccessFactors: PayCardEmployerAccessFactors
  PayCardEmployerAccessLevel: PayCardEmployerAccessLevel
  PayCardPayoutConfiguration: PayCardPayoutConfiguration
  PayCardRestaurantAccess: ResolverTypeWrapper<PayCardRestaurantAccess>
  PayCardRestaurantAccessLevel: PayCardRestaurantAccessLevel
  PayCardTipsConfiguration: PayCardTipsConfiguration
  PayChangeEvent: ResolverTypeWrapper<PayChangeEvent>
  PayChangeEventStatus: PayChangeEventStatus
  PayChangeLog: ResolverTypeWrapper<PayChangeLog>
  PayChangeLogResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['PayChangeLogResponse']
  >
  PayChangeLogSortField: PayChangeLogSortField
  PayChangeLogSortInput: PayChangeLogSortInput
  PayFrequency: PayFrequency
  PayGroup: ResolverTypeWrapper<PayGroup>
  PayGroupAssignment: ResolverTypeWrapper<PayGroupAssignment>
  PayGroupAssignmentInput: PayGroupAssignmentInput
  PayGroupWithFrequency: ResolverTypeWrapper<PayGroupWithFrequency>
  PayInterval: PayInterval
  Paycheck: ResolverTypeWrapper<Paycheck>
  PayrollCheckCode: ResolverTypeWrapper<PayrollCheckCode>
  PayrollCustomerUser: ResolverTypeWrapper<PayrollCustomerUser>
  PayrollCustomerUserSource: PayrollCustomerUserSource
  PayrollCustomerUsersInput: PayrollCustomerUsersInput
  PayrollEmployeeMapping: ResolverTypeWrapper<PayrollEmployeeMapping>
  PayrollFein: ResolverTypeWrapper<PayrollFein>
  PayrollJob: ResolverTypeWrapper<PayrollJob>
  PayrollLocation: ResolverTypeWrapper<PayrollLocation>
  PayrollSecurityRole: ResolverTypeWrapper<PayrollSecurityRole>
  PayrollUser: ResolverTypeWrapper<PayrollUser>
  PayrollUserMutations: ResolverTypeWrapper<
    Omit<
      PayrollUserMutations,
      | 'changeLinkedUsername'
      | 'changeUnlinkedUsername'
      | 'link'
      | 'linkIfMapped'
      | 'unlink'
    > & {
      changeLinkedUsername: Array<ResolversTypes['ChangeLinkedUsernameResult']>
      changeUnlinkedUsername: ResolversTypes['ChangeUnlinkedUsernameResult']
      link: ResolversTypes['LinkUserResult']
      linkIfMapped: Array<ResolversTypes['LinkIfMappedResult']>
      unlink: ResolversTypes['UnlinkUserResult']
    }
  >
  PayrollWorkTaxLocation: ResolverTypeWrapper<PayrollWorkTaxLocation>
  PayrollWorkTaxLocationAddress: ResolverTypeWrapper<PayrollWorkTaxLocationAddress>
  PendingEmploymentStatus: ResolverTypeWrapper<PendingEmploymentStatus>
  PendingPayChange: ResolverTypeWrapper<PendingPayChange>
  PendingPayChangeNotFoundError: ResolverTypeWrapper<PendingPayChangeNotFoundError>
  PendingPayChangeNotFoundErrors: ResolverTypeWrapper<PendingPayChangeNotFoundErrors>
  PendingPayChangeStatus: PendingPayChangeStatus
  PendingPayChangeStatusInput: PendingPayChangeStatusInput
  PendingPayChangeStatusResponse: ResolverTypeWrapper<PendingPayChangeStatusResponse>
  Person: ResolverTypeWrapper<Person>
  PersonInput: PersonInput
  PersonName: ResolverTypeWrapper<PersonName>
  PosAvailabilityRequest: PosAvailabilityRequest
  PosEmployee: ResolverTypeWrapper<PosEmployee>
  PosRawEmployee: ResolverTypeWrapper<PosRawEmployee>
  PosUser: ResolverTypeWrapper<PosUser>
  PostalAddress: ResolverTypeWrapper<PostalAddress>
  Query: ResolverTypeWrapper<{}>
  RehireEmployeeNoChangesInput: RehireEmployeeNoChangesInput
  RehiredEmploymentStatus: ResolverTypeWrapper<RehiredEmploymentStatus>
  RequiredDocumentsRequest: RequiredDocumentsRequest
  Response: ResolverTypeWrapper<Response>
  Restaurant: ResolverTypeWrapper<Restaurant>
  RestaurantJob: ResolverTypeWrapper<RestaurantJob>
  RestaurantJobStatusCode: RestaurantJobStatusCode
  RestaurantUser: ResolverTypeWrapper<RestaurantUser>
  Salary: ResolverTypeWrapper<Salary>
  SalaryAllocation: ResolverTypeWrapper<SalaryAllocation>
  SalaryAllocationNotFoundError: ResolverTypeWrapper<SalaryAllocationNotFoundError>
  SalaryNotFoundError: ResolverTypeWrapper<SalaryNotFoundError>
  SalaryPayFrequencyDoesNotExistError: ResolverTypeWrapper<SalaryPayFrequencyDoesNotExistError>
  SalaryPayFrequencyNoneError: ResolverTypeWrapper<SalaryPayFrequencyNoneError>
  SalaryRetroPay: ResolverTypeWrapper<SalaryRetroPay>
  SalaryRetroPayInput: SalaryRetroPayInput
  SalaryRetroPayResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SalaryRetroPayResponse']
  >
  SaveNewEmployeeResponse: ResolverTypeWrapper<SaveNewEmployeeResponse>
  SeasonalEmploymentStatus: ResolverTypeWrapper<SeasonalEmploymentStatus>
  Section: ResolverTypeWrapper<Section>
  SectionResult: ResolverTypeWrapper<SectionResult>
  SectionsInput: SectionsInput
  SecurityRole: SecurityRole
  SendEmailVerificationError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SendEmailVerificationError']
  >
  SendVerificationEmailInput: SendVerificationEmailInput
  SendVerificationEmailResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SendVerificationEmailResult']
  >
  SortDirection: SortDirection
  SplitDepositType: SplitDepositType
  StateInUnitedStates: ResolverTypeWrapper<StateInUnitedStates>
  Supervisor: ResolverTypeWrapper<Supervisor>
  Supervisors: ResolverTypeWrapper<Supervisors>
  TaskStatus: TaskStatus
  TerminatedEmploymentStatus: ResolverTypeWrapper<TerminatedEmploymentStatus>
  ThirdPartyBenefit: ResolverTypeWrapper<ThirdPartyBenefit>
  ThirdPartyBenefitType: ThirdPartyBenefitType
  TimesheetRetrievalError: ResolverTypeWrapper<TimesheetRetrievalError>
  ToastVersionIgnored: ResolverTypeWrapper<ToastVersionIgnored>
  UnlinkUserResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UnlinkUserResult']
  >
  UpdateEmployeePaymentMethodEmailResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateEmployeePaymentMethodEmailResponse']
  >
  UpdatePayCardAdminSettingsInput: UpdatePayCardAdminSettingsInput
  UpdatePayCardCustomerSettingsInput: UpdatePayCardCustomerSettingsInput
  UpdatePayCardCustomerSettingsResponse: ResolverTypeWrapper<UpdatePayCardCustomerSettingsResponse>
  UpdatePendingPayChangeStatusResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdatePendingPayChangeStatusResponse']
  >
  User: ResolverTypeWrapper<User>
  UserAlreadyLinked: ResolverTypeWrapper<UserAlreadyLinked>
  UserDocument: ResolverTypeWrapper<UserDocument>
  UserDocumentType: UserDocumentType
  UserDocuments: ResolverTypeWrapper<UserDocuments>
  UserEvent: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UserEvent']
  >
  UserLinkChanged: ResolverTypeWrapper<UserLinkChanged>
  UserLinked: ResolverTypeWrapper<UserLinked>
  UserMigrationChooseAnotherUsername: ResolverTypeWrapper<UserMigrationChooseAnotherUsername>
  UserMigrationConfirmAccountVerificationEmailResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UserMigrationConfirmAccountVerificationEmailResult']
  >
  UserMigrationDefaultEmailSource: UserMigrationDefaultEmailSource
  UserMigrationDefaults: ResolverTypeWrapper<UserMigrationDefaults>
  UserMigrationDefaultsResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UserMigrationDefaultsResult']
  >
  UserMigrationEmailConfirmationFailed: ResolverTypeWrapper<
    Omit<UserMigrationEmailConfirmationFailed, 'error'> & {
      error: ResolversTypes['ConfirmVerificationEmailError']
    }
  >
  UserMigrationEmailExistsInCustomer: ResolverTypeWrapper<UserMigrationEmailExistsInCustomer>
  UserMigrationEmailNotVerified: ResolverTypeWrapper<UserMigrationEmailNotVerified>
  UserMigrationEmailVerified: ResolverTypeWrapper<UserMigrationEmailVerified>
  UserMigrationFailed: ResolverTypeWrapper<UserMigrationFailed>
  UserMigrationMigrateResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UserMigrationMigrateResult']
  >
  UserMigrationMutation: ResolverTypeWrapper<
    Omit<
      UserMigrationMutation,
      | 'confirmAccountVerificationEmail'
      | 'migrate'
      | 'sendAccountVerificationEmail'
    > & {
      confirmAccountVerificationEmail: ResolversTypes['UserMigrationConfirmAccountVerificationEmailResult']
      migrate: ResolversTypes['UserMigrationMigrateResult']
      sendAccountVerificationEmail: ResolversTypes['UserMigrationSendAccountVerificationEmailResult']
    }
  >
  UserMigrationNotEnabled: ResolverTypeWrapper<UserMigrationNotEnabled>
  UserMigrationQuery: ResolverTypeWrapper<
    Omit<UserMigrationQuery, 'defaults' | 'verifyAccountEmail'> & {
      defaults: ResolversTypes['UserMigrationDefaultsResult']
      verifyAccountEmail: ResolversTypes['UserMigrationVerifyAccountEmailResult']
    }
  >
  UserMigrationSendAccountVerificationEmailFailed: ResolverTypeWrapper<
    Omit<UserMigrationSendAccountVerificationEmailFailed, 'error'> & {
      error?: Maybe<ResolversTypes['SendEmailVerificationError']>
    }
  >
  UserMigrationSendAccountVerificationEmailResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UserMigrationSendAccountVerificationEmailResult']
  >
  UserMigrationSuccess: ResolverTypeWrapper<UserMigrationSuccess>
  UserMigrationToastAccountType: UserMigrationToastAccountType
  UserMigrationToastUserHasCredentials: ResolverTypeWrapper<UserMigrationToastUserHasCredentials>
  UserMigrationUserAlreadyMigrated: ResolverTypeWrapper<UserMigrationUserAlreadyMigrated>
  UserMigrationUserNotFound: ResolverTypeWrapper<UserMigrationUserNotFound>
  UserMigrationVerificationEmailSent: ResolverTypeWrapper<UserMigrationVerificationEmailSent>
  UserMigrationVerifyAccountEmailResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UserMigrationVerifyAccountEmailResult']
  >
  UserNotFound: ResolverTypeWrapper<UserNotFound>
  UserNotLinked: ResolverTypeWrapper<UserNotLinked>
  UserNotMapped: ResolverTypeWrapper<UserNotMapped>
  UserRoleAdded: ResolverTypeWrapper<UserRoleAdded>
  UserRoleRemoved: ResolverTypeWrapper<UserRoleRemoved>
  UserUnlinked: ResolverTypeWrapper<UserUnlinked>
  UsernameChanged: ResolverTypeWrapper<UsernameChanged>
  UsernameChangedWithConflict: ResolverTypeWrapper<UsernameChangedWithConflict>
  UsernameExists: ResolverTypeWrapper<UsernameExists>
  Users: ResolverTypeWrapper<Users>
  VerificationEmailConfirmed: ResolverTypeWrapper<VerificationEmailConfirmed>
  VerificationEmailNotSent: ResolverTypeWrapper<VerificationEmailNotSent>
  VerificationEmailSent: ResolverTypeWrapper<VerificationEmailSent>
  VeteranStatus: VeteranStatus
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AbsentPayChangeLogTokenError: AbsentPayChangeLogTokenError
  String: Scalars['String']['output']
  AccessibleCustomer: AccessibleCustomer
  AccessibleCustomersInput: AccessibleCustomersInput
  ID: Scalars['ID']['output']
  Int: Scalars['Int']['output']
  AccessibleCustomersResult: AccessibleCustomersResult
  ActiveEmploymentStatus: ActiveEmploymentStatus
  Actor: Actor
  ActorPermissionsForEmployee: ActorPermissionsForEmployee
  AdditionalDocumentInput: AdditionalDocumentInput
  AdditionalEarningResponse: ResolversUnionTypes<ResolversParentTypes>['AdditionalEarningResponse']
  AllEmploymentStatuses: ResolversUnionTypes<ResolversParentTypes>['AllEmploymentStatuses']
  AmountWithPayInterval: AmountWithPayInterval
  ApplicantEmploymentStatus: ApplicantEmploymentStatus
  AvailabilityRequest: AvailabilityRequest
  AvailabilityResponse: AvailabilityResponse
  Boolean: Scalars['Boolean']['output']
  Benefit: Benefit
  BenefitOption: BenefitOption
  Benefits: Benefits
  BenefitsInput: BenefitsInput
  BenefitsThirdPartyDataSharingConsent: BenefitsThirdPartyDataSharingConsent
  Float: Scalars['Float']['output']
  BulkNotifyResponse: BulkNotifyResponse
  CannotChangeLinkedUsername: CannotChangeLinkedUsername
  CannotChangeUnlinkedUsername: CannotChangeUnlinkedUsername
  ChangeLinkedUsernameResult: ResolversUnionTypes<ResolversParentTypes>['ChangeLinkedUsernameResult']
  ChangePayInput: ChangePayInput
  ChangeSalaryAllocationInput: ChangeSalaryAllocationInput
  ChangeSalaryResponse: ResolversUnionTypes<ResolversParentTypes>['ChangeSalaryResponse']
  ChangeUnlinkedUsernameResult: ResolversUnionTypes<ResolversParentTypes>['ChangeUnlinkedUsernameResult']
  ChecklistState: ChecklistState
  CompleteDirectDepositEmployee: CompleteDirectDepositEmployee
  CompleteDirectDepositsResponse: CompleteDirectDepositsResponse
  ConfirmVerificationEmailError: ResolversUnionTypes<ResolversParentTypes>['ConfirmVerificationEmailError']
  ConfirmVerificationEmailInput: ConfirmVerificationEmailInput
  ConfirmVerificationEmailResult: ResolversUnionTypes<ResolversParentTypes>['ConfirmVerificationEmailResult']
  ConfirmationExpired: ConfirmationExpired
  ContactTelephoneNumber: ContactTelephoneNumber
  Country: Country
  CreateJobTagAssignmentInput: CreateJobTagAssignmentInput
  CreateJobTagInput: CreateJobTagInput
  CustomDocumentFieldInput: CustomDocumentFieldInput
  CustomDocumentFields: CustomDocumentFields
  CustomDocumentFieldsV2: CustomDocumentFieldsV2
  Customer: Customer
  CustomerConfiguration: CustomerConfiguration
  CustomerInput: CustomerInput
  CustomerPosition: CustomerPosition
  CustomerSetting: CustomerSetting
  DataExceptionError: DataExceptionError
  Date: Scalars['Date']['output']
  DateRange: DateRange
  DateTime: Scalars['DateTime']['output']
  Decimal: Scalars['Decimal']['output']
  DefaultEarningsId: DefaultEarningsId
  DeletedEmploymentStatus: DeletedEmploymentStatus
  DemoEmploymentStatus: DemoEmploymentStatus
  DirectDepositAllocation: DirectDepositAllocation
  DirectDepositBulkEmailResponse: DirectDepositBulkEmailResponse
  DirectDepositNotification: DirectDepositNotification
  DirectDepositPaginationInput: DirectDepositPaginationInput
  DriversLicense: DriversLicense
  DuplicateRequest: DuplicateRequest
  EarningListEmptyError: EarningListEmptyError
  EeoClassification: EeoClassification
  EeoClassificationInput: EeoClassificationInput
  EmailAlreadyVerified: EmailAlreadyVerified
  EmailHasBeenUpdated: EmailHasBeenUpdated
  EmailInvalidError: EmailInvalidError
  EmailNotFound: EmailNotFound
  EmailSendingError: EmailSendingError
  EmailVerification: EmailVerification
  EmailVerificationError: ResolversUnionTypes<ResolversParentTypes>['EmailVerificationError']
  EmailVerificationFailed: EmailVerificationFailed
  EmailVerificationMutation: Omit<
    EmailVerificationMutation,
    'confirmVerificationEmail' | 'sendVerificationEmail'
  > & {
    confirmVerificationEmail: ResolversParentTypes['ConfirmVerificationEmailResult']
    sendVerificationEmail: ResolversParentTypes['SendVerificationEmailResult']
  }
  EmailVerificationQuery: Omit<EmailVerificationQuery, 'verification'> & {
    verification?: Maybe<ResolversParentTypes['EmailVerificationResult']>
  }
  EmailVerificationResult: ResolversUnionTypes<ResolversParentTypes>['EmailVerificationResult']
  EmergencyContact: EmergencyContact
  Employee: Employee
  EmployeeAtLocation: EmployeeAtLocation
  EmployeeContact: EmployeeContact
  EmployeeDemographics: EmployeeDemographics
  EmployeeDocumentUploadRequest: EmployeeDocumentUploadRequest
  EmployeeIdentification: EmployeeIdentification
  EmployeeJob: EmployeeJob
  EmployeeMappingStatus: EmployeeMappingStatus
  EmployeeNewHireDocument: EmployeeNewHireDocument
  EmployeeNotFoundError: EmployeeNotFoundError
  EmployeePay: EmployeePay
  EmployeePayHistory: EmployeePayHistory
  EmployeePaymentMethodEmailResponse: EmployeePaymentMethodEmailResponse
  EmployeeStatusTypeCount: EmployeeStatusTypeCount
  EmployeeSupervisor: EmployeeSupervisor
  Employment: Omit<Employment, 'employmentStatus'> & {
    employmentStatus: ResolversParentTypes['AllEmploymentStatuses']
  }
  EmploymentDetails: EmploymentDetails
  EmploymentDetailsInput: EmploymentDetailsInput
  EmploymentId: EmploymentId
  EmploymentStatus: ResolversInterfaceTypes<ResolversParentTypes>['EmploymentStatus']
  EmptySalaryAllocationsError: EmptySalaryAllocationsError
  FindUserEventsByUserIdInput: FindUserEventsByUserIdInput
  GenericResponse: GenericResponse
  HourlyEarningInput: HourlyEarningInput
  HourlyRetroPay: HourlyRetroPay
  HourlyRetroPayInput: HourlyRetroPayInput
  HourlyRetroPayResponse: ResolversUnionTypes<ResolversParentTypes>['HourlyRetroPayResponse']
  ImplementationEmploymentStatus: ImplementationEmploymentStatus
  IncompleteDirectDepositEmployee: IncompleteDirectDepositEmployee
  IncompleteDirectDepositInput: IncompleteDirectDepositInput
  IncompleteDirectDepositsResponse: IncompleteDirectDepositsResponse
  InputCannotBeEmpty: InputCannotBeEmpty
  IntercomSessionDataResponse: IntercomSessionDataResponse
  InvalidEffectiveDateError: InvalidEffectiveDateError
  InvalidPayChangeLogTokenError: InvalidPayChangeLogTokenError
  InvalidStartDateError: InvalidStartDateError
  InvalidUsername: InvalidUsername
  Item: Item
  Job: Job
  JobAssignment: JobAssignment
  JobAssignmentNotFoundError: JobAssignmentNotFoundError
  JobInput: JobInput
  JobMapping: JobMapping
  JobTag: JobTag
  JobTagAssignment: JobTagAssignment
  KeyDoesNotMatch: KeyDoesNotMatch
  LeaveOfAbsenceEmploymentStatus: LeaveOfAbsenceEmploymentStatus
  LevelId: LevelId
  LevelIdInput: LevelIdInput
  LinkIfMappedResult: ResolversUnionTypes<ResolversParentTypes>['LinkIfMappedResult']
  LinkUserResult: ResolversUnionTypes<ResolversParentTypes>['LinkUserResult']
  LinkingNotAvailable: LinkingNotAvailable
  LocalizableMessage: LocalizableMessage
  Location: Location
  LocationInput: LocationInput
  LocationMappingStatus: LocationMappingStatus
  Long: Scalars['Long']['output']
  LumpSumEarningInput: LumpSumEarningInput
  MappedUserMismatchedUsername: MappedUserMismatchedUsername
  MappingAssignment: MappingAssignment
  MappingIssue: MappingIssue
  MissingPayGroupError: MissingPayGroupError
  Money: Money
  MoneyInput: MoneyInput
  Mutation: {}
  NavigableTaxAccount: NavigableTaxAccount
  NavigableTaxAccountResult: NavigableTaxAccountResult
  NavigableTaxAccountsInput: NavigableTaxAccountsInput
  Navigation: Navigation
  NavigationError: NavigationError
  NavigationErrors: NavigationErrors
  NavigationInput: NavigationInput
  NavigationParam: NavigationParam
  NavigationResult: NavigationResult
  NeverEmployedEmploymentStatus: NeverEmployedEmploymentStatus
  NewEmployeeInput: NewEmployeeInput
  NewHireDocument: NewHireDocument
  NewJobAssignment: NewJobAssignment
  NewJobAssignmentInput: NewJobAssignmentInput
  NewJobsAndPay: NewJobsAndPay
  NewJobsAndPayInput: NewJobsAndPayInput
  NextPayPeriodNotFoundError: NextPayPeriodNotFoundError
  NextPaycheckDateNotFoundError: NextPaycheckDateNotFoundError
  NotAToastUser: NotAToastUser
  PayCardAdminSettings: PayCardAdminSettings
  PayCardBusinessAccess: PayCardBusinessAccess
  PayCardCustomerAccess: PayCardCustomerAccess
  PayCardCustomerAccessInput: PayCardCustomerAccessInput
  PayCardCustomerSettings: PayCardCustomerSettings
  PayCardCustomerSettingsAuditEntry: PayCardCustomerSettingsAuditEntry
  PayCardCustomerSettingsAuditHistoryChangeContent: PayCardCustomerSettingsAuditHistoryChangeContent
  PayCardCustomerSettingsAuditUser: PayCardCustomerSettingsAuditUser
  PayCardEmployeeAccess: PayCardEmployeeAccess
  PayCardEmployeeAccessFactors: PayCardEmployeeAccessFactors
  PayCardEmployeeAccessInput: PayCardEmployeeAccessInput
  PayCardRestaurantAccess: PayCardRestaurantAccess
  PayChangeEvent: PayChangeEvent
  PayChangeLog: PayChangeLog
  PayChangeLogResponse: ResolversUnionTypes<ResolversParentTypes>['PayChangeLogResponse']
  PayChangeLogSortInput: PayChangeLogSortInput
  PayGroup: PayGroup
  PayGroupAssignment: PayGroupAssignment
  PayGroupAssignmentInput: PayGroupAssignmentInput
  PayGroupWithFrequency: PayGroupWithFrequency
  Paycheck: Paycheck
  PayrollCheckCode: PayrollCheckCode
  PayrollCustomerUser: PayrollCustomerUser
  PayrollCustomerUsersInput: PayrollCustomerUsersInput
  PayrollEmployeeMapping: PayrollEmployeeMapping
  PayrollFein: PayrollFein
  PayrollJob: PayrollJob
  PayrollLocation: PayrollLocation
  PayrollSecurityRole: PayrollSecurityRole
  PayrollUser: PayrollUser
  PayrollUserMutations: Omit<
    PayrollUserMutations,
    | 'changeLinkedUsername'
    | 'changeUnlinkedUsername'
    | 'link'
    | 'linkIfMapped'
    | 'unlink'
  > & {
    changeLinkedUsername: Array<
      ResolversParentTypes['ChangeLinkedUsernameResult']
    >
    changeUnlinkedUsername: ResolversParentTypes['ChangeUnlinkedUsernameResult']
    link: ResolversParentTypes['LinkUserResult']
    linkIfMapped: Array<ResolversParentTypes['LinkIfMappedResult']>
    unlink: ResolversParentTypes['UnlinkUserResult']
  }
  PayrollWorkTaxLocation: PayrollWorkTaxLocation
  PayrollWorkTaxLocationAddress: PayrollWorkTaxLocationAddress
  PendingEmploymentStatus: PendingEmploymentStatus
  PendingPayChange: PendingPayChange
  PendingPayChangeNotFoundError: PendingPayChangeNotFoundError
  PendingPayChangeNotFoundErrors: PendingPayChangeNotFoundErrors
  PendingPayChangeStatusInput: PendingPayChangeStatusInput
  PendingPayChangeStatusResponse: PendingPayChangeStatusResponse
  Person: Person
  PersonInput: PersonInput
  PersonName: PersonName
  PosAvailabilityRequest: PosAvailabilityRequest
  PosEmployee: PosEmployee
  PosRawEmployee: PosRawEmployee
  PosUser: PosUser
  PostalAddress: PostalAddress
  Query: {}
  RehireEmployeeNoChangesInput: RehireEmployeeNoChangesInput
  RehiredEmploymentStatus: RehiredEmploymentStatus
  RequiredDocumentsRequest: RequiredDocumentsRequest
  Response: Response
  Restaurant: Restaurant
  RestaurantJob: RestaurantJob
  RestaurantUser: RestaurantUser
  Salary: Salary
  SalaryAllocation: SalaryAllocation
  SalaryAllocationNotFoundError: SalaryAllocationNotFoundError
  SalaryNotFoundError: SalaryNotFoundError
  SalaryPayFrequencyDoesNotExistError: SalaryPayFrequencyDoesNotExistError
  SalaryPayFrequencyNoneError: SalaryPayFrequencyNoneError
  SalaryRetroPay: SalaryRetroPay
  SalaryRetroPayInput: SalaryRetroPayInput
  SalaryRetroPayResponse: ResolversUnionTypes<ResolversParentTypes>['SalaryRetroPayResponse']
  SaveNewEmployeeResponse: SaveNewEmployeeResponse
  SeasonalEmploymentStatus: SeasonalEmploymentStatus
  Section: Section
  SectionResult: SectionResult
  SectionsInput: SectionsInput
  SendEmailVerificationError: ResolversUnionTypes<ResolversParentTypes>['SendEmailVerificationError']
  SendVerificationEmailInput: SendVerificationEmailInput
  SendVerificationEmailResult: ResolversUnionTypes<ResolversParentTypes>['SendVerificationEmailResult']
  StateInUnitedStates: StateInUnitedStates
  Supervisor: Supervisor
  Supervisors: Supervisors
  TerminatedEmploymentStatus: TerminatedEmploymentStatus
  ThirdPartyBenefit: ThirdPartyBenefit
  TimesheetRetrievalError: TimesheetRetrievalError
  ToastVersionIgnored: ToastVersionIgnored
  UnlinkUserResult: ResolversUnionTypes<ResolversParentTypes>['UnlinkUserResult']
  UpdateEmployeePaymentMethodEmailResponse: ResolversUnionTypes<ResolversParentTypes>['UpdateEmployeePaymentMethodEmailResponse']
  UpdatePayCardAdminSettingsInput: UpdatePayCardAdminSettingsInput
  UpdatePayCardCustomerSettingsInput: UpdatePayCardCustomerSettingsInput
  UpdatePayCardCustomerSettingsResponse: UpdatePayCardCustomerSettingsResponse
  UpdatePendingPayChangeStatusResponse: ResolversUnionTypes<ResolversParentTypes>['UpdatePendingPayChangeStatusResponse']
  User: User
  UserAlreadyLinked: UserAlreadyLinked
  UserDocument: UserDocument
  UserDocuments: UserDocuments
  UserEvent: ResolversUnionTypes<ResolversParentTypes>['UserEvent']
  UserLinkChanged: UserLinkChanged
  UserLinked: UserLinked
  UserMigrationChooseAnotherUsername: UserMigrationChooseAnotherUsername
  UserMigrationConfirmAccountVerificationEmailResult: ResolversUnionTypes<ResolversParentTypes>['UserMigrationConfirmAccountVerificationEmailResult']
  UserMigrationDefaults: UserMigrationDefaults
  UserMigrationDefaultsResult: ResolversUnionTypes<ResolversParentTypes>['UserMigrationDefaultsResult']
  UserMigrationEmailConfirmationFailed: Omit<
    UserMigrationEmailConfirmationFailed,
    'error'
  > & { error: ResolversParentTypes['ConfirmVerificationEmailError'] }
  UserMigrationEmailExistsInCustomer: UserMigrationEmailExistsInCustomer
  UserMigrationEmailNotVerified: UserMigrationEmailNotVerified
  UserMigrationEmailVerified: UserMigrationEmailVerified
  UserMigrationFailed: UserMigrationFailed
  UserMigrationMigrateResult: ResolversUnionTypes<ResolversParentTypes>['UserMigrationMigrateResult']
  UserMigrationMutation: Omit<
    UserMigrationMutation,
    | 'confirmAccountVerificationEmail'
    | 'migrate'
    | 'sendAccountVerificationEmail'
  > & {
    confirmAccountVerificationEmail: ResolversParentTypes['UserMigrationConfirmAccountVerificationEmailResult']
    migrate: ResolversParentTypes['UserMigrationMigrateResult']
    sendAccountVerificationEmail: ResolversParentTypes['UserMigrationSendAccountVerificationEmailResult']
  }
  UserMigrationNotEnabled: UserMigrationNotEnabled
  UserMigrationQuery: Omit<
    UserMigrationQuery,
    'defaults' | 'verifyAccountEmail'
  > & {
    defaults: ResolversParentTypes['UserMigrationDefaultsResult']
    verifyAccountEmail: ResolversParentTypes['UserMigrationVerifyAccountEmailResult']
  }
  UserMigrationSendAccountVerificationEmailFailed: Omit<
    UserMigrationSendAccountVerificationEmailFailed,
    'error'
  > & { error?: Maybe<ResolversParentTypes['SendEmailVerificationError']> }
  UserMigrationSendAccountVerificationEmailResult: ResolversUnionTypes<ResolversParentTypes>['UserMigrationSendAccountVerificationEmailResult']
  UserMigrationSuccess: UserMigrationSuccess
  UserMigrationToastUserHasCredentials: UserMigrationToastUserHasCredentials
  UserMigrationUserAlreadyMigrated: UserMigrationUserAlreadyMigrated
  UserMigrationUserNotFound: UserMigrationUserNotFound
  UserMigrationVerificationEmailSent: UserMigrationVerificationEmailSent
  UserMigrationVerifyAccountEmailResult: ResolversUnionTypes<ResolversParentTypes>['UserMigrationVerifyAccountEmailResult']
  UserNotFound: UserNotFound
  UserNotLinked: UserNotLinked
  UserNotMapped: UserNotMapped
  UserRoleAdded: UserRoleAdded
  UserRoleRemoved: UserRoleRemoved
  UserUnlinked: UserUnlinked
  UsernameChanged: UsernameChanged
  UsernameChangedWithConflict: UsernameChangedWithConflict
  UsernameExists: UsernameExists
  Users: Users
  VerificationEmailConfirmed: VerificationEmailConfirmed
  VerificationEmailNotSent: VerificationEmailNotSent
  VerificationEmailSent: VerificationEmailSent
}

export type ExtendsDirectiveArgs = {}

export type ExtendsDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ExtendsDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type InaccessibleDirectiveArgs = {}

export type InaccessibleDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = InaccessibleDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type LinkDirectiveArgs = {
  import?: Maybe<Array<Maybe<Scalars['String']['input']>>>
  url: Scalars['String']['input']
}

export type LinkDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = LinkDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type OverrideDirectiveArgs = {
  from: Scalars['String']['input']
}

export type OverrideDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = OverrideDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ShareableDirectiveArgs = {}

export type ShareableDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ShareableDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type AbsentPayChangeLogTokenErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AbsentPayChangeLogTokenError'] = ResolversParentTypes['AbsentPayChangeLogTokenError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AccessibleCustomerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessibleCustomer'] = ResolversParentTypes['AccessibleCustomer']
> = {
  companyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shardName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AccessibleCustomersResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessibleCustomersResult'] = ResolversParentTypes['AccessibleCustomersResult']
> = {
  customers?: Resolver<
    Array<ResolversTypes['AccessibleCustomer']>,
    ParentType,
    ContextType
  >
  nextCursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActiveEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveEmploymentStatus'] = ResolversParentTypes['ActiveEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Actor'] = ResolversParentTypes['Actor']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jobName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['PersonName']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActorPermissionsForEmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActorPermissionsForEmployee'] = ResolversParentTypes['ActorPermissionsForEmployee']
> = {
  employeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  permissions?: Resolver<
    Array<ResolversTypes['HumanResourcesPermission']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalEarningResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalEarningResponse'] = ResolversParentTypes['AdditionalEarningResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'EarningListEmptyError'
    | 'EmployeeNotFoundError'
    | 'JobAssignmentNotFoundError'
    | 'MissingPayGroupError'
    | 'NextPayPeriodNotFoundError'
    | 'NextPaycheckDateNotFoundError'
    | 'Paycheck',
    ParentType,
    ContextType
  >
}

export type AllEmploymentStatusesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllEmploymentStatuses'] = ResolversParentTypes['AllEmploymentStatuses']
> = {
  __resolveType: TypeResolveFn<
    | 'ActiveEmploymentStatus'
    | 'ApplicantEmploymentStatus'
    | 'DeletedEmploymentStatus'
    | 'DemoEmploymentStatus'
    | 'ImplementationEmploymentStatus'
    | 'LeaveOfAbsenceEmploymentStatus'
    | 'NeverEmployedEmploymentStatus'
    | 'PendingEmploymentStatus'
    | 'RehiredEmploymentStatus'
    | 'SeasonalEmploymentStatus'
    | 'TerminatedEmploymentStatus',
    ParentType,
    ContextType
  >
}

export type AmountWithPayIntervalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AmountWithPayInterval'] = ResolversParentTypes['AmountWithPayInterval']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  interval?: Resolver<ResolversTypes['PayInterval'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicantEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicantEmploymentStatus'] = ResolversParentTypes['ApplicantEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AvailabilityResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AvailabilityResponse'] = ResolversParentTypes['AvailabilityResponse']
> = {
  email?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  employeeNumber?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  posAccessCode?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  ssn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BenefitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Benefit'] = ResolversParentTypes['Benefit']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  options?: Resolver<
    Array<ResolversTypes['BenefitOption']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BenefitOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BenefitOption'] = ResolversParentTypes['BenefitOption']
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BenefitsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Benefits'] = ResolversParentTypes['Benefits']
> = {
  acaPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  benefitPlan?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  tafwPolicy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  washingtonLaI?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  workersComp?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BenefitsThirdPartyDataSharingConsentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BenefitsThirdPartyDataSharingConsent'] = ResolversParentTypes['BenefitsThirdPartyDataSharingConsent']
> = {
  version?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BulkNotifyResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BulkNotifyResponse'] = ResolversParentTypes['BulkNotifyResponse']
> = {
  employee?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['DirectDepositNotificationStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CannotChangeLinkedUsernameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CannotChangeLinkedUsername'] = ResolversParentTypes['CannotChangeLinkedUsername']
> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CannotChangeUnlinkedUsernameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CannotChangeUnlinkedUsername'] = ResolversParentTypes['CannotChangeUnlinkedUsername']
> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeLinkedUsernameResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangeLinkedUsernameResult'] = ResolversParentTypes['ChangeLinkedUsernameResult']
> = {
  __resolveType: TypeResolveFn<
    | 'CannotChangeUnlinkedUsername'
    | 'InvalidUsername'
    | 'PayrollUser'
    | 'ToastVersionIgnored'
    | 'UserNotFound'
    | 'UsernameExists',
    ParentType,
    ContextType
  >
}

export type ChangeSalaryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangeSalaryResponse'] = ResolversParentTypes['ChangeSalaryResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'EmployeeNotFoundError'
    | 'EmptySalaryAllocationsError'
    | 'InvalidEffectiveDateError'
    | 'Salary'
    | 'SalaryAllocationNotFoundError'
    | 'SalaryNotFoundError',
    ParentType,
    ContextType
  >
}

export type ChangeUnlinkedUsernameResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangeUnlinkedUsernameResult'] = ResolversParentTypes['ChangeUnlinkedUsernameResult']
> = {
  __resolveType: TypeResolveFn<
    | 'CannotChangeLinkedUsername'
    | 'InvalidUsername'
    | 'PayrollUser'
    | 'UserNotFound'
    | 'UsernameExists',
    ParentType,
    ContextType
  >
}

export type ChecklistStateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChecklistState'] = ResolversParentTypes['ChecklistState']
> = {
  customerUuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['Item']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompleteDirectDepositEmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompleteDirectDepositEmployee'] = ResolversParentTypes['CompleteDirectDepositEmployee']
> = {
  allocations?: Resolver<
    Array<ResolversTypes['DirectDepositAllocation']>,
    ParentType,
    ContextType
  >
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['PersonName'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompleteDirectDepositsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompleteDirectDepositsResponse'] = ResolversParentTypes['CompleteDirectDepositsResponse']
> = {
  employees?: Resolver<
    Array<ResolversTypes['CompleteDirectDepositEmployee']>,
    ParentType,
    ContextType
  >
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfirmVerificationEmailErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfirmVerificationEmailError'] = ResolversParentTypes['ConfirmVerificationEmailError']
> = {
  __resolveType: TypeResolveFn<
    | 'ConfirmationExpired'
    | 'EmailAlreadyVerified'
    | 'EmailHasBeenUpdated'
    | 'EmailVerificationFailed'
    | 'KeyDoesNotMatch'
    | 'VerificationEmailNotSent',
    ParentType,
    ContextType
  >
}

export type ConfirmVerificationEmailResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfirmVerificationEmailResult'] = ResolversParentTypes['ConfirmVerificationEmailResult']
> = {
  __resolveType: TypeResolveFn<
    | 'ConfirmationExpired'
    | 'EmailAlreadyVerified'
    | 'EmailHasBeenUpdated'
    | 'EmailVerificationFailed'
    | 'KeyDoesNotMatch'
    | 'VerificationEmailConfirmed'
    | 'VerificationEmailNotSent',
    ParentType,
    ContextType
  >
}

export type ConfirmationExpiredResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfirmationExpired'] = ResolversParentTypes['ConfirmationExpired']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContactTelephoneNumberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactTelephoneNumber'] = ResolversParentTypes['ContactTelephoneNumber']
> = {
  isMobile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  telephoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CountryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isoCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomDocumentFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomDocumentFields'] = ResolversParentTypes['CustomDocumentFields']
> = {
  documentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  fieldId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomDocumentFieldsV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomDocumentFieldsV2'] = ResolversParentTypes['CustomDocumentFieldsV2']
> = {
  documentIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  fieldId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['Customer']>,
    { __typename: 'Customer' } & (
      | GraphQLRecursivePick<ParentType, { id: true }>
      | GraphQLRecursivePick<ParentType, { id: true }>
    ),
    ContextType
  >
  feins?: Resolver<
    Array<ResolversTypes['PayrollFein']>,
    { __typename: 'Customer' } & (
      | GraphQLRecursivePick<ParentType, { id: true }>
      | GraphQLRecursivePick<ParentType, { id: true }>
    ),
    ContextType
  >

  locations?: Resolver<
    Array<ResolversTypes['Location']>,
    { __typename: 'Customer' } & (
      | GraphQLRecursivePick<ParentType, { id: true }>
      | GraphQLRecursivePick<ParentType, { id: true }>
    ),
    ContextType
  >
  uuid?: Resolver<
    ResolversTypes['ID'],
    { __typename: 'Customer' } & (
      | GraphQLRecursivePick<ParentType, { id: true }>
      | GraphQLRecursivePick<ParentType, { id: true }>
    ),
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerConfiguration'] = ResolversParentTypes['CustomerConfiguration']
> = {
  customerPositions?: Resolver<
    Array<ResolversTypes['CustomerPosition']>,
    ParentType,
    ContextType
  >
  defaultEarningsIds?: Resolver<
    Maybe<ResolversTypes['DefaultEarningsId']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locations?: Resolver<
    Array<ResolversTypes['PayrollLocation']>,
    ParentType,
    ContextType
  >
  payGroups?: Resolver<
    Array<ResolversTypes['PayGroupWithFrequency']>,
    ParentType,
    ContextType
  >
  payGroupsV2?: Resolver<
    Array<ResolversTypes['PayGroup']>,
    ParentType,
    ContextType
  >
  payrollSecurityRoles?: Resolver<
    Array<ResolversTypes['PayrollSecurityRole']>,
    ParentType,
    ContextType
  >
  workTaxLocations?: Resolver<
    Array<ResolversTypes['PayrollWorkTaxLocation']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerPositionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerPosition'] = ResolversParentTypes['CustomerPosition']
> = {
  checkCode?: Resolver<
    ResolversTypes['PayrollCheckCode'],
    ParentType,
    ContextType
  >
  fein?: Resolver<ResolversTypes['PayrollFein'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isExempt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  payGroup?: Resolver<ResolversTypes['PayGroup'], ParentType, ContextType>
  salaryType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerSettingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerSetting'] = ResolversParentTypes['CustomerSetting']
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DataExceptionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataExceptionError'] = ResolversParentTypes['DataExceptionError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date'
}

export type DateRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateRange'] = ResolversParentTypes['DateRange']
> = {
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export interface DecimalScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Decimal'], any> {
  name: 'Decimal'
}

export type DefaultEarningsIdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DefaultEarningsId'] = ResolversParentTypes['DefaultEarningsId']
> = {
  contractorHourly?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  contractorSalary?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  hourly?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  salary?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedEmploymentStatus'] = ResolversParentTypes['DeletedEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DemoEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DemoEmploymentStatus'] = ResolversParentTypes['DemoEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DirectDepositAllocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DirectDepositAllocation'] = ResolversParentTypes['DirectDepositAllocation']
> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  depositAccountType?: Resolver<
    ResolversTypes['DepositAccountType'],
    ParentType,
    ContextType
  >
  sequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  splitDepositType?: Resolver<
    ResolversTypes['SplitDepositType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DirectDepositBulkEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DirectDepositBulkEmailResponse'] = ResolversParentTypes['DirectDepositBulkEmailResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['DirectDepositNotificationStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DirectDepositNotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DirectDepositNotification'] = ResolversParentTypes['DirectDepositNotification']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastContactedUtc?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['DirectDepositNotificationStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DriversLicenseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DriversLicense'] = ResolversParentTypes['DriversLicense']
> = {
  expirationDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<
    ResolversTypes['StateInUnitedStates'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DuplicateRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DuplicateRequest'] = ResolversParentTypes['DuplicateRequest']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EarningListEmptyErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EarningListEmptyError'] = ResolversParentTypes['EarningListEmptyError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EeoClassificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EeoClassification'] = ResolversParentTypes['EeoClassification']
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailAlreadyVerifiedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAlreadyVerified'] = ResolversParentTypes['EmailAlreadyVerified']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailHasBeenUpdatedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailHasBeenUpdated'] = ResolversParentTypes['EmailHasBeenUpdated']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailInvalidErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailInvalidError'] = ResolversParentTypes['EmailInvalidError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailNotFound'] = ResolversParentTypes['EmailNotFound']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailSendingErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailSendingError'] = ResolversParentTypes['EmailSendingError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailVerificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailVerification'] = ResolversParentTypes['EmailVerification']
> = {
  isVerified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  verifiedTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailVerificationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailVerificationError'] = ResolversParentTypes['EmailVerificationError']
> = {
  __resolveType: TypeResolveFn<
    | 'ConfirmationExpired'
    | 'DuplicateRequest'
    | 'EmailAlreadyVerified'
    | 'EmailHasBeenUpdated'
    | 'EmailNotFound'
    | 'EmailVerificationFailed'
    | 'KeyDoesNotMatch'
    | 'VerificationEmailNotSent',
    ParentType,
    ContextType
  >
}

export type EmailVerificationFailedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailVerificationFailed'] = ResolversParentTypes['EmailVerificationFailed']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailVerificationMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailVerificationMutation'] = ResolversParentTypes['EmailVerificationMutation']
> = {
  confirmVerificationEmail?: Resolver<
    ResolversTypes['ConfirmVerificationEmailResult'],
    ParentType,
    ContextType,
    Partial<EmailVerificationMutationConfirmVerificationEmailArgs>
  >
  sendVerificationEmail?: Resolver<
    ResolversTypes['SendVerificationEmailResult'],
    ParentType,
    ContextType,
    Partial<EmailVerificationMutationSendVerificationEmailArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailVerificationQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailVerificationQuery'] = ResolversParentTypes['EmailVerificationQuery']
> = {
  verification?: Resolver<
    Maybe<ResolversTypes['EmailVerificationResult']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailVerificationResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailVerificationResult'] = ResolversParentTypes['EmailVerificationResult']
> = {
  __resolveType: TypeResolveFn<
    | 'ConfirmationExpired'
    | 'DuplicateRequest'
    | 'EmailAlreadyVerified'
    | 'EmailHasBeenUpdated'
    | 'EmailNotFound'
    | 'EmailVerification'
    | 'EmailVerificationFailed'
    | 'KeyDoesNotMatch'
    | 'VerificationEmailNotSent',
    ParentType,
    ContextType
  >
}

export type EmergencyContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmergencyContact'] = ResolversParentTypes['EmergencyContact']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPrimary?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  relation?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  telephoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Employee'] = ResolversParentTypes['Employee']
> = {
  benefits?: Resolver<
    Maybe<ResolversTypes['Benefits']>,
    ParentType,
    ContextType
  >
  companyCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  contact?: Resolver<ResolversTypes['EmployeeContact'], ParentType, ContextType>
  demographics?: Resolver<
    ResolversTypes['EmployeeDemographics'],
    ParentType,
    ContextType
  >
  emergencyContacts?: Resolver<
    Array<ResolversTypes['EmergencyContact']>,
    ParentType,
    ContextType
  >
  employment?: Resolver<
    Maybe<ResolversTypes['Employment']>,
    ParentType,
    ContextType
  >
  hasSsn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  identification?: Resolver<
    ResolversTypes['EmployeeIdentification'],
    ParentType,
    ContextType
  >
  isEmployeeZero?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  jobAssignmentPayChangeErrors?: Resolver<
    Array<ResolversTypes['JobAssignment']>,
    ParentType,
    ContextType
  >
  jobAssignments?: Resolver<
    Array<ResolversTypes['JobAssignment']>,
    ParentType,
    ContextType
  >
  payGroupAssignments?: Resolver<
    Array<ResolversTypes['PayGroupAssignment']>,
    ParentType,
    ContextType
  >
  payHistory?: Resolver<
    ResolversTypes['EmployeePayHistory'],
    ParentType,
    ContextType
  >
  payrollSecurityRole?: Resolver<
    Maybe<ResolversTypes['PayrollSecurityRole']>,
    ParentType,
    ContextType
  >
  salary?: Resolver<Maybe<ResolversTypes['Salary']>, ParentType, ContextType>
  salaryChangeErrors?: Resolver<
    Maybe<ResolversTypes['Salary']>,
    ParentType,
    ContextType
  >
  supervisors?: Resolver<
    Array<ResolversTypes['EmployeeSupervisor']>,
    ParentType,
    ContextType
  >
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  userUuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeAtLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeAtLocation'] = ResolversParentTypes['EmployeeAtLocation']
> = {
  jobs?: Resolver<Array<ResolversTypes['EmployeeJob']>, ParentType, ContextType>
  mappingStatus?: Resolver<
    ResolversTypes['MappingCondition'],
    ParentType,
    ContextType
  >
  payrollLocationId?: Resolver<
    Maybe<ResolversTypes['LevelId']>,
    ParentType,
    ContextType
  >
  payrollLocationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  posEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  posLocationGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  posMostRecentClockInDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  posRestaurantUserGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  posStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  posUserName?: Resolver<
    Maybe<ResolversTypes['PersonName']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeContact'] = ResolversParentTypes['EmployeeContact']
> = {
  homeAddress?: Resolver<
    Maybe<ResolversTypes['PostalAddress']>,
    ParentType,
    ContextType
  >
  mailingAddress?: Resolver<
    Maybe<ResolversTypes['PostalAddress']>,
    ParentType,
    ContextType
  >
  telephoneNumber?: Resolver<
    Maybe<ResolversTypes['ContactTelephoneNumber']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeDemographicsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeDemographics'] = ResolversParentTypes['EmployeeDemographics']
> = {
  dateOfBirth?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  disability?: Resolver<
    Maybe<ResolversTypes['DisabilityStatus']>,
    ParentType,
    ContextType
  >
  ethnicity?: Resolver<
    Maybe<ResolversTypes['Ethnicity']>,
    ParentType,
    ContextType
  >
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>
  maritalStatus?: Resolver<
    Maybe<ResolversTypes['MaritalStatus']>,
    ParentType,
    ContextType
  >
  veteranStatus?: Resolver<
    Array<ResolversTypes['VeteranStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeIdentificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeIdentification'] = ResolversParentTypes['EmployeeIdentification']
> = {
  driversLicense?: Resolver<
    Maybe<ResolversTypes['DriversLicense']>,
    ParentType,
    ContextType
  >
  hasSocialSecurityNumber?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeJob'] = ResolversParentTypes['EmployeeJob']
> = {
  mappingStatus?: Resolver<
    ResolversTypes['MappingCondition'],
    ParentType,
    ContextType
  >
  payrollId?: Resolver<
    Maybe<ResolversTypes['LevelId']>,
    ParentType,
    ContextType
  >
  payrollRateOfPay?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  payrollTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  posGuid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  posRateOfPay?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  posTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeMappingStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeMappingStatus'] = ResolversParentTypes['EmployeeMappingStatus']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  issues?: Resolver<
    Array<ResolversTypes['MappingIssue']>,
    ParentType,
    ContextType
  >
  locations?: Resolver<
    Array<ResolversTypes['EmployeeAtLocation']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['PersonName'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['EmploymentStatusType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeNewHireDocumentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeNewHireDocument'] = ResolversParentTypes['EmployeeNewHireDocument']
> = {
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  filePath?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  uploadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeNotFoundError'] = ResolversParentTypes['EmployeeNotFoundError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeePayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeePay'] = ResolversParentTypes['EmployeePay']
> = {
  mostRecentPaycheck?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeePayHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeePayHistory'] = ResolversParentTypes['EmployeePayHistory']
> = {
  mostRecentPaycheck?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeePaymentMethodEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeePaymentMethodEmailResponse'] = ResolversParentTypes['EmployeePaymentMethodEmailResponse']
> = {
  messageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeStatusTypeCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeStatusTypeCount'] = ResolversParentTypes['EmployeeStatusTypeCount']
> = {
  active?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  applicant?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  deleted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  demo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dormant?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  implementation?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  leaveOfAbsence?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  neverEmployed?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  notSpecified?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  pendingActive?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  terminated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSupervisorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSupervisor'] = ResolversParentTypes['EmployeeSupervisor']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  job?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmploymentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Employment'] = ResolversParentTypes['Employment']
> = {
  eeoClassification?: Resolver<
    ResolversTypes['EeoClassification'],
    ParentType,
    ContextType
  >
  employeeNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  employmentStatus?: Resolver<
    ResolversTypes['AllEmploymentStatuses'],
    ParentType,
    ContextType
  >
  employmentType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  overtimeEligible?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  totalLengthOfService?: Resolver<
    Maybe<ResolversTypes['DateRange']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmploymentDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmploymentDetails'] = ResolversParentTypes['EmploymentDetails']
> = {
  employeeNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employmentTaxType?: Resolver<
    ResolversTypes['EmploymentTaxType'],
    ParentType,
    ContextType
  >
  hireDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmploymentIdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmploymentId'] = ResolversParentTypes['EmploymentId']
> = {
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  employeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmploymentStatus'] = ResolversParentTypes['EmploymentStatus']
> = {
  __resolveType: TypeResolveFn<
    | 'ActiveEmploymentStatus'
    | 'ApplicantEmploymentStatus'
    | 'DeletedEmploymentStatus'
    | 'DemoEmploymentStatus'
    | 'ImplementationEmploymentStatus'
    | 'LeaveOfAbsenceEmploymentStatus'
    | 'NeverEmployedEmploymentStatus'
    | 'PendingEmploymentStatus'
    | 'RehiredEmploymentStatus'
    | 'SeasonalEmploymentStatus'
    | 'TerminatedEmploymentStatus',
    ParentType,
    ContextType
  >
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type EmptySalaryAllocationsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmptySalaryAllocationsError'] = ResolversParentTypes['EmptySalaryAllocationsError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GenericResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenericResponse'] = ResolversParentTypes['GenericResponse']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HourlyRetroPayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HourlyRetroPay'] = ResolversParentTypes['HourlyRetroPay']
> = {
  hoursWorked?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  payChangeDifference?: Resolver<
    ResolversTypes['AmountWithPayInterval'],
    ParentType,
    ContextType
  >
  retroPay?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HourlyRetroPayResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HourlyRetroPayResponse'] = ResolversParentTypes['HourlyRetroPayResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'HourlyRetroPay'
    | 'InvalidStartDateError'
    | 'JobAssignmentNotFoundError'
    | 'TimesheetRetrievalError',
    ParentType,
    ContextType
  >
}

export type ImplementationEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImplementationEmploymentStatus'] = ResolversParentTypes['ImplementationEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IncompleteDirectDepositEmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IncompleteDirectDepositEmployee'] = ResolversParentTypes['IncompleteDirectDepositEmployee']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['PersonName'], ParentType, ContextType>
  notification?: Resolver<
    Maybe<ResolversTypes['DirectDepositNotification']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IncompleteDirectDepositsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IncompleteDirectDepositsResponse'] = ResolversParentTypes['IncompleteDirectDepositsResponse']
> = {
  employees?: Resolver<
    Array<ResolversTypes['IncompleteDirectDepositEmployee']>,
    ParentType,
    ContextType
  >
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InputCannotBeEmptyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InputCannotBeEmpty'] = ResolversParentTypes['InputCannotBeEmpty']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IntercomSessionDataResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntercomSessionDataResponse'] = ResolversParentTypes['IntercomSessionDataResponse']
> = {
  intercomUserHash?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidEffectiveDateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidEffectiveDateError'] = ResolversParentTypes['InvalidEffectiveDateError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidPayChangeLogTokenErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidPayChangeLogTokenError'] = ResolversParentTypes['InvalidPayChangeLogTokenError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidStartDateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidStartDateError'] = ResolversParentTypes['InvalidStartDateError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidUsernameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidUsername'] = ResolversParentTypes['InvalidUsername']
> = {
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Item'] = ResolversParentTypes['Item']
> = {
  assignedTo?: Resolver<ResolversTypes['Assignee'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['TaskStatus'], ParentType, ContextType>
  subItems?: Resolver<Array<ResolversTypes['Item']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job']
> = {
  exportId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobAssignmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAssignment'] = ResolversParentTypes['JobAssignment']
> = {
  activeHourlyRateOfPay?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  employmentTaxType?: Resolver<
    Maybe<ResolversTypes['EmploymentTaxType']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  jobId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  payGroupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  pendingPayChange?: Resolver<
    Maybe<ResolversTypes['PendingPayChange']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobAssignmentNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAssignmentNotFoundError'] = ResolversParentTypes['JobAssignmentNotFoundError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobMappingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobMapping'] = ResolversParentTypes['JobMapping']
> = {
  payrollJob?: Resolver<ResolversTypes['PayrollJob'], ParentType, ContextType>
  restaurantJob?: Resolver<
    ResolversTypes['RestaurantJob'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobTag'] = ResolversParentTypes['JobTag']
> = {
  createdByUserGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  modifiedByUserGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  modifiedDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobTagAssignmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobTagAssignment'] = ResolversParentTypes['JobTagAssignment']
> = {
  createdByUserGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jobTagId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jobTagName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  managementSetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  modifiedByUserGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  modifiedDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  restaurantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  restaurantJobId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  selectedJobTagValue?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KeyDoesNotMatchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeyDoesNotMatch'] = ResolversParentTypes['KeyDoesNotMatch']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LeaveOfAbsenceEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaveOfAbsenceEmploymentStatus'] = ResolversParentTypes['LeaveOfAbsenceEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  leaveType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  scheduledLeave?: Resolver<
    Maybe<ResolversTypes['DateRange']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LevelIdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LevelId'] = ResolversParentTypes['LevelId']
> = {
  databaseId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sequenceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LinkIfMappedResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkIfMappedResult'] = ResolversParentTypes['LinkIfMappedResult']
> = {
  __resolveType: TypeResolveFn<
    | 'LinkingNotAvailable'
    | 'MappedUserMismatchedUsername'
    | 'PayrollUser'
    | 'UserAlreadyLinked'
    | 'UserNotMapped',
    ParentType,
    ContextType
  >
}

export type LinkUserResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkUserResult'] = ResolversParentTypes['LinkUserResult']
> = {
  __resolveType: TypeResolveFn<
    'PayrollUser' | 'UserNotFound',
    ParentType,
    ContextType
  >
}

export type LinkingNotAvailableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkingNotAvailable'] = ResolversParentTypes['LinkingNotAvailable']
> = {
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocalizableMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocalizableMessage'] = ResolversParentTypes['LocalizableMessage']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['LocalizableMessage']>,
    { __typename: 'LocalizableMessage' } & GraphQLRecursivePick<
      ParentType,
      { key: true }
    >,
    ContextType
  >
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['LocationMappingStatus'],
    ParentType,
    ContextType
  >
  uuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocationMappingStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationMappingStatus'] = ResolversParentTypes['LocationMappingStatus']
> = {
  code?: Resolver<
    ResolversTypes['LocationMappingStatusCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface LongScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long'
}

export type MappedUserMismatchedUsernameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MappedUserMismatchedUsername'] = ResolversParentTypes['MappedUserMismatchedUsername']
> = {
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MappingIssueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MappingIssue'] = ResolversParentTypes['MappingIssue']
> = {
  severity?: Resolver<ResolversTypes['IssueSeverity'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['IssueType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MissingPayGroupErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MissingPayGroupError'] = ResolversParentTypes['MissingPayGroupError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MoneyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']
> = {
  amount?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  applyMapping?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationApplyMappingArgs, 'locationId' | 'payrollEmployeeId'>
  >
  bulkNotify?: Resolver<
    Array<ResolversTypes['BulkNotifyResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationBulkNotifyArgs, 'employees'>
  >
  changeHelloWorldResponse?: Resolver<
    Maybe<ResolversTypes['Response']>,
    ParentType,
    ContextType,
    RequireFields<MutationChangeHelloWorldResponseArgs, 'response'>
  >
  changeHourlyPay?: Resolver<
    Maybe<ResolversTypes['JobAssignment']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationChangeHourlyPayArgs,
      'changePayInput' | 'employeeId' | 'jobAssignmentId'
    >
  >
  changeSalaryAllocations?: Resolver<
    ResolversTypes['ChangeSalaryResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationChangeSalaryAllocationsArgs,
      'changeSalaryAllocations' | 'employeeId'
    >
  >
  contactOnboarding?: Resolver<
    Maybe<ResolversTypes['GenericResponse']>,
    ParentType,
    ContextType,
    Partial<MutationContactOnboardingArgs>
  >
  createJobTag?: Resolver<
    Maybe<ResolversTypes['JobTag']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateJobTagArgs, 'jobTagInput'>
  >
  createJobTagAssignment?: Resolver<
    Maybe<ResolversTypes['JobTagAssignment']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateJobTagAssignmentArgs, 'jobTagAssignmentInput'>
  >
  deletePendingPayChange?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeletePendingPayChangeArgs,
      'employeeId' | 'pendingPayChangeId'
    >
  >
  directDepositBulkEmail?: Resolver<
    Array<ResolversTypes['DirectDepositBulkEmailResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationDirectDepositBulkEmailArgs, 'employees'>
  >
  emailVerification?: Resolver<
    Maybe<ResolversTypes['EmailVerificationMutation']>,
    ParentType,
    ContextType
  >
  ignorePosUserInPayroll?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationIgnorePosUserInPayrollArgs, 'userGuid'>
  >
  payrollUser?: Resolver<
    Maybe<ResolversTypes['PayrollUserMutations']>,
    ParentType,
    ContextType
  >
  rehireEmployeeNoChanges?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<MutationRehireEmployeeNoChangesArgs, 'request'>
  >
  rehireEmployeeWithChanges?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<
      MutationRehireEmployeeWithChangesArgs,
      'employeeId' | 'rehireDate' | 'request'
    >
  >
  resyncTasks?: Resolver<
    ResolversTypes['ChecklistState'],
    ParentType,
    ContextType
  >
  saveAdditionalEarnings?: Resolver<
    Maybe<ResolversTypes['AdditionalEarningResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSaveAdditionalEarningsArgs,
      'employeeId' | 'hourlyEarnings' | 'jobAssignmentId' | 'lumpSumEarnings'
    >
  >
  saveNewEmployee?: Resolver<
    Maybe<ResolversTypes['SaveNewEmployeeResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveNewEmployeeArgs, 'request'>
  >
  sendJobMappingRequest?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationSendJobMappingRequestArgs, 'employeeUuid'>
  >
  sendUpdateEmployeePaymentMethodEmail?: Resolver<
    Maybe<ResolversTypes['UpdateEmployeePaymentMethodEmailResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSendUpdateEmployeePaymentMethodEmailArgs,
      'employeeId'
    >
  >
  setHasPreviousPayrollProvider?: Resolver<
    ResolversTypes['GenericResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationSetHasPreviousPayrollProviderArgs,
      'hasPreviousPayrollProvider'
    >
  >
  updateBenefitsConsent?: Resolver<
    ResolversTypes['BenefitsThirdPartyDataSharingConsent'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBenefitsConsentArgs, 'version'>
  >
  updateCustomer?: Resolver<
    ResolversTypes['Customer'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCustomerArgs, 'customerInput'>
  >
  updateJobMappings?: Resolver<
    Array<ResolversTypes['PayrollJob']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateJobMappingsArgs, 'jobs' | 'locationId'>
  >
  updatePayCardCustomerSettings?: Resolver<
    ResolversTypes['UpdatePayCardCustomerSettingsResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePayCardCustomerSettingsArgs, 'input'>
  >
  updatePendingPayChangeStatuses?: Resolver<
    Maybe<ResolversTypes['UpdatePendingPayChangeStatusResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdatePendingPayChangeStatusesArgs,
      'employeeId' | 'updatePendingPayChangeStatusInput'
    >
  >
  updateTask?: Resolver<
    ResolversTypes['ChecklistState'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskArgs, 'status' | 'taskKey'>
  >
  userMigration?: Resolver<
    Maybe<ResolversTypes['UserMigrationMutation']>,
    ParentType,
    ContextType
  >
}

export type NavigableTaxAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigableTaxAccount'] = ResolversParentTypes['NavigableTaxAccount']
> = {
  companyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  legalName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shardName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tin?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigableTaxAccountResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigableTaxAccountResult'] = ResolversParentTypes['NavigableTaxAccountResult']
> = {
  nextPageNumber?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  taxAccounts?: Resolver<
    Array<ResolversTypes['NavigableTaxAccount']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Navigation'] = ResolversParentTypes['Navigation']
> = {
  children?: Resolver<
    Array<ResolversTypes['Navigation']>,
    ParentType,
    ContextType
  >
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  labelNew?: Resolver<
    ResolversTypes['LocalizableMessage'],
    ParentType,
    ContextType
  >
  matchUrls?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  pageType?: Resolver<
    Maybe<ResolversTypes['PageType']>,
    ParentType,
    ContextType
  >
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationError'] = ResolversParentTypes['NavigationError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationErrorsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationErrors'] = ResolversParentTypes['NavigationErrors']
> = {
  errors?: Resolver<
    Array<ResolversTypes['NavigationError']>,
    ParentType,
    ContextType
  >
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationResult'] = ResolversParentTypes['NavigationResult']
> = {
  errors?: Resolver<
    Array<ResolversTypes['NavigationErrors']>,
    ParentType,
    ContextType
  >
  result?: Resolver<
    Maybe<ResolversTypes['Navigation']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NeverEmployedEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NeverEmployedEmploymentStatus'] = ResolversParentTypes['NeverEmployedEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NewHireDocumentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewHireDocument'] = ResolversParentTypes['NewHireDocument']
> = {
  assignedByPosition?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NewJobAssignmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewJobAssignment'] = ResolversParentTypes['NewJobAssignment']
> = {
  hourlyRate?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  jobId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NewJobsAndPayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewJobsAndPay'] = ResolversParentTypes['NewJobsAndPay']
> = {
  annualSalary?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  jobs?: Resolver<
    Array<ResolversTypes['NewJobAssignment']>,
    ParentType,
    ContextType
  >
  payGroupAssignments?: Resolver<
    Array<ResolversTypes['PayGroupAssignment']>,
    ParentType,
    ContextType
  >
  salaryPerPayPeriod?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  salaryRateOfPay?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NextPayPeriodNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NextPayPeriodNotFoundError'] = ResolversParentTypes['NextPayPeriodNotFoundError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NextPaycheckDateNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NextPaycheckDateNotFoundError'] = ResolversParentTypes['NextPaycheckDateNotFoundError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotAToastUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotAToastUser'] = ResolversParentTypes['NotAToastUser']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardAdminSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardAdminSettings'] = ResolversParentTypes['PayCardAdminSettings']
> = {
  payCardAdminPayoutsLocked?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardBusinessAccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardBusinessAccess'] = ResolversParentTypes['PayCardBusinessAccess']
> = {
  businessId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  existsInPayroll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  fein?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isContractComplete?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  restaurants?: Resolver<
    Array<ResolversTypes['PayCardRestaurantAccess']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardCustomerAccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardCustomerAccess'] = ResolversParentTypes['PayCardCustomerAccess']
> = {
  businesses?: Resolver<
    Array<ResolversTypes['PayCardBusinessAccess']>,
    ParentType,
    ContextType
  >
  customerUuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  detachedRestaurants?: Resolver<
    Array<ResolversTypes['PayCardRestaurantAccess']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardCustomerSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardCustomerSettings'] = ResolversParentTypes['PayCardCustomerSettings']
> = {
  payCardAdminSettings?: Resolver<
    Maybe<ResolversTypes['PayCardAdminSettings']>,
    ParentType,
    ContextType
  >
  payCardPayoutConfiguration?: Resolver<
    Maybe<ResolversTypes['PayCardPayoutConfiguration']>,
    ParentType,
    ContextType
  >
  payCardPayoutEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  payCardTipsConfiguration?: Resolver<
    Maybe<ResolversTypes['PayCardTipsConfiguration']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardCustomerSettingsAuditEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardCustomerSettingsAuditEntry'] = ResolversParentTypes['PayCardCustomerSettingsAuditEntry']
> = {
  change?: Resolver<
    ResolversTypes['PayCardCustomerSettingsAuditHistoryChangeContent'],
    ParentType,
    ContextType
  >
  datetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  user?: Resolver<
    ResolversTypes['PayCardCustomerSettingsAuditUser'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardCustomerSettingsAuditHistoryChangeContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardCustomerSettingsAuditHistoryChangeContent'] = ResolversParentTypes['PayCardCustomerSettingsAuditHistoryChangeContent']
> = {
  messageKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardCustomerSettingsAuditUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardCustomerSettingsAuditUser'] = ResolversParentTypes['PayCardCustomerSettingsAuditUser']
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardEmployeeAccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardEmployeeAccess'] = ResolversParentTypes['PayCardEmployeeAccess']
> = {
  accessFactors?: Resolver<
    ResolversTypes['PayCardEmployeeAccessFactors'],
    ParentType,
    ContextType
  >
  accessLevel?: Resolver<
    ResolversTypes['PayCardEmployeeAccessLevel'],
    ParentType,
    ContextType
  >
  employeeUuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardEmployeeAccessFactorsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardEmployeeAccessFactors'] = ResolversParentTypes['PayCardEmployeeAccessFactors']
> = {
  cardActivated?: Resolver<
    Maybe<ResolversTypes['PayCardBooleanAccessFactor']>,
    ParentType,
    ContextType
  >
  highestRestaurantAccessLevel?: Resolver<
    Maybe<ResolversTypes['PayCardRestaurantAccessLevel']>,
    ParentType,
    ContextType
  >
  isEmployed?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  isLocEnabled?: Resolver<
    Maybe<ResolversTypes['PayCardBooleanAccessFactor']>,
    ParentType,
    ContextType
  >
  isWorkTaxLocationStateEligible?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayCardRestaurantAccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayCardRestaurantAccess'] = ResolversParentTypes['PayCardRestaurantAccess']
> = {
  accessFactors?: Resolver<
    Array<ResolversTypes['PayCardEmployerAccessFactors']>,
    ParentType,
    ContextType
  >
  accessLevel?: Resolver<
    ResolversTypes['PayCardEmployerAccessLevel'],
    ParentType,
    ContextType
  >
  restaurantGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayChangeEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayChangeEvent'] = ResolversParentTypes['PayChangeEvent']
> = {
  actor?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>
  createdAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  currentRate?: Resolver<
    ResolversTypes['AmountWithPayInterval'],
    ParentType,
    ContextType
  >
  effectiveAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jobName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  locationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  previousRate?: Resolver<
    Maybe<ResolversTypes['AmountWithPayInterval']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['PayChangeEventStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayChangeLogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayChangeLog'] = ResolversParentTypes['PayChangeLog']
> = {
  currentPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  data?: Resolver<
    Maybe<Array<ResolversTypes['PayChangeEvent']>>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  previousPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  totalResultCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayChangeLogResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayChangeLogResponse'] = ResolversParentTypes['PayChangeLogResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'AbsentPayChangeLogTokenError'
    | 'InvalidPayChangeLogTokenError'
    | 'PayChangeLog',
    ParentType,
    ContextType
  >
}

export type PayGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayGroup'] = ResolversParentTypes['PayGroup']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayGroupAssignmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayGroupAssignment'] = ResolversParentTypes['PayGroupAssignment']
> = {
  checkCodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  payGroupId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  positionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  workTaxLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayGroupWithFrequencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayGroupWithFrequency'] = ResolversParentTypes['PayGroupWithFrequency']
> = {
  checkCodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  payGroupId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  periodsPerYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaycheckResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Paycheck'] = ResolversParentTypes['Paycheck']
> = {
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollCheckCodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollCheckCode'] = ResolversParentTypes['PayrollCheckCode']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  periodsPerYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollCustomerUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollCustomerUser'] = ResolversParentTypes['PayrollCustomerUser']
> = {
  companyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  customerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shardName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toastIdentityGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollEmployeeMappingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollEmployeeMapping'] = ResolversParentTypes['PayrollEmployeeMapping']
> = {
  name?: Resolver<ResolversTypes['PersonName'], ParentType, ContextType>
  payrollEmployeeNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollFeinResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollFein'] = ResolversParentTypes['PayrollFein']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['PayrollFein']>,
    { __typename: 'PayrollFein' } & (
      | GraphQLRecursivePick<ParentType, { id: true }>
      | GraphQLRecursivePick<ParentType, { id: true }>
    ),
    ContextType
  >
  benefits?: Resolver<
    Array<ResolversTypes['ThirdPartyBenefit']>,
    { __typename: 'PayrollFein' } & (
      | GraphQLRecursivePick<ParentType, { id: true }>
      | GraphQLRecursivePick<ParentType, { id: true }>
    ),
    ContextType
  >
  fein?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'PayrollFein' } & (
      | GraphQLRecursivePick<ParentType, { id: true }>
      | GraphQLRecursivePick<ParentType, { id: true }>
    ),
    ContextType
  >

  name?: Resolver<
    ResolversTypes['String'],
    { __typename: 'PayrollFein' } & (
      | GraphQLRecursivePick<ParentType, { id: true }>
      | GraphQLRecursivePick<ParentType, { id: true }>
    ),
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollJob'] = ResolversParentTypes['PayrollJob']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  uuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollLocation'] = ResolversParentTypes['PayrollLocation']
> = {
  exportId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jobs?: Resolver<Array<ResolversTypes['Job']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollSecurityRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollSecurityRole'] = ResolversParentTypes['PayrollSecurityRole']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollUser'] = ResolversParentTypes['PayrollUser']
> = {
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  employeeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  toastIdentityGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollUserMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollUserMutations'] = ResolversParentTypes['PayrollUserMutations']
> = {
  changeLinkedUsername?: Resolver<
    Array<ResolversTypes['ChangeLinkedUsernameResult']>,
    ParentType,
    ContextType,
    RequireFields<
      PayrollUserMutationsChangeLinkedUsernameArgs,
      'toastIdentityGuid' | 'username'
    >
  >
  changeUnlinkedUsername?: Resolver<
    ResolversTypes['ChangeUnlinkedUsernameResult'],
    ParentType,
    ContextType,
    RequireFields<
      PayrollUserMutationsChangeUnlinkedUsernameArgs,
      'customerId' | 'userId' | 'username'
    >
  >
  link?: Resolver<
    ResolversTypes['LinkUserResult'],
    ParentType,
    ContextType,
    RequireFields<
      PayrollUserMutationsLinkArgs,
      'customerId' | 'toastIdentityGuid' | 'userId'
    >
  >
  linkIfMapped?: Resolver<
    Array<ResolversTypes['LinkIfMappedResult']>,
    ParentType,
    ContextType
  >
  unlink?: Resolver<
    ResolversTypes['UnlinkUserResult'],
    ParentType,
    ContextType,
    RequireFields<PayrollUserMutationsUnlinkArgs, 'customerId' | 'userId'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollWorkTaxLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollWorkTaxLocation'] = ResolversParentTypes['PayrollWorkTaxLocation']
> = {
  address?: Resolver<
    ResolversTypes['PayrollWorkTaxLocationAddress'],
    ParentType,
    ContextType
  >
  fein?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollWorkTaxLocationAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollWorkTaxLocationAddress'] = ResolversParentTypes['PayrollWorkTaxLocationAddress']
> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  gnisCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  line1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  line2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  line3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PendingEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingEmploymentStatus'] = ResolversParentTypes['PendingEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PendingPayChangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingPayChange'] = ResolversParentTypes['PendingPayChange']
> = {
  effectiveDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['PendingPayChangeStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PendingPayChangeNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingPayChangeNotFoundError'] = ResolversParentTypes['PendingPayChangeNotFoundError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PendingPayChangeNotFoundErrorsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingPayChangeNotFoundErrors'] = ResolversParentTypes['PendingPayChangeNotFoundErrors']
> = {
  pendingPayChangeNotFoundErrors?: Resolver<
    Array<ResolversTypes['PendingPayChangeNotFoundError']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PendingPayChangeStatusResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingPayChangeStatusResponse'] = ResolversParentTypes['PendingPayChangeStatusResponse']
> = {
  pendingPayChanges?: Resolver<
    Array<ResolversTypes['PendingPayChange']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PersonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Person'] = ResolversParentTypes['Person']
> = {
  chosenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  posAccessCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  ssn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PersonNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PersonName'] = ResolversParentTypes['PersonName']
> = {
  chosen?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  first?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  formatted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  last?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  middle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PosEmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PosEmployee'] = ResolversParentTypes['PosEmployee']
> = {
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  externalEmployeeId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  mappedToEmployee?: Resolver<
    Maybe<ResolversTypes['PayrollEmployeeMapping']>,
    ParentType,
    ContextType
  >
  mostRecentClockInDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  restaurantUserGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userName?: Resolver<ResolversTypes['PersonName'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PosRawEmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PosRawEmployee'] = ResolversParentTypes['PosRawEmployee']
> = {
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  locationGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  payrollLocationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  restaurantUserGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userName?: Resolver<ResolversTypes['PersonName'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PosUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PosUser'] = ResolversParentTypes['PosUser']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  restaurantUsers?: Resolver<
    Array<ResolversTypes['RestaurantUser']>,
    ParentType,
    ContextType
  >
  userGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userName?: Resolver<ResolversTypes['PersonName'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PostalAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PostalAddress'] = ResolversParentTypes['PostalAddress']
> = {
  administrativeArea?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  country?: Resolver<ResolversTypes['Country'], ParentType, ContextType>
  locality?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  streetAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  streetAddress2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  accessibleCustomers?: Resolver<
    ResolversTypes['AccessibleCustomersResult'],
    ParentType,
    ContextType,
    Partial<QueryAccessibleCustomersArgs>
  >
  actorPermissionsForEmployees?: Resolver<
    Array<ResolversTypes['ActorPermissionsForEmployee']>,
    ParentType,
    ContextType,
    RequireFields<QueryActorPermissionsForEmployeesArgs, 'employeeIds'>
  >
  checkAvailability?: Resolver<
    ResolversTypes['AvailabilityResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryCheckAvailabilityArgs, 'request'>
  >
  checkPosAvailability?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<QueryCheckPosAvailabilityArgs, 'request'>
  >
  completeDirectDeposits?: Resolver<
    ResolversTypes['CompleteDirectDepositsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryCompleteDirectDepositsArgs, 'completeDirectDepositInput'>
  >
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>
  customerBenefits?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Benefit']>>>,
    ParentType,
    ContextType
  >
  customerConfiguration?: Resolver<
    Maybe<ResolversTypes['CustomerConfiguration']>,
    ParentType,
    ContextType
  >
  directDepositEmailTemplate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  documentCenter?: Resolver<
    ResolversTypes['UserDocuments'],
    ParentType,
    ContextType,
    RequireFields<QueryDocumentCenterArgs, 'documentType'>
  >
  emailVerification?: Resolver<
    Maybe<ResolversTypes['EmailVerificationQuery']>,
    ParentType,
    ContextType
  >
  employee?: Resolver<
    ResolversTypes['Employee'],
    ParentType,
    ContextType,
    RequireFields<QueryEmployeeArgs, 'employeeUuid'>
  >
  employeeDocumentUploadUrl?: Resolver<
    ResolversTypes['EmployeeNewHireDocument'],
    ParentType,
    ContextType,
    RequireFields<QueryEmployeeDocumentUploadUrlArgs, 'request'>
  >
  employeeStatusTypeCount?: Resolver<
    ResolversTypes['EmployeeStatusTypeCount'],
    ParentType,
    ContextType
  >
  findCustomerSettings?: Resolver<
    Array<ResolversTypes['CustomerSetting']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindCustomerSettingsArgs, 'settings'>
  >
  findEmployeeById?: Resolver<
    Maybe<ResolversTypes['Employee']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindEmployeeByIdArgs, 'employeeId'>
  >
  findEmployeeForRestaurantByToastIdentityGuid?: Resolver<
    Maybe<ResolversTypes['Employee']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryFindEmployeeForRestaurantByToastIdentityGuidArgs,
      'toastIdentityGuid' | 'toastRestaurantGuid'
    >
  >
  findEmployeePay?: Resolver<
    Maybe<ResolversTypes['EmployeePay']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindEmployeePayArgs, 'employeeUuid'>
  >
  findJobMappingByJobId?: Resolver<
    Maybe<ResolversTypes['JobMapping']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindJobMappingByJobIdArgs, 'jobId'>
  >
  findJobMappingsByLocationId?: Resolver<
    Array<ResolversTypes['JobMapping']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindJobMappingsByLocationIdArgs, 'locationId'>
  >
  findPayrollJobsByLocationId?: Resolver<
    Array<ResolversTypes['PayrollJob']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindPayrollJobsByLocationIdArgs, 'locationId'>
  >
  findRestaurantJobsByRestaurantId?: Resolver<
    Array<ResolversTypes['RestaurantJob']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindRestaurantJobsByRestaurantIdArgs, 'restaurantId'>
  >
  findUserByEmployeeId?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindUserByEmployeeIdArgs, 'employeeId'>
  >
  findUserById?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindUserByIdArgs, 'userId'>
  >
  findUserEventsByUserId?: Resolver<
    Array<ResolversTypes['UserEvent']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindUserEventsByUserIdArgs, 'input'>
  >
  findUsers?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindUsersArgs, 'companyCode' | 'employeeUuid'>
  >
  getEmployeeMappingStatus?: Resolver<
    ResolversTypes['EmployeeMappingStatus'],
    ParentType,
    ContextType,
    RequireFields<QueryGetEmployeeMappingStatusArgs, 'employeeUuid'>
  >
  getPosMappingOptions?: Resolver<
    Array<ResolversTypes['PosEmployee']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryGetPosMappingOptionsArgs,
      'employeeUuid' | 'locationUuid'
    >
  >
  getPosUser?: Resolver<
    Array<ResolversTypes['PosRawEmployee']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPosUserArgs, 'userGuid'>
  >
  getPosUserV2?: Resolver<
    Maybe<ResolversTypes['PosUser']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPosUserV2Args, 'userGuid'>
  >
  getUnmappedPosUsers?: Resolver<
    Array<ResolversTypes['PosRawEmployee']>,
    ParentType,
    ContextType
  >
  helloFromBenefits?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  helloWorld?: Resolver<ResolversTypes['Response'], ParentType, ContextType>
  incompleteDirectDeposits?: Resolver<
    ResolversTypes['IncompleteDirectDepositsResponse'],
    ParentType,
    ContextType,
    RequireFields<
      QueryIncompleteDirectDepositsArgs,
      'incompleteDirectDepositInput'
    >
  >
  intercomSessionData?: Resolver<
    Maybe<ResolversTypes['IntercomSessionDataResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryIntercomSessionDataArgs, 'intercomClientType'>
  >
  jobAssignmentsById?: Resolver<
    Array<ResolversTypes['JobAssignment']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryJobAssignmentsByIdArgs,
      'employeeId' | 'jobAssignmentIds'
    >
  >
  navigableTaxAccounts?: Resolver<
    ResolversTypes['NavigableTaxAccountResult'],
    ParentType,
    ContextType,
    Partial<QueryNavigableTaxAccountsArgs>
  >
  navigation?: Resolver<
    ResolversTypes['NavigationResult'],
    ParentType,
    ContextType,
    Partial<QueryNavigationArgs>
  >
  newHireRequiredDocumentsForAssignedFields?: Resolver<
    Array<ResolversTypes['NewHireDocument']>,
    ParentType,
    ContextType,
    RequireFields<QueryNewHireRequiredDocumentsForAssignedFieldsArgs, 'request'>
  >
  nextAvailableEmployeeNumber?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  numRunPayrollsInThePastYear?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  payCardCustomerAccess?: Resolver<
    ResolversTypes['PayCardCustomerAccess'],
    ParentType,
    ContextType,
    RequireFields<QueryPayCardCustomerAccessArgs, 'input'>
  >
  payCardCustomerSettings?: Resolver<
    ResolversTypes['PayCardCustomerSettings'],
    ParentType,
    ContextType
  >
  payCardCustomerSettingsAuditHistory?: Resolver<
    Array<ResolversTypes['PayCardCustomerSettingsAuditEntry']>,
    ParentType,
    ContextType
  >
  payCardCustomerSettingsLastEvent?: Resolver<
    Maybe<ResolversTypes['PayCardCustomerSettingsAuditEntry']>,
    ParentType,
    ContextType
  >
  payCardEmployeeAccess?: Resolver<
    ResolversTypes['PayCardEmployeeAccess'],
    ParentType,
    ContextType,
    RequireFields<QueryPayCardEmployeeAccessArgs, 'input'>
  >
  payChangeLog?: Resolver<
    Maybe<ResolversTypes['PayChangeLogResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryPayChangeLogArgs,
      'employeeId' | 'pageNumber' | 'pageSize' | 'pageToken' | 'sort'
    >
  >
  payrollCustomerUsers?: Resolver<
    Array<ResolversTypes['PayrollCustomerUser']>,
    ParentType,
    ContextType,
    Partial<QueryPayrollCustomerUsersArgs>
  >
  payrollOnboardingPermissions?: Resolver<
    Array<ResolversTypes['OnboardingCheckListPermission']>,
    ParentType,
    ContextType
  >
  requiredDocumentationCustomFields?: Resolver<
    Array<ResolversTypes['CustomDocumentFields']>,
    ParentType,
    ContextType
  >
  requiredDocumentationCustomFieldsV2?: Resolver<
    Array<ResolversTypes['CustomDocumentFieldsV2']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType,
    RequireFields<QueryRestaurantArgs, 'restaurantGuid'>
  >
  retroPayForHourlyPayChange?: Resolver<
    Maybe<ResolversTypes['HourlyRetroPayResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryRetroPayForHourlyPayChangeArgs, 'hourlyRetroPayInput'>
  >
  retroPayForSalaryChange?: Resolver<
    Maybe<ResolversTypes['SalaryRetroPayResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryRetroPayForSalaryChangeArgs, 'salaryRetroPayInput'>
  >
  sections?: Resolver<
    ResolversTypes['SectionResult'],
    ParentType,
    ContextType,
    Partial<QuerySectionsArgs>
  >
  userMigration?: Resolver<
    Maybe<ResolversTypes['UserMigrationQuery']>,
    ParentType,
    ContextType
  >
}

export type RehiredEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RehiredEmploymentStatus'] = ResolversParentTypes['RehiredEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  dismissalPeriod?: Resolver<
    Maybe<ResolversTypes['DateRange']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  rehireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response']
> = {
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Restaurant'] = ResolversParentTypes['Restaurant']
> = {
  guid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantJob'] = ResolversParentTypes['RestaurantJob']
> = {
  guid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['RestaurantJobStatusCode'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantUser'] = ResolversParentTypes['RestaurantUser']
> = {
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  jobs?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  locationGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  restaurantUserGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Salary'] = ResolversParentTypes['Salary']
> = {
  activeSalaryRateOfPay?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  employmentTaxType?: Resolver<
    Maybe<ResolversTypes['EmploymentTaxType']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  payFrequency?: Resolver<
    Maybe<ResolversTypes['PayFrequency']>,
    ParentType,
    ContextType
  >
  pendingPayChange?: Resolver<
    Maybe<ResolversTypes['PendingPayChange']>,
    ParentType,
    ContextType
  >
  salaryAllocations?: Resolver<
    Array<ResolversTypes['SalaryAllocation']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryAllocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalaryAllocation'] = ResolversParentTypes['SalaryAllocation']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jobAssignment?: Resolver<
    ResolversTypes['JobAssignment'],
    ParentType,
    ContextType
  >
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryAllocationNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalaryAllocationNotFoundError'] = ResolversParentTypes['SalaryAllocationNotFoundError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalaryNotFoundError'] = ResolversParentTypes['SalaryNotFoundError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryPayFrequencyDoesNotExistErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalaryPayFrequencyDoesNotExistError'] = ResolversParentTypes['SalaryPayFrequencyDoesNotExistError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryPayFrequencyNoneErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalaryPayFrequencyNoneError'] = ResolversParentTypes['SalaryPayFrequencyNoneError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryRetroPayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalaryRetroPay'] = ResolversParentTypes['SalaryRetroPay']
> = {
  payChangeDifference?: Resolver<
    ResolversTypes['AmountWithPayInterval'],
    ParentType,
    ContextType
  >
  payFrequency?: Resolver<
    ResolversTypes['PayFrequency'],
    ParentType,
    ContextType
  >
  payPeriodEarnings?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  retroPay?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryRetroPayResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalaryRetroPayResponse'] = ResolversParentTypes['SalaryRetroPayResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'InvalidStartDateError'
    | 'SalaryPayFrequencyDoesNotExistError'
    | 'SalaryPayFrequencyNoneError'
    | 'SalaryRetroPay'
    | 'TimesheetRetrievalError',
    ParentType,
    ContextType
  >
}

export type SaveNewEmployeeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SaveNewEmployeeResponse'] = ResolversParentTypes['SaveNewEmployeeResponse']
> = {
  employeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeasonalEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeasonalEmploymentStatus'] = ResolversParentTypes['SeasonalEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section']
> = {
  items?: Resolver<Array<ResolversTypes['Navigation']>, ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  labelNew?: Resolver<
    ResolversTypes['LocalizableMessage'],
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SectionResult'] = ResolversParentTypes['SectionResult']
> = {
  errors?: Resolver<
    Array<ResolversTypes['NavigationErrors']>,
    ParentType,
    ContextType
  >
  result?: Resolver<Array<ResolversTypes['Section']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SendEmailVerificationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendEmailVerificationError'] = ResolversParentTypes['SendEmailVerificationError']
> = {
  __resolveType: TypeResolveFn<
    | 'DuplicateRequest'
    | 'EmailAlreadyVerified'
    | 'EmailHasBeenUpdated'
    | 'EmailVerificationFailed'
    | 'KeyDoesNotMatch',
    ParentType,
    ContextType
  >
}

export type SendVerificationEmailResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendVerificationEmailResult'] = ResolversParentTypes['SendVerificationEmailResult']
> = {
  __resolveType: TypeResolveFn<
    | 'DuplicateRequest'
    | 'EmailAlreadyVerified'
    | 'EmailHasBeenUpdated'
    | 'EmailVerificationFailed'
    | 'KeyDoesNotMatch'
    | 'VerificationEmailSent',
    ParentType,
    ContextType
  >
}

export type StateInUnitedStatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StateInUnitedStates'] = ResolversParentTypes['StateInUnitedStates']
> = {
  abbreviation?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fipsCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SupervisorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Supervisor'] = ResolversParentTypes['Supervisor']
> = {
  id?: Resolver<ResolversTypes['EmploymentId'], ParentType, ContextType>
  job?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SupervisorsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Supervisors'] = ResolversParentTypes['Supervisors']
> = {
  supervisors?: Resolver<
    Array<ResolversTypes['Supervisor']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TerminatedEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TerminatedEmploymentStatus'] = ResolversParentTypes['TerminatedEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastDayOfEmployment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  lastDayToReceiveBenefits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyBenefitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyBenefit'] = ResolversParentTypes['ThirdPartyBenefit']
> = {
  benefitType?: Resolver<
    ResolversTypes['ThirdPartyBenefitType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TimesheetRetrievalErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimesheetRetrievalError'] = ResolversParentTypes['TimesheetRetrievalError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastVersionIgnoredResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastVersionIgnored'] = ResolversParentTypes['ToastVersionIgnored']
> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnlinkUserResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnlinkUserResult'] = ResolversParentTypes['UnlinkUserResult']
> = {
  __resolveType: TypeResolveFn<
    'PayrollUser' | 'UserNotFound' | 'UserNotLinked',
    ParentType,
    ContextType
  >
}

export type UpdateEmployeePaymentMethodEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateEmployeePaymentMethodEmailResponse'] = ResolversParentTypes['UpdateEmployeePaymentMethodEmailResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'EmailInvalidError'
    | 'EmailSendingError'
    | 'EmployeeNotFoundError'
    | 'EmployeePaymentMethodEmailResponse',
    ParentType,
    ContextType
  >
}

export type UpdatePayCardCustomerSettingsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePayCardCustomerSettingsResponse'] = ResolversParentTypes['UpdatePayCardCustomerSettingsResponse']
> = {
  payCardCustomerSettings?: Resolver<
    ResolversTypes['PayCardCustomerSettings'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatePendingPayChangeStatusResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePendingPayChangeStatusResponse'] = ResolversParentTypes['UpdatePendingPayChangeStatusResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'EmployeeNotFoundError'
    | 'PendingPayChangeNotFoundErrors'
    | 'PendingPayChangeStatusResponse',
    ParentType,
    ContextType
  >
}

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  employeeUuid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isEmailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['PersonName'], ParentType, ContextType>
  toastIdentityGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserAlreadyLinkedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAlreadyLinked'] = ResolversParentTypes['UserAlreadyLinked']
> = {
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserDocumentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDocument'] = ResolversParentTypes['UserDocument']
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserDocumentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDocuments'] = ResolversParentTypes['UserDocuments']
> = {
  documents?: Resolver<
    Array<ResolversTypes['UserDocument']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEvent'] = ResolversParentTypes['UserEvent']
> = {
  __resolveType: TypeResolveFn<
    | 'UserLinkChanged'
    | 'UserLinked'
    | 'UserRoleAdded'
    | 'UserRoleRemoved'
    | 'UserUnlinked'
    | 'UsernameChanged'
    | 'UsernameChangedWithConflict',
    ParentType,
    ContextType
  >
}

export type UserLinkChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserLinkChanged'] = ResolversParentTypes['UserLinkChanged']
> = {
  actorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  newToastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  oldToastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserLinkedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserLinked'] = ResolversParentTypes['UserLinked']
> = {
  actorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationChooseAnotherUsernameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationChooseAnotherUsername'] = ResolversParentTypes['UserMigrationChooseAnotherUsername']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationConfirmAccountVerificationEmailResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationConfirmAccountVerificationEmailResult'] = ResolversParentTypes['UserMigrationConfirmAccountVerificationEmailResult']
> = {
  __resolveType: TypeResolveFn<
    | 'UserMigrationChooseAnotherUsername'
    | 'UserMigrationEmailConfirmationFailed'
    | 'UserMigrationEmailExistsInCustomer'
    | 'UserMigrationEmailVerified'
    | 'UserMigrationFailed'
    | 'UserMigrationNotEnabled'
    | 'UserMigrationToastUserHasCredentials'
    | 'UserMigrationUserAlreadyMigrated'
    | 'UserMigrationUserNotFound',
    ParentType,
    ContextType
  >
}

export type UserMigrationDefaultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationDefaults'] = ResolversParentTypes['UserMigrationDefaults']
> = {
  canChangeEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  source?: Resolver<
    ResolversTypes['UserMigrationDefaultEmailSource'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationDefaultsResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationDefaultsResult'] = ResolversParentTypes['UserMigrationDefaultsResult']
> = {
  __resolveType: TypeResolveFn<
    | 'UserMigrationDefaults'
    | 'UserMigrationFailed'
    | 'UserMigrationNotEnabled'
    | 'UserMigrationUserAlreadyMigrated'
    | 'UserMigrationUserNotFound',
    ParentType,
    ContextType
  >
}

export type UserMigrationEmailConfirmationFailedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationEmailConfirmationFailed'] = ResolversParentTypes['UserMigrationEmailConfirmationFailed']
> = {
  error?: Resolver<
    ResolversTypes['ConfirmVerificationEmailError'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationEmailExistsInCustomerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationEmailExistsInCustomer'] = ResolversParentTypes['UserMigrationEmailExistsInCustomer']
> = {
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationEmailNotVerifiedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationEmailNotVerified'] = ResolversParentTypes['UserMigrationEmailNotVerified']
> = {
  toastAccountType?: Resolver<
    ResolversTypes['UserMigrationToastAccountType'],
    ParentType,
    ContextType
  >
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationEmailVerifiedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationEmailVerified'] = ResolversParentTypes['UserMigrationEmailVerified']
> = {
  toastAccountType?: Resolver<
    ResolversTypes['UserMigrationToastAccountType'],
    ParentType,
    ContextType
  >
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  verifiedTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationFailedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationFailed'] = ResolversParentTypes['UserMigrationFailed']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationMigrateResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationMigrateResult'] = ResolversParentTypes['UserMigrationMigrateResult']
> = {
  __resolveType: TypeResolveFn<
    | 'UserMigrationChooseAnotherUsername'
    | 'UserMigrationEmailExistsInCustomer'
    | 'UserMigrationEmailNotVerified'
    | 'UserMigrationFailed'
    | 'UserMigrationNotEnabled'
    | 'UserMigrationSuccess'
    | 'UserMigrationToastUserHasCredentials'
    | 'UserMigrationUserAlreadyMigrated'
    | 'UserMigrationUserNotFound',
    ParentType,
    ContextType
  >
}

export type UserMigrationMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationMutation'] = ResolversParentTypes['UserMigrationMutation']
> = {
  confirmAccountVerificationEmail?: Resolver<
    ResolversTypes['UserMigrationConfirmAccountVerificationEmailResult'],
    ParentType,
    ContextType,
    RequireFields<
      UserMigrationMutationConfirmAccountVerificationEmailArgs,
      'confirmationKey' | 'email'
    >
  >
  migrate?: Resolver<
    ResolversTypes['UserMigrationMigrateResult'],
    ParentType,
    ContextType,
    RequireFields<UserMigrationMutationMigrateArgs, 'email' | 'password'>
  >
  sendAccountVerificationEmail?: Resolver<
    ResolversTypes['UserMigrationSendAccountVerificationEmailResult'],
    ParentType,
    ContextType,
    RequireFields<
      UserMigrationMutationSendAccountVerificationEmailArgs,
      'email' | 'idempotencyKey'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationNotEnabledResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationNotEnabled'] = ResolversParentTypes['UserMigrationNotEnabled']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationQuery'] = ResolversParentTypes['UserMigrationQuery']
> = {
  defaults?: Resolver<
    ResolversTypes['UserMigrationDefaultsResult'],
    ParentType,
    ContextType
  >
  verifyAccountEmail?: Resolver<
    ResolversTypes['UserMigrationVerifyAccountEmailResult'],
    ParentType,
    ContextType,
    RequireFields<UserMigrationQueryVerifyAccountEmailArgs, 'email'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationSendAccountVerificationEmailFailedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationSendAccountVerificationEmailFailed'] = ResolversParentTypes['UserMigrationSendAccountVerificationEmailFailed']
> = {
  error?: Resolver<
    Maybe<ResolversTypes['SendEmailVerificationError']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationSendAccountVerificationEmailResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationSendAccountVerificationEmailResult'] = ResolversParentTypes['UserMigrationSendAccountVerificationEmailResult']
> = {
  __resolveType: TypeResolveFn<
    | 'UserMigrationChooseAnotherUsername'
    | 'UserMigrationEmailExistsInCustomer'
    | 'UserMigrationFailed'
    | 'UserMigrationNotEnabled'
    | 'UserMigrationSendAccountVerificationEmailFailed'
    | 'UserMigrationToastUserHasCredentials'
    | 'UserMigrationUserAlreadyMigrated'
    | 'UserMigrationUserNotFound'
    | 'UserMigrationVerificationEmailSent',
    ParentType,
    ContextType
  >
}

export type UserMigrationSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationSuccess'] = ResolversParentTypes['UserMigrationSuccess']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationToastUserHasCredentialsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationToastUserHasCredentials'] = ResolversParentTypes['UserMigrationToastUserHasCredentials']
> = {
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationUserAlreadyMigratedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationUserAlreadyMigrated'] = ResolversParentTypes['UserMigrationUserAlreadyMigrated']
> = {
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationUserNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationUserNotFound'] = ResolversParentTypes['UserMigrationUserNotFound']
> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationVerificationEmailSentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationVerificationEmailSent'] = ResolversParentTypes['UserMigrationVerificationEmailSent']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  expirationTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  idempotencyKey?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMigrationVerifyAccountEmailResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMigrationVerifyAccountEmailResult'] = ResolversParentTypes['UserMigrationVerifyAccountEmailResult']
> = {
  __resolveType: TypeResolveFn<
    | 'UserMigrationChooseAnotherUsername'
    | 'UserMigrationEmailExistsInCustomer'
    | 'UserMigrationEmailNotVerified'
    | 'UserMigrationEmailVerified'
    | 'UserMigrationFailed'
    | 'UserMigrationNotEnabled'
    | 'UserMigrationToastUserHasCredentials'
    | 'UserMigrationUserAlreadyMigrated'
    | 'UserMigrationUserNotFound',
    ParentType,
    ContextType
  >
}

export type UserNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNotFound'] = ResolversParentTypes['UserNotFound']
> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserNotLinkedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNotLinked'] = ResolversParentTypes['UserNotLinked']
> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserNotMappedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNotMapped'] = ResolversParentTypes['UserNotMapped']
> = {
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRoleAddedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserRoleAdded'] = ResolversParentTypes['UserRoleAdded']
> = {
  actorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  role?: Resolver<
    Maybe<ResolversTypes['SecurityRole']>,
    ParentType,
    ContextType
  >
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRoleRemovedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserRoleRemoved'] = ResolversParentTypes['UserRoleRemoved']
> = {
  actorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  role?: Resolver<
    Maybe<ResolversTypes['SecurityRole']>,
    ParentType,
    ContextType
  >
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUnlinkedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserUnlinked'] = ResolversParentTypes['UserUnlinked']
> = {
  actorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  toastIdentityGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsernameChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsernameChanged'] = ResolversParentTypes['UsernameChanged']
> = {
  actorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  fromUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  toUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  toastIdentityGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsernameChangedWithConflictResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsernameChangedWithConflict'] = ResolversParentTypes['UsernameChangedWithConflict']
> = {
  actorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  conflictingUserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  fromUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  toUsername?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  toastIdentityGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsernameExistsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsernameExists'] = ResolversParentTypes['UsernameExists']
> = {
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users']
> = {
  list?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VerificationEmailConfirmedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerificationEmailConfirmed'] = ResolversParentTypes['VerificationEmailConfirmed']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VerificationEmailNotSentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerificationEmailNotSent'] = ResolversParentTypes['VerificationEmailNotSent']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VerificationEmailSentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerificationEmailSent'] = ResolversParentTypes['VerificationEmailSent']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  expirationTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  idempotencyKey?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  AbsentPayChangeLogTokenError?: AbsentPayChangeLogTokenErrorResolvers<ContextType>
  AccessibleCustomer?: AccessibleCustomerResolvers<ContextType>
  AccessibleCustomersResult?: AccessibleCustomersResultResolvers<ContextType>
  ActiveEmploymentStatus?: ActiveEmploymentStatusResolvers<ContextType>
  Actor?: ActorResolvers<ContextType>
  ActorPermissionsForEmployee?: ActorPermissionsForEmployeeResolvers<ContextType>
  AdditionalEarningResponse?: AdditionalEarningResponseResolvers<ContextType>
  AllEmploymentStatuses?: AllEmploymentStatusesResolvers<ContextType>
  AmountWithPayInterval?: AmountWithPayIntervalResolvers<ContextType>
  ApplicantEmploymentStatus?: ApplicantEmploymentStatusResolvers<ContextType>
  AvailabilityResponse?: AvailabilityResponseResolvers<ContextType>
  Benefit?: BenefitResolvers<ContextType>
  BenefitOption?: BenefitOptionResolvers<ContextType>
  Benefits?: BenefitsResolvers<ContextType>
  BenefitsThirdPartyDataSharingConsent?: BenefitsThirdPartyDataSharingConsentResolvers<ContextType>
  BulkNotifyResponse?: BulkNotifyResponseResolvers<ContextType>
  CannotChangeLinkedUsername?: CannotChangeLinkedUsernameResolvers<ContextType>
  CannotChangeUnlinkedUsername?: CannotChangeUnlinkedUsernameResolvers<ContextType>
  ChangeLinkedUsernameResult?: ChangeLinkedUsernameResultResolvers<ContextType>
  ChangeSalaryResponse?: ChangeSalaryResponseResolvers<ContextType>
  ChangeUnlinkedUsernameResult?: ChangeUnlinkedUsernameResultResolvers<ContextType>
  ChecklistState?: ChecklistStateResolvers<ContextType>
  CompleteDirectDepositEmployee?: CompleteDirectDepositEmployeeResolvers<ContextType>
  CompleteDirectDepositsResponse?: CompleteDirectDepositsResponseResolvers<ContextType>
  ConfirmVerificationEmailError?: ConfirmVerificationEmailErrorResolvers<ContextType>
  ConfirmVerificationEmailResult?: ConfirmVerificationEmailResultResolvers<ContextType>
  ConfirmationExpired?: ConfirmationExpiredResolvers<ContextType>
  ContactTelephoneNumber?: ContactTelephoneNumberResolvers<ContextType>
  Country?: CountryResolvers<ContextType>
  CustomDocumentFields?: CustomDocumentFieldsResolvers<ContextType>
  CustomDocumentFieldsV2?: CustomDocumentFieldsV2Resolvers<ContextType>
  Customer?: CustomerResolvers<ContextType>
  CustomerConfiguration?: CustomerConfigurationResolvers<ContextType>
  CustomerPosition?: CustomerPositionResolvers<ContextType>
  CustomerSetting?: CustomerSettingResolvers<ContextType>
  DataExceptionError?: DataExceptionErrorResolvers<ContextType>
  Date?: GraphQLScalarType
  DateRange?: DateRangeResolvers<ContextType>
  DateTime?: GraphQLScalarType
  Decimal?: GraphQLScalarType
  DefaultEarningsId?: DefaultEarningsIdResolvers<ContextType>
  DeletedEmploymentStatus?: DeletedEmploymentStatusResolvers<ContextType>
  DemoEmploymentStatus?: DemoEmploymentStatusResolvers<ContextType>
  DirectDepositAllocation?: DirectDepositAllocationResolvers<ContextType>
  DirectDepositBulkEmailResponse?: DirectDepositBulkEmailResponseResolvers<ContextType>
  DirectDepositNotification?: DirectDepositNotificationResolvers<ContextType>
  DriversLicense?: DriversLicenseResolvers<ContextType>
  DuplicateRequest?: DuplicateRequestResolvers<ContextType>
  EarningListEmptyError?: EarningListEmptyErrorResolvers<ContextType>
  EeoClassification?: EeoClassificationResolvers<ContextType>
  EmailAlreadyVerified?: EmailAlreadyVerifiedResolvers<ContextType>
  EmailHasBeenUpdated?: EmailHasBeenUpdatedResolvers<ContextType>
  EmailInvalidError?: EmailInvalidErrorResolvers<ContextType>
  EmailNotFound?: EmailNotFoundResolvers<ContextType>
  EmailSendingError?: EmailSendingErrorResolvers<ContextType>
  EmailVerification?: EmailVerificationResolvers<ContextType>
  EmailVerificationError?: EmailVerificationErrorResolvers<ContextType>
  EmailVerificationFailed?: EmailVerificationFailedResolvers<ContextType>
  EmailVerificationMutation?: EmailVerificationMutationResolvers<ContextType>
  EmailVerificationQuery?: EmailVerificationQueryResolvers<ContextType>
  EmailVerificationResult?: EmailVerificationResultResolvers<ContextType>
  EmergencyContact?: EmergencyContactResolvers<ContextType>
  Employee?: EmployeeResolvers<ContextType>
  EmployeeAtLocation?: EmployeeAtLocationResolvers<ContextType>
  EmployeeContact?: EmployeeContactResolvers<ContextType>
  EmployeeDemographics?: EmployeeDemographicsResolvers<ContextType>
  EmployeeIdentification?: EmployeeIdentificationResolvers<ContextType>
  EmployeeJob?: EmployeeJobResolvers<ContextType>
  EmployeeMappingStatus?: EmployeeMappingStatusResolvers<ContextType>
  EmployeeNewHireDocument?: EmployeeNewHireDocumentResolvers<ContextType>
  EmployeeNotFoundError?: EmployeeNotFoundErrorResolvers<ContextType>
  EmployeePay?: EmployeePayResolvers<ContextType>
  EmployeePayHistory?: EmployeePayHistoryResolvers<ContextType>
  EmployeePaymentMethodEmailResponse?: EmployeePaymentMethodEmailResponseResolvers<ContextType>
  EmployeeStatusTypeCount?: EmployeeStatusTypeCountResolvers<ContextType>
  EmployeeSupervisor?: EmployeeSupervisorResolvers<ContextType>
  Employment?: EmploymentResolvers<ContextType>
  EmploymentDetails?: EmploymentDetailsResolvers<ContextType>
  EmploymentId?: EmploymentIdResolvers<ContextType>
  EmploymentStatus?: EmploymentStatusResolvers<ContextType>
  EmptySalaryAllocationsError?: EmptySalaryAllocationsErrorResolvers<ContextType>
  GenericResponse?: GenericResponseResolvers<ContextType>
  HourlyRetroPay?: HourlyRetroPayResolvers<ContextType>
  HourlyRetroPayResponse?: HourlyRetroPayResponseResolvers<ContextType>
  ImplementationEmploymentStatus?: ImplementationEmploymentStatusResolvers<ContextType>
  IncompleteDirectDepositEmployee?: IncompleteDirectDepositEmployeeResolvers<ContextType>
  IncompleteDirectDepositsResponse?: IncompleteDirectDepositsResponseResolvers<ContextType>
  InputCannotBeEmpty?: InputCannotBeEmptyResolvers<ContextType>
  IntercomSessionDataResponse?: IntercomSessionDataResponseResolvers<ContextType>
  InvalidEffectiveDateError?: InvalidEffectiveDateErrorResolvers<ContextType>
  InvalidPayChangeLogTokenError?: InvalidPayChangeLogTokenErrorResolvers<ContextType>
  InvalidStartDateError?: InvalidStartDateErrorResolvers<ContextType>
  InvalidUsername?: InvalidUsernameResolvers<ContextType>
  Item?: ItemResolvers<ContextType>
  Job?: JobResolvers<ContextType>
  JobAssignment?: JobAssignmentResolvers<ContextType>
  JobAssignmentNotFoundError?: JobAssignmentNotFoundErrorResolvers<ContextType>
  JobMapping?: JobMappingResolvers<ContextType>
  JobTag?: JobTagResolvers<ContextType>
  JobTagAssignment?: JobTagAssignmentResolvers<ContextType>
  KeyDoesNotMatch?: KeyDoesNotMatchResolvers<ContextType>
  LeaveOfAbsenceEmploymentStatus?: LeaveOfAbsenceEmploymentStatusResolvers<ContextType>
  LevelId?: LevelIdResolvers<ContextType>
  LinkIfMappedResult?: LinkIfMappedResultResolvers<ContextType>
  LinkUserResult?: LinkUserResultResolvers<ContextType>
  LinkingNotAvailable?: LinkingNotAvailableResolvers<ContextType>
  LocalizableMessage?: LocalizableMessageResolvers<ContextType>
  Location?: LocationResolvers<ContextType>
  LocationMappingStatus?: LocationMappingStatusResolvers<ContextType>
  Long?: GraphQLScalarType
  MappedUserMismatchedUsername?: MappedUserMismatchedUsernameResolvers<ContextType>
  MappingIssue?: MappingIssueResolvers<ContextType>
  MissingPayGroupError?: MissingPayGroupErrorResolvers<ContextType>
  Money?: MoneyResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  NavigableTaxAccount?: NavigableTaxAccountResolvers<ContextType>
  NavigableTaxAccountResult?: NavigableTaxAccountResultResolvers<ContextType>
  Navigation?: NavigationResolvers<ContextType>
  NavigationError?: NavigationErrorResolvers<ContextType>
  NavigationErrors?: NavigationErrorsResolvers<ContextType>
  NavigationResult?: NavigationResultResolvers<ContextType>
  NeverEmployedEmploymentStatus?: NeverEmployedEmploymentStatusResolvers<ContextType>
  NewHireDocument?: NewHireDocumentResolvers<ContextType>
  NewJobAssignment?: NewJobAssignmentResolvers<ContextType>
  NewJobsAndPay?: NewJobsAndPayResolvers<ContextType>
  NextPayPeriodNotFoundError?: NextPayPeriodNotFoundErrorResolvers<ContextType>
  NextPaycheckDateNotFoundError?: NextPaycheckDateNotFoundErrorResolvers<ContextType>
  NotAToastUser?: NotAToastUserResolvers<ContextType>
  PayCardAdminSettings?: PayCardAdminSettingsResolvers<ContextType>
  PayCardBusinessAccess?: PayCardBusinessAccessResolvers<ContextType>
  PayCardCustomerAccess?: PayCardCustomerAccessResolvers<ContextType>
  PayCardCustomerSettings?: PayCardCustomerSettingsResolvers<ContextType>
  PayCardCustomerSettingsAuditEntry?: PayCardCustomerSettingsAuditEntryResolvers<ContextType>
  PayCardCustomerSettingsAuditHistoryChangeContent?: PayCardCustomerSettingsAuditHistoryChangeContentResolvers<ContextType>
  PayCardCustomerSettingsAuditUser?: PayCardCustomerSettingsAuditUserResolvers<ContextType>
  PayCardEmployeeAccess?: PayCardEmployeeAccessResolvers<ContextType>
  PayCardEmployeeAccessFactors?: PayCardEmployeeAccessFactorsResolvers<ContextType>
  PayCardRestaurantAccess?: PayCardRestaurantAccessResolvers<ContextType>
  PayChangeEvent?: PayChangeEventResolvers<ContextType>
  PayChangeLog?: PayChangeLogResolvers<ContextType>
  PayChangeLogResponse?: PayChangeLogResponseResolvers<ContextType>
  PayGroup?: PayGroupResolvers<ContextType>
  PayGroupAssignment?: PayGroupAssignmentResolvers<ContextType>
  PayGroupWithFrequency?: PayGroupWithFrequencyResolvers<ContextType>
  Paycheck?: PaycheckResolvers<ContextType>
  PayrollCheckCode?: PayrollCheckCodeResolvers<ContextType>
  PayrollCustomerUser?: PayrollCustomerUserResolvers<ContextType>
  PayrollEmployeeMapping?: PayrollEmployeeMappingResolvers<ContextType>
  PayrollFein?: PayrollFeinResolvers<ContextType>
  PayrollJob?: PayrollJobResolvers<ContextType>
  PayrollLocation?: PayrollLocationResolvers<ContextType>
  PayrollSecurityRole?: PayrollSecurityRoleResolvers<ContextType>
  PayrollUser?: PayrollUserResolvers<ContextType>
  PayrollUserMutations?: PayrollUserMutationsResolvers<ContextType>
  PayrollWorkTaxLocation?: PayrollWorkTaxLocationResolvers<ContextType>
  PayrollWorkTaxLocationAddress?: PayrollWorkTaxLocationAddressResolvers<ContextType>
  PendingEmploymentStatus?: PendingEmploymentStatusResolvers<ContextType>
  PendingPayChange?: PendingPayChangeResolvers<ContextType>
  PendingPayChangeNotFoundError?: PendingPayChangeNotFoundErrorResolvers<ContextType>
  PendingPayChangeNotFoundErrors?: PendingPayChangeNotFoundErrorsResolvers<ContextType>
  PendingPayChangeStatusResponse?: PendingPayChangeStatusResponseResolvers<ContextType>
  Person?: PersonResolvers<ContextType>
  PersonName?: PersonNameResolvers<ContextType>
  PosEmployee?: PosEmployeeResolvers<ContextType>
  PosRawEmployee?: PosRawEmployeeResolvers<ContextType>
  PosUser?: PosUserResolvers<ContextType>
  PostalAddress?: PostalAddressResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  RehiredEmploymentStatus?: RehiredEmploymentStatusResolvers<ContextType>
  Response?: ResponseResolvers<ContextType>
  Restaurant?: RestaurantResolvers<ContextType>
  RestaurantJob?: RestaurantJobResolvers<ContextType>
  RestaurantUser?: RestaurantUserResolvers<ContextType>
  Salary?: SalaryResolvers<ContextType>
  SalaryAllocation?: SalaryAllocationResolvers<ContextType>
  SalaryAllocationNotFoundError?: SalaryAllocationNotFoundErrorResolvers<ContextType>
  SalaryNotFoundError?: SalaryNotFoundErrorResolvers<ContextType>
  SalaryPayFrequencyDoesNotExistError?: SalaryPayFrequencyDoesNotExistErrorResolvers<ContextType>
  SalaryPayFrequencyNoneError?: SalaryPayFrequencyNoneErrorResolvers<ContextType>
  SalaryRetroPay?: SalaryRetroPayResolvers<ContextType>
  SalaryRetroPayResponse?: SalaryRetroPayResponseResolvers<ContextType>
  SaveNewEmployeeResponse?: SaveNewEmployeeResponseResolvers<ContextType>
  SeasonalEmploymentStatus?: SeasonalEmploymentStatusResolvers<ContextType>
  Section?: SectionResolvers<ContextType>
  SectionResult?: SectionResultResolvers<ContextType>
  SendEmailVerificationError?: SendEmailVerificationErrorResolvers<ContextType>
  SendVerificationEmailResult?: SendVerificationEmailResultResolvers<ContextType>
  StateInUnitedStates?: StateInUnitedStatesResolvers<ContextType>
  Supervisor?: SupervisorResolvers<ContextType>
  Supervisors?: SupervisorsResolvers<ContextType>
  TerminatedEmploymentStatus?: TerminatedEmploymentStatusResolvers<ContextType>
  ThirdPartyBenefit?: ThirdPartyBenefitResolvers<ContextType>
  TimesheetRetrievalError?: TimesheetRetrievalErrorResolvers<ContextType>
  ToastVersionIgnored?: ToastVersionIgnoredResolvers<ContextType>
  UnlinkUserResult?: UnlinkUserResultResolvers<ContextType>
  UpdateEmployeePaymentMethodEmailResponse?: UpdateEmployeePaymentMethodEmailResponseResolvers<ContextType>
  UpdatePayCardCustomerSettingsResponse?: UpdatePayCardCustomerSettingsResponseResolvers<ContextType>
  UpdatePendingPayChangeStatusResponse?: UpdatePendingPayChangeStatusResponseResolvers<ContextType>
  User?: UserResolvers<ContextType>
  UserAlreadyLinked?: UserAlreadyLinkedResolvers<ContextType>
  UserDocument?: UserDocumentResolvers<ContextType>
  UserDocuments?: UserDocumentsResolvers<ContextType>
  UserEvent?: UserEventResolvers<ContextType>
  UserLinkChanged?: UserLinkChangedResolvers<ContextType>
  UserLinked?: UserLinkedResolvers<ContextType>
  UserMigrationChooseAnotherUsername?: UserMigrationChooseAnotherUsernameResolvers<ContextType>
  UserMigrationConfirmAccountVerificationEmailResult?: UserMigrationConfirmAccountVerificationEmailResultResolvers<ContextType>
  UserMigrationDefaults?: UserMigrationDefaultsResolvers<ContextType>
  UserMigrationDefaultsResult?: UserMigrationDefaultsResultResolvers<ContextType>
  UserMigrationEmailConfirmationFailed?: UserMigrationEmailConfirmationFailedResolvers<ContextType>
  UserMigrationEmailExistsInCustomer?: UserMigrationEmailExistsInCustomerResolvers<ContextType>
  UserMigrationEmailNotVerified?: UserMigrationEmailNotVerifiedResolvers<ContextType>
  UserMigrationEmailVerified?: UserMigrationEmailVerifiedResolvers<ContextType>
  UserMigrationFailed?: UserMigrationFailedResolvers<ContextType>
  UserMigrationMigrateResult?: UserMigrationMigrateResultResolvers<ContextType>
  UserMigrationMutation?: UserMigrationMutationResolvers<ContextType>
  UserMigrationNotEnabled?: UserMigrationNotEnabledResolvers<ContextType>
  UserMigrationQuery?: UserMigrationQueryResolvers<ContextType>
  UserMigrationSendAccountVerificationEmailFailed?: UserMigrationSendAccountVerificationEmailFailedResolvers<ContextType>
  UserMigrationSendAccountVerificationEmailResult?: UserMigrationSendAccountVerificationEmailResultResolvers<ContextType>
  UserMigrationSuccess?: UserMigrationSuccessResolvers<ContextType>
  UserMigrationToastUserHasCredentials?: UserMigrationToastUserHasCredentialsResolvers<ContextType>
  UserMigrationUserAlreadyMigrated?: UserMigrationUserAlreadyMigratedResolvers<ContextType>
  UserMigrationUserNotFound?: UserMigrationUserNotFoundResolvers<ContextType>
  UserMigrationVerificationEmailSent?: UserMigrationVerificationEmailSentResolvers<ContextType>
  UserMigrationVerifyAccountEmailResult?: UserMigrationVerifyAccountEmailResultResolvers<ContextType>
  UserNotFound?: UserNotFoundResolvers<ContextType>
  UserNotLinked?: UserNotLinkedResolvers<ContextType>
  UserNotMapped?: UserNotMappedResolvers<ContextType>
  UserRoleAdded?: UserRoleAddedResolvers<ContextType>
  UserRoleRemoved?: UserRoleRemovedResolvers<ContextType>
  UserUnlinked?: UserUnlinkedResolvers<ContextType>
  UsernameChanged?: UsernameChangedResolvers<ContextType>
  UsernameChangedWithConflict?: UsernameChangedWithConflictResolvers<ContextType>
  UsernameExists?: UsernameExistsResolvers<ContextType>
  Users?: UsersResolvers<ContextType>
  VerificationEmailConfirmed?: VerificationEmailConfirmedResolvers<ContextType>
  VerificationEmailNotSent?: VerificationEmailNotSentResolvers<ContextType>
  VerificationEmailSent?: VerificationEmailSentResolvers<ContextType>
}

export type DirectiveResolvers<ContextType = any> = {
  extends?: ExtendsDirectiveResolver<any, any, ContextType>
  inaccessible?: InaccessibleDirectiveResolver<any, any, ContextType>
  link?: LinkDirectiveResolver<any, any, ContextType>
  override?: OverrideDirectiveResolver<any, any, ContextType>
  shareable?: ShareableDirectiveResolver<any, any, ContextType>
}
