import React from 'react'
import cx from 'classnames'

import {
  Body,
  Cell,
  Head as BuffetHead,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'

import {
  BulkActionTableFooter,
  BulkActionTableFooterProps
} from '@local/bulk-action-table-footer'
import { DirectDepositsQueryEmployeeFragment } from '@local/grouter'
import {
  AmountCell,
  DepositAccountTypeCell,
  NameCell,
  SequenceCell
} from './cells'

import { t } from '@local/translations'

export type DirectDepositIncompleteTableProps = {
  visibleEmployees: DirectDepositsQueryEmployeeFragment[]
} & BulkActionTableFooterProps

export const DirectDepositsTable: React.FunctionComponent<
  DirectDepositIncompleteTableProps
> = (props) => {
  return props.visibleEmployees && props.visibleEmployees.length > 0 ? (
    <Table variant='plain'>
      <Head />
      <Body>
        {props.visibleEmployees.map((employee) => {
          return <EmployeeRow key={employee.id} employee={employee} />
        })}
      </Body>
      <BulkActionTableFooter cellClassName='pt-6 pb-0' colSpan={5} {...props} />
    </Table>
  ) : null
}

const Head: React.FunctionComponent = () => {
  return (
    <BuffetHead>
      <Row>
        <HeadingCell>{t('direct-deposits.table.heading.name')}</HeadingCell>
        <HeadingCell>
          {t('direct-deposits.table.heading.account-type')}
        </HeadingCell>
        <HeadingCell>{t('direct-deposits.table.heading.amount')}</HeadingCell>
        <HeadingCell>{t('direct-deposits.table.heading.sequence')}</HeadingCell>
      </Row>
    </BuffetHead>
  )
}

const EmployeeRow: React.FunctionComponent<{
  employee: DirectDepositsQueryEmployeeFragment
}> = ({ employee }) => {
  return employee.allocations.map((allocation, index) => {
    return (
      <Row
        className={cx({
          'border-b-0':
            employee.allocations.length > 1 &&
            index !== employee.allocations.length - 1
        })}
        key={`{employee.id}-{index}`}
      >
        {index === 0 && (
          <NameCell
            id={employee.id}
            name={employee.name}
            email={employee.email}
          />
        )}
        {index !== 0 && <Cell />}
        <DepositAccountTypeCell
          depositAccountType={allocation.depositAccountType}
        />
        <AmountCell
          amount={allocation.amount}
          splitDepositType={employee.allocations[0].splitDepositType}
          isLast={index === employee.allocations.length - 1}
        />
        <SequenceCell
          sequence={
            employee.allocations.length > 1 ? allocation.sequence : undefined
          }
        />
      </Row>
    )
  })
}
