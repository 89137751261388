import React from 'react'
import cx from 'classnames'

import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Cell } from '@toasttab/buffet-pui-table'
import { useUser } from '@toasttab/ec-session'

import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'
import { trackIncomplete } from '@local/track'

type Props = Pick<IncompleteDirectDepositsQueryEmployeeFragment, 'id' | 'name'>

export const NameCell: React.FunctionComponent<Props> = (props) => {
  return (
    <Cell>
      <NameComponent {...props} />
    </Cell>
  )
}

export const NameComponent: React.FunctionComponent<Props> = ({ id, name }) => {
  const screenSize = useScreenSize()
  const user = useUser()

  const onClick = () => {
    window.location.assign(`/${user.client}/employees/${id}/paymentMethod`)
  }

  return (
    <Button
      {...trackIncomplete('payment-method-link')}
      className={cx('max-w-full px-0', {
        'h-11 min-h-11 ': screenSize < ScreenSize.MD
      })}
      onClick={onClick}
      variant='text-link'
    >
      {name.last}, {!!name.chosen ? name.chosen : name.first}
    </Button>
  )
}
