import * as React from 'react'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { CardContainer } from '@toasttab/buffet-pui-card'
import {
  IncompleteDirectDepositSortColumn,
  IncompleteDirectDepositsQuery,
  SortDirection
} from '@local/grouter'
import {
  DirectDepositIncompleteTable,
  DirectDepositIncompleteTableMobile
} from './direct-deposit-incomplete-table'
import { DirectDepositIncompleteCardHeader } from '../DirectDepositIncompleteCardHeader'
import { useDirectDepositIncompleteSectionStore } from '../../stores'

type DirectDepositIncompleteDataViewProps = {
  data: IncompleteDirectDepositsQuery
  limit: number
  setLimit: (newLimit: number) => void
  offset: number
  setOffset: (newOffset: number) => void
  sortColumn: IncompleteDirectDepositSortColumn
  setSortColumn: (newSortColumn: IncompleteDirectDepositSortColumn) => void
  sortDirection: SortDirection
  setSortDirection: (newSortDirection: SortDirection) => void
}

export const DirectDepositIncompleteDataView: React.FunctionComponent<
  DirectDepositIncompleteDataViewProps
> = ({
  data,
  limit,
  setLimit,
  offset,
  setOffset,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection
}) => {
  const screenSize = useScreenSize()

  const setSelectedEmployees = useDirectDepositIncompleteSectionStore(
    (state) => state.setSelectedEmployees
  )
  const onChangeSort = React.useCallback(
    (
      sortColumn: IncompleteDirectDepositSortColumn,
      sortDirection: SortDirection
    ) => {
      setSelectedEmployees([])
      setOffset(0)
      setSortColumn(sortColumn)
      setSortDirection(sortDirection)
    },
    [setSelectedEmployees]
  )

  const onChangeLimit = React.useCallback(
    (newLimit: number | null) => {
      setSelectedEmployees([])
      setOffset(0)
      setLimit(newLimit ?? 10)
    },
    [setSelectedEmployees]
  )

  const onChangePage = React.useCallback(
    (newPage: number, limit: number) => {
      setSelectedEmployees([])
      setOffset(limit * newPage)
    },
    [setSelectedEmployees]
  )

  return screenSize < ScreenSize.MD ? (
    <div className='grid gap-y-6'>
      <DirectDepositIncompleteTableMobile
        limit={limit}
        offset={offset}
        onChangeLimit={onChangeLimit}
        onChangePage={(newPage: number) => onChangePage(newPage, limit)}
        onChangeSort={onChangeSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        totalEmployeeCount={data?.incompleteDirectDeposits?.totalCount ?? 0}
        visibleEmployees={data?.incompleteDirectDeposits?.employees}
      />
    </div>
  ) : (
    <CardContainer className='flex flex-col gap-y-6' noElevation>
      <DirectDepositIncompleteCardHeader
        totalCount={data?.incompleteDirectDeposits?.totalCount ?? 0}
      />
      <DirectDepositIncompleteTable
        limit={limit}
        offset={offset}
        onChangeLimit={onChangeLimit}
        onChangePage={(newPage: number) => onChangePage(newPage, limit)}
        onChangeSort={onChangeSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        totalEmployeeCount={data?.incompleteDirectDeposits?.totalCount ?? 0}
        visibleEmployees={data?.incompleteDirectDeposits?.employees}
      />
    </CardContainer>
  )
}
