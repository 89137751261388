import { create } from 'zustand'
import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'

type DirectDepositIncompleteSectionStore = {
  selectedEmployees: IncompleteDirectDepositsQueryEmployeeFragment[]
  setSelectedEmployees: (
    selectedEmployees: IncompleteDirectDepositsQueryEmployeeFragment[]
  ) => void
  selectEmployee: (
    employee: IncompleteDirectDepositsQueryEmployeeFragment
  ) => void
  unselectEmployee: (
    employee: IncompleteDirectDepositsQueryEmployeeFragment
  ) => void
}

const initialState = {
  selectedEmployees: []
}

export const useDirectDepositIncompleteSectionStore =
  create<DirectDepositIncompleteSectionStore>()((set) => ({
    ...initialState,
    setSelectedEmployees: (
      value: IncompleteDirectDepositsQueryEmployeeFragment[]
    ) => {
      set({ selectedEmployees: value })
    },
    selectEmployee: (
      employee: IncompleteDirectDepositsQueryEmployeeFragment
    ) => {
      set((state) => ({
        selectedEmployees: [...state.selectedEmployees, employee]
      }))
    },
    unselectEmployee: (
      employee: IncompleteDirectDepositsQueryEmployeeFragment
    ) => {
      set((state) => ({
        selectedEmployees: [
          ...state.selectedEmployees.filter(
            (selectedEmployee) => selectedEmployee.id !== employee.id
          )
        ]
      }))
    }
  }))
