/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query EmployeeCountQuery {\n    employeeStatusTypeCount {\n      active\n    }\n  }\n':
    types.EmployeeCountQueryDocument,
  '\n  fragment IncompleteDirectDepositsQueryEmployee on IncompleteDirectDepositEmployee {\n    id\n    email\n    name {\n      first\n      last\n      chosen\n    }\n    notification {\n      ...IncompleteDirectDepositsQueryNotification\n    }\n  }\n':
    types.IncompleteDirectDepositsQueryEmployeeFragmentDoc,
  '\n  fragment IncompleteDirectDepositsQueryNotification on DirectDepositNotification {\n    email\n    lastContactedUtc\n    status\n  }\n':
    types.IncompleteDirectDepositsQueryNotificationFragmentDoc,
  '\n  query IncompleteDirectDeposits(\n    $incompleteDirectDepositInput: IncompleteDirectDepositInput!\n  ) {\n    incompleteDirectDeposits(\n      incompleteDirectDepositInput: $incompleteDirectDepositInput\n    ) {\n      totalCount\n      hasNextPage\n      hasPreviousPage\n      employees {\n        ...IncompleteDirectDepositsQueryEmployee\n      }\n    }\n  }\n':
    types.IncompleteDirectDepositsDocument,
  '\n  query EmailPreviewQuery {\n    directDepositEmailTemplate\n  }\n':
    types.EmailPreviewQueryDocument,
  '\n  mutation SendDirectDepositInfoRequestEmail($employees: [ID!]!) {\n    directDepositBulkEmail(employees: $employees) {\n      id\n      status\n    }\n  }\n':
    types.SendDirectDepositInfoRequestEmailDocument,
  '\n  fragment DirectDepositsQueryEmployee on CompleteDirectDepositEmployee {\n    id\n    email\n    name {\n      first\n      last\n      chosen\n    }\n    allocations {\n      ...DirectDepositsQueryAllocation\n    }\n  }\n':
    types.DirectDepositsQueryEmployeeFragmentDoc,
  '\n  fragment DirectDepositsQueryAllocation on DirectDepositAllocation {\n    depositAccountType\n    splitDepositType\n    amount\n    sequence\n  }\n':
    types.DirectDepositsQueryAllocationFragmentDoc,
  '\n  query DirectDeposits($directDepositsInput: DirectDepositPaginationInput!) {\n    completeDirectDeposits(completeDirectDepositInput: $directDepositsInput) {\n      totalCount\n      hasNextPage\n      hasPreviousPage\n      employees {\n        ...DirectDepositsQueryEmployee\n      }\n    }\n  }\n':
    types.DirectDepositsDocument
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmployeeCountQuery {\n    employeeStatusTypeCount {\n      active\n    }\n  }\n'
): typeof documents['\n  query EmployeeCountQuery {\n    employeeStatusTypeCount {\n      active\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment IncompleteDirectDepositsQueryEmployee on IncompleteDirectDepositEmployee {\n    id\n    email\n    name {\n      first\n      last\n      chosen\n    }\n    notification {\n      ...IncompleteDirectDepositsQueryNotification\n    }\n  }\n'
): typeof documents['\n  fragment IncompleteDirectDepositsQueryEmployee on IncompleteDirectDepositEmployee {\n    id\n    email\n    name {\n      first\n      last\n      chosen\n    }\n    notification {\n      ...IncompleteDirectDepositsQueryNotification\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment IncompleteDirectDepositsQueryNotification on DirectDepositNotification {\n    email\n    lastContactedUtc\n    status\n  }\n'
): typeof documents['\n  fragment IncompleteDirectDepositsQueryNotification on DirectDepositNotification {\n    email\n    lastContactedUtc\n    status\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query IncompleteDirectDeposits(\n    $incompleteDirectDepositInput: IncompleteDirectDepositInput!\n  ) {\n    incompleteDirectDeposits(\n      incompleteDirectDepositInput: $incompleteDirectDepositInput\n    ) {\n      totalCount\n      hasNextPage\n      hasPreviousPage\n      employees {\n        ...IncompleteDirectDepositsQueryEmployee\n      }\n    }\n  }\n'
): typeof documents['\n  query IncompleteDirectDeposits(\n    $incompleteDirectDepositInput: IncompleteDirectDepositInput!\n  ) {\n    incompleteDirectDeposits(\n      incompleteDirectDepositInput: $incompleteDirectDepositInput\n    ) {\n      totalCount\n      hasNextPage\n      hasPreviousPage\n      employees {\n        ...IncompleteDirectDepositsQueryEmployee\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmailPreviewQuery {\n    directDepositEmailTemplate\n  }\n'
): typeof documents['\n  query EmailPreviewQuery {\n    directDepositEmailTemplate\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SendDirectDepositInfoRequestEmail($employees: [ID!]!) {\n    directDepositBulkEmail(employees: $employees) {\n      id\n      status\n    }\n  }\n'
): typeof documents['\n  mutation SendDirectDepositInfoRequestEmail($employees: [ID!]!) {\n    directDepositBulkEmail(employees: $employees) {\n      id\n      status\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DirectDepositsQueryEmployee on CompleteDirectDepositEmployee {\n    id\n    email\n    name {\n      first\n      last\n      chosen\n    }\n    allocations {\n      ...DirectDepositsQueryAllocation\n    }\n  }\n'
): typeof documents['\n  fragment DirectDepositsQueryEmployee on CompleteDirectDepositEmployee {\n    id\n    email\n    name {\n      first\n      last\n      chosen\n    }\n    allocations {\n      ...DirectDepositsQueryAllocation\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DirectDepositsQueryAllocation on DirectDepositAllocation {\n    depositAccountType\n    splitDepositType\n    amount\n    sequence\n  }\n'
): typeof documents['\n  fragment DirectDepositsQueryAllocation on DirectDepositAllocation {\n    depositAccountType\n    splitDepositType\n    amount\n    sequence\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DirectDeposits($directDepositsInput: DirectDepositPaginationInput!) {\n    completeDirectDeposits(completeDirectDepositInput: $directDepositsInput) {\n      totalCount\n      hasNextPage\n      hasPreviousPage\n      employees {\n        ...DirectDepositsQueryEmployee\n      }\n    }\n  }\n'
): typeof documents['\n  query DirectDeposits($directDepositsInput: DirectDepositPaginationInput!) {\n    completeDirectDeposits(completeDirectDepositInput: $directDepositsInput) {\n      totalCount\n      hasNextPage\n      hasPreviousPage\n      employees {\n        ...DirectDepositsQueryEmployee\n      }\n    }\n  }\n']

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
