import * as React from 'react'

import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { LockLockedIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { t } from '@local/translations'

/**
 * UnauthorizedPage component
 */
export const UnauthorizedPage: React.FunctionComponent<TestIdentifiable> = (
  props
) => {
  return (
    <EmptyState
      className='my-8'
      icon={<LockLockedIcon accessibility='decorative' />}
      title={t('unauthorized.title')}
      {...props}
    >
      {t('unauthorized.description')}
    </EmptyState>
  )
}
