import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { PayrollEmployeeIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export type EmptyStateCardProps = {
  title?: string
  subtitle: string
  action?: {
    label: string
    onClick: VoidFunction
  }
} & TestIdentifiable

/**
 * EmptyStateCard component
 */
export const EmptyStateCard: React.FunctionComponent<EmptyStateCardProps> = ({
  testId = 'empty-state-card',
  title,
  subtitle,
  action
}) => {
  return (
    <CardContainer
      testId={testId}
      noPadding
      className='flex flex-col items-center p-10 space-y-6'
    >
      <div className='flex flex-col items-center space-y-4'>
        <PayrollEmployeeIcon
          testId={`${testId}-icon`}
          accessibility='decorative'
          className='text-secondary'
          size='md'
        />

        <div className='space-y-2'>
          {title && (
            <div
              data-testid={`${testId}-title`}
              className='type-default text-center font-semibold'
            >
              {title}
            </div>
          )}

          <div
            data-testid={`${testId}-subtitle`}
            className='type-default text-center'
          >
            {subtitle}
          </div>
        </div>
      </div>

      {action && (
        <Button onClick={action.onClick} variant='secondary'>
          {action.label}
        </Button>
      )}
    </CardContainer>
  )
}
