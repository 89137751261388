import React from 'react'
import { BulkActionSection } from '@local/bulk-action-section'
import { useTracking } from '@local/track'
import { DirectDepositIncompleteCard } from './direct-deposit-incomplete-card'
import { EmailPreviewModal } from './email-preview-modal/EmailPreviewModal'
import { t, Trans } from '@local/translations'

export const DirectDepositIncompleteSection: React.FunctionComponent = () => {
  const { track } = useTracking()
  const [isEmailPreviewOpen, setIsEmailPreviewOpen] = React.useState(false)

  const emailPreviewOnOpen = React.useCallback(() => {
    track('direct-deposit-bulk-action.incomplete-section.email-preview-open')
    setIsEmailPreviewOpen(true)
  }, [track])

  const emailPreviewOnClose = React.useCallback(() => {
    track('direct-deposit-bulk-action.incomplete-section.email-preview-close')
    setIsEmailPreviewOpen(false)
  }, [track])

  return (
    <>
      {isEmailPreviewOpen && (
        <EmailPreviewModal onClose={emailPreviewOnClose} />
      )}
      <BulkActionSection
        title={t('direct-deposit-incomplete.title')}
        subtitle={
          <Trans
            i18nKey='direct-deposit-incomplete.subtitle'
            components={[
              // content is provided by translation
              // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
              <a
                className='inline-link'
                key='email-preview'
                onClick={emailPreviewOnOpen}
                role='link'
              />
            ]}
          />
        }
      >
        <DirectDepositIncompleteCard />
      </BulkActionSection>
    </>
  )
}
