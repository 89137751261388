import { graphql } from '@local/grouter'

graphql(`
  fragment IncompleteDirectDepositsQueryEmployee on IncompleteDirectDepositEmployee {
    id
    email
    name {
      first
      last
      chosen
    }
    notification {
      ...IncompleteDirectDepositsQueryNotification
    }
  }
`)

graphql(`
  fragment IncompleteDirectDepositsQueryNotification on DirectDepositNotification {
    email
    lastContactedUtc
    status
  }
`)

export const IncompleteDirectDeposits = graphql(`
  query IncompleteDirectDeposits(
    $incompleteDirectDepositInput: IncompleteDirectDepositInput!
  ) {
    incompleteDirectDeposits(
      incompleteDirectDepositInput: $incompleteDirectDepositInput
    ) {
      totalCount
      hasNextPage
      hasPreviousPage
      employees {
        ...IncompleteDirectDepositsQueryEmployee
      }
    }
  }
`)
