import { graphql } from '@local/grouter'

export const SendDirectDepositInfoRequestEmail = graphql(`
  mutation SendDirectDepositInfoRequestEmail($employees: [ID!]!) {
    directDepositBulkEmail(employees: $employees) {
      id
      status
    }
  }
`)
