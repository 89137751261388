import React from 'react'
import cx from 'classnames'

import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { Cell } from '@toasttab/buffet-pui-table'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'

import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'
import { isValidEmail } from '../utils'
import { useDirectDepositIncompleteSectionStore } from '../../../../stores'
import { t } from '@local/translations'

type SelectCellProps = {
  employee: IncompleteDirectDepositsQueryEmployeeFragment
}

export const SelectCell: React.FunctionComponent<SelectCellProps> = ({
  employee
}) => {
  const screenSize = useScreenSize()

  const isEmailValid = isValidEmail(employee.email)

  const selectedEmployees = useDirectDepositIncompleteSectionStore(
    (store) => store.selectedEmployees
  )
  const selectEmployee = useDirectDepositIncompleteSectionStore(
    (store) => store.selectEmployee
  )
  const unselectEmployee = useDirectDepositIncompleteSectionStore(
    (store) => store.unselectEmployee
  )

  const getIsSelected = React.useCallback(
    (
      selectedEmployees: IncompleteDirectDepositsQueryEmployeeFragment[]
    ): boolean => {
      return selectedEmployees.some(
        (selectedEmployee) => selectedEmployee.id === employee.id
      )
    },
    [employee.id]
  )
  const isSelected = getIsSelected(selectedEmployees)

  const onChange = React.useCallback(
    (isSelected: boolean) => {
      if (isSelected) {
        unselectEmployee(employee)
      } else {
        selectEmployee(employee)
      }
    },
    [employee, unselectEmployee, selectEmployee]
  )

  return (
    <Cell
      className={cx({
        'pl-0 pr-0 border-default border-b': screenSize < ScreenSize.MD
      })}
      valign='middle'
    >
      <Checkbox
        checked={isSelected}
        disabled={!isEmailValid}
        hideLabel
        label={t('direct-deposit-incomplete.table.select', {
          lastName: employee.name.last,
          firstName: employee.name.chosen ?? employee.name.first
        })}
        onChange={() => onChange(isSelected)}
      />
    </Cell>
  )
}
