import { graphql } from '@local/grouter'

graphql(`
  fragment DirectDepositsQueryEmployee on CompleteDirectDepositEmployee {
    id
    email
    name {
      first
      last
      chosen
    }
    allocations {
      ...DirectDepositsQueryAllocation
    }
  }
`)

graphql(`
  fragment DirectDepositsQueryAllocation on DirectDepositAllocation {
    depositAccountType
    splitDepositType
    amount
    sequence
  }
`)

export const DirectDeposits = graphql(`
  query DirectDeposits($directDepositsInput: DirectDepositPaginationInput!) {
    completeDirectDeposits(completeDirectDepositInput: $directDepositsInput) {
      totalCount
      hasNextPage
      hasPreviousPage
      employees {
        ...DirectDepositsQueryEmployee
      }
    }
  }
`)
