import React from 'react'
import { Body, Row, Table } from '@toasttab/buffet-pui-table'
import { BulkActionTableFooter } from '@local/bulk-action-table-footer'
import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'
import { ActionsCell, SelectCell } from '../cells'
import { DirectDepositIncompleteTableHeadMobile } from './DirectDepositIncompleteTableHeadMobile'
import { StackedDataCell } from './cells'
import { DirectDepositIncompleteTableProps } from '../DirectDepositIncompleteTable'

export const DirectDepositIncompleteTableMobile: React.FunctionComponent<
  DirectDepositIncompleteTableProps
> = ({
  sortColumn,
  sortDirection,
  onChangeSort,
  visibleEmployees,
  ...footerProps
}) => {
  return visibleEmployees && visibleEmployees.length > 0 ? (
    <Table variant='plain' className='table-fixed border-separate'>
      <colgroup>
        <col className='w-6' />
        <col />
        <col className='w-12' />
      </colgroup>
      <DirectDepositIncompleteTableHeadMobile
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onChangeSort={onChangeSort}
        visibleEmployees={visibleEmployees}
        totalEmployeeCount={footerProps.totalEmployeeCount}
      />
      <Body>
        {visibleEmployees.map((employee) => {
          return (
            employee && <EmployeeRow key={employee.id} employee={employee} />
          )
        })}
      </Body>
      <BulkActionTableFooter
        cellClassName='py-6'
        colSpan={3}
        {...footerProps}
      />
    </Table>
  ) : null
}

const EmployeeRow: React.FunctionComponent<{
  employee: IncompleteDirectDepositsQueryEmployeeFragment
}> = ({ employee }) => {
  return (
    <Row>
      <SelectCell employee={employee} />
      <StackedDataCell employee={employee} />
      <ActionsCell employee={employee} />
    </Row>
  )
}
