import * as React from 'react'
import { IconProps } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  BulkActionSectionProps,
  BulkActionSectionSubtitle,
  BulkActionSectionTitle
} from './BulkActionSection'

export type BulkActionLinkSectionProps = {
  action: {
    onClick: React.MouseEventHandler
    icon: React.FunctionComponentElement<IconProps>
  }
} & BulkActionSectionProps &
  TestIdentifiable

/**
 * BulkActionSection component
 * Modeled after the sections in ec-settings-spa
 * https://github.com/toasttab/ec-settings-spa/blob/main/packages/features/configuration/pages/dashboard/index.tsx#L62
 */
export const BulkActionLinkSection: React.FunctionComponent<
  BulkActionLinkSectionProps
> = ({ testId = 'bulk-action-link-section', action, ...rest }) => {
  return (
    <div
      data-testid={testId}
      className='py-4 md:py-8 flex flex-row justify-between w-full items-center cursor-pointer'
    >
      <div className='space-y-2'>
        <BulkActionSectionTitle
          testId={testId}
          action={{ onClick: action.onClick }}
          {...rest}
        />
        <BulkActionSectionSubtitle onClick={action.onClick} {...rest} />
      </div>
      <IconButton cropToIcon textClassName='text-secondary' {...action} />
    </div>
  )
}
