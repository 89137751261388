import * as React from 'react'
import cx from 'classnames'
import { BulkImportSection } from './bulk-import'
import { DirectDepositsSection } from './direct-deposit'
import { DirectDepositIncompleteSection } from './direct-deposit-incomplete'

export const DirectDepositBulkActionPage: React.FunctionComponent = () => {
  return (
    <div
      className={cx(
        'grid grid-cols-1 divide-y',
        '-mt-3 -mb-4 mx-0',
        'md:-mt-1 md:-mb-2 md:mx-8'
      )}
    >
      <DirectDepositIncompleteSection />
      <DirectDepositsSection />
      <BulkImportSection />
    </div>
  )
}
