import {
  track as ecTrack,
  useTracking as useEcTracking
} from '@toasttab/ec-track'

type DataToastTrackIdProps = {
  'data-toast-track-id': TrackId
}

type App = 'direct-deposit-bulk-action'

type BulkImportPortalTrackId = 'bulk-import-portal'
type BulkImportPortalActionTrackIds = 'legacy-bulk-import'

export const trackBulkImportPortal = (
  actionTrackId: BulkImportPortalActionTrackIds
): DataToastTrackIdProps => {
  return track(`direct-deposit-bulk-action.bulk-import-portal.${actionTrackId}`)
}

type IncompleteSectionTrackId = 'incomplete-section'
type IncompleteActionTrackIds =
  | 'payment-method-link'
  | 'send-single-email'
  | 'update-email'
  | 'manually-add-direct-deposit'
  | 'add-employee'
  | 'email-preview-close'
  | 'email-preview-open'
  | 'open-email-send-modal'
  | 'email-send'
  | 'change-page-size'
  | 'change-page'
  | 'change-sort'

export const trackIncomplete = (
  actionTrackId: IncompleteActionTrackIds
): DataToastTrackIdProps => {
  return track(`direct-deposit-bulk-action.incomplete-section.${actionTrackId}`)
}

export type TrackId =
  | `${App}.${BulkImportPortalTrackId}.${BulkImportPortalActionTrackIds}`
  | `${App}.${IncompleteSectionTrackId}.${IncompleteActionTrackIds}`

const track = (trackId: TrackId): { 'data-toast-track-id': TrackId } =>
  ecTrack(trackId) as DataToastTrackIdProps

export const useTracking = (): { track: (trackId: TrackId) => void } => {
  const { track: ecTrack } = useEcTracking()
  const track = (trackId: TrackId) => {
    ecTrack(trackId)
  }
  return { track }
}
