import * as React from 'react'
import { useQuery } from '@apollo/client'
import { LoadingCard } from '@local/loading-card'

import { EmptyStateCard } from '@local/empty-state-card'
import { ErrorCard } from '@local/error-card'
import { EmployeeCountByStatus } from '../../api-documents'

import { DirectDeposits } from '../api-documents'
import { DirectDepositsDataView } from './components'
import { t } from '@local/translations'

export const DirectDepositsCard: React.FunctionComponent = () => {
  const [limit, setLimit] = React.useState<number>(10)
  const [offset, setOffset] = React.useState<number>(0)

  const { data: employeeCountData, loading: employeeCountLoading } = useQuery(
    EmployeeCountByStatus
  )

  const { data, error, loading, refetch } = useQuery(DirectDeposits, {
    variables: {
      directDepositsInput: {
        limit: limit,
        offset: offset
      }
    }
  })

  return employeeCountLoading || loading ? (
    <LoadingCard />
  ) : error ? (
    <ErrorCard onClick={() => refetch} />
  ) : employeeCountData?.employeeStatusTypeCount.active === 0 ? (
    <EmptyStateCard
      title={t('direct-deposits.empty-state.no-employees.title')}
      subtitle={t('direct-deposits.empty-state.no-employees.subtitle')}
    />
  ) : !data || data.completeDirectDeposits?.totalCount === 0 ? (
    <EmptyStateCard subtitle={t('direct-deposits.empty-state')} />
  ) : (
    <DirectDepositsDataView
      data={data}
      limit={limit}
      setLimit={setLimit}
      offset={offset}
      setOffset={setOffset}
    />
  )
}
