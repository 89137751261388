import React from 'react'

import { Cell } from '@toasttab/buffet-pui-table'

import { IncompleteDirectDepositsQueryEmployeeFragment } from '@local/grouter'
import {
  EmailComponent,
  LastEmailedComponent,
  NameComponent
} from '../../cells'
import { t } from '@local/translations'

type StackedDataCellProps = {
  employee: IncompleteDirectDepositsQueryEmployeeFragment
}

export const StackedDataCell: React.FunctionComponent<StackedDataCellProps> = ({
  employee
}) => {
  return (
    <Cell className='flex flex-col items-start border-default border-b'>
      <NameComponent id={employee.id} name={employee.name} />
      <EmailComponent email={employee.email} />
      <div className='type-caption text-secondary mt-2'>
        {t('direct-deposit-incomplete.table.heading.last-contacted')}
      </div>
      <LastEmailedComponent
        currentEmail={employee.email}
        notification={employee.notification}
      />
    </Cell>
  )
}
