import * as React from 'react'

import { Button, IconButtonProps } from '@toasttab/buffet-pui-buttons'
import { EmailIcon } from '@toasttab/buffet-pui-icons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

import { trackIncomplete } from '@local/track'
import { EmailSendModal } from '../email-send-modal'

import { useDirectDepositIncompleteSectionStore } from '../stores'
import { t } from '@local/translations'

type DirectDepositIncompletePanelHeaderProps = {
  secondaryAction?: React.ReactElement<IconButtonProps>
  totalCount: number
}

export const DirectDepositIncompleteCardHeader: React.FunctionComponent<
  DirectDepositIncompletePanelHeaderProps
> = ({ secondaryAction, totalCount }) => {
  const screenSize = useScreenSize()

  const selectedEmployees = useDirectDepositIncompleteSectionStore(
    (state) => state.selectedEmployees
  )

  const [isEmailSendModalOpen, setIsEmailSendModalOpen] = React.useState(false)
  const onOpenEmailSendModal = React.useCallback(
    () => setIsEmailSendModalOpen(true),
    []
  )
  const onCloseEmailSendModal = React.useCallback(
    () => setIsEmailSendModalOpen(false),
    []
  )

  return (
    <>
      {isEmailSendModalOpen && (
        <EmailSendModal onClose={onCloseEmailSendModal} />
      )}
      <div className='flex flex-row items-center justify-between'>
        <span className='font-semibold'>
          {t('direct-deposit-incomplete.selected', {
            selectedCount: selectedEmployees?.length || 0,
            totalCount: totalCount
          })}
        </span>
        <div className='space-x-4'>
          {secondaryAction}
          <Button
            {...trackIncomplete('open-email-send-modal')}
            disabled={!selectedEmployees?.length}
            iconLeft={
              screenSize >= ScreenSize.MD && (
                <EmailIcon accessibility='decorative' />
              )
            }
            onClick={() => {
              onOpenEmailSendModal()
            }}
          >
            {t(
              `direct-deposit-incomplete.button.email${
                screenSize < ScreenSize.MD ? '.mobile' : ''
              }`
            )}
          </Button>
        </div>
      </div>
    </>
  )
}
