import * as React from 'react'
import cx from 'classnames'
import { ExpandIcon } from '@toasttab/buffet-pui-icons'
import { Disclosure, useDisclosure } from '@toasttab/buffet-pui-disclosure'
import {
  BulkActionSectionProps,
  BulkActionSectionSubtitle,
  BulkActionSectionSubtitleProps,
  BulkActionSectionTitle,
  BulkActionSectionTitleProps
} from './BulkActionSection'
import { t } from '@local/translations'

/**
 * BulkActionDisclosureSection component
 */
export const BulkActionDisclosureSection: React.FunctionComponent<
  React.PropsWithChildren<BulkActionSectionProps>
> = ({ testId = 'bulk-action-disclosure-section', children, ...rest }) => {
  return (
    <Disclosure testId={testId} className='py-4 md:py-8' initialIsOpen={false}>
      <DisclosureTitle
        className={cx({
          'mb-2': rest.subtitle
        })}
        {...rest}
      />
      <DisclosureSubtitle subtitle={rest.subtitle} />
      <Disclosure.Content className='mt-4'>{children}</Disclosure.Content>
    </Disclosure>
  )
}

const DisclosureTitle: React.FunctionComponent<BulkActionSectionTitleProps> = (
  props
) => {
  const { isOpen, toggleProps } = useDisclosure()
  return (
    <BulkActionSectionTitle
      action={{
        onClick: toggleProps.onClick,
        icon: (
          <ExpandIcon
            size='sm'
            isExpanded={isOpen}
            aria-label={isOpen ? t('disclosure.close') : t('disclosure.open')}
          />
        )
      }}
      stickyOnMobile={true}
      {...props}
    />
  )
}

const DisclosureSubtitle: React.FunctionComponent<
  BulkActionSectionSubtitleProps
> = (props) => {
  const { toggleProps } = useDisclosure()
  return <BulkActionSectionSubtitle onClick={toggleProps.onClick} {...props} />
}
